import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl'
import TextField from '@material-ui/core/TextField';
import { NorwegianId } from 'norwegian-national-id-validator';

const BirthNumberField = ({ onChange, onErrorChange, setError, ...props }) => {
    const [hasError, setHasError] = useState(false);
    const [message, setMessage] = useState(<FormattedMessage id="info.national-id" />);

    const handleChange = event => {
        if (event.isDefaultPrevented()) {
            return;
        }

        const value = event.target.value.replace(/\D/g, '');
        onChange(value);
        const validLength = (value !== '' && /^\d{11}$/.test(value));
        const validator = NorwegianId(value);
        const isValid = (validLength && validator.isValid());

        if (hasError !== !isValid) {
            setHasError(!isValid);
            onErrorChange(!isValid);
        }

        if (isValid) {
            if (validator.isBirthNumber()) {
                setMessage(<FormattedMessage id="info.national-id.birthnumber" />);
            } else if (validator.isDNumber()) {
                setMessage(<FormattedMessage id="info.national-id.d-number" />);
            } else if (validator.isHNumber()) {
                setMessage(<FormattedMessage id="info.national-id.h-number" />);
            } else if (validator.isFhNumber()) {
                setMessage(<FormattedMessage id="info.national-id.fh-number" />);
            } else {
                setMessage(<FormattedMessage id="info.national-id.unknown" />);
            }
        } else {
            if (!validLength) {
                setMessage(<FormattedMessage id="error.national-id.length" />);
            } else {
                setMessage(<FormattedMessage id="error.national-id.invalid" />);
            }
        }
    };

    return (
        <TextField
            id="national-identification-number"
            error={setError !== '' ? true : hasError}
            inputMode="numeric"
            onChange={handleChange}
            helperText={setError !== '' ? setError : message}
            {...props}
        />
    );
};

BirthNumberField.propTypes = {
    onChange: PropTypes.func,
    onErrorChange: PropTypes.func,
    setError: PropTypes.string,
};

BirthNumberField.defaultProps = {
    onChange: (() => {}),
    onErrorChange: (() => {}),
    setError: '',
};

export default BirthNumberField;
