import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import Link from '@material-ui/core/Link';
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import FileSize from './FileSize';
import { axiosWithAuth as axios } from '../utils/axios';
import { FolderFieldEmployeeMine, FolderFieldExternalUpload } from './FolderField';
import FileTypeHelpDialog from './FileTypeHelpDialog';
import { usePolling } from '../utils/usePolling';
import { useFolder } from '../routes/Folder';
import { FILE_TYPE, fileIsOfType } from '../utils/file';

const useStyles = makeStyles((theme) => ({
    uploadError: {
        color: red[700],
        verticalAlign: 'bottom',
    },
    padding: {
        marginBottom: theme.spacing(1),
    },
}))

const acceptedFileTypes = [
    '.m4a',
    '.mp3',
    '.flac',
    '.ogg',
    '.wav',
    '.gif',
    '.jpg',
    '.jpeg',
    '.jfif',
    '.pjpeg',
    '.pjp',
    '.png',
    '.webp',
    '.vtt',
    '.srt',
];
const acceptedMimeTypes = [
    'video/*',
    'application/pdf',
];

// Fix caption files not having correct mime type
function getContentType(file) {
    let type = file.type;

    if (type === '' && file.name.toLocaleLowerCase().endsWith('.vtt')) {
        type = 'text/vtt';
    }

    if (type === '' && file.name.toLocaleLowerCase().endsWith('.srt')) {
        type = 'text/srt';
    }

    return type;
}

export const LinearProgressWithPercentage = ({ value, ...props }) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={value} {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

export const UploadStatus = ({
    haveFile,
    fileName,
    uploadProgress,
    uploadError,
    waitForFileReady,
    checkLoaded,
    checkLoading,
    linkPath,
}) => {
    const classes = useStyles();

    if (haveFile && typeof uploadProgress === 'number' && !uploadError) {
        return (
            <Grid item xs={12} className={classes.padding}>
                <FormattedMessage id="info.uploading" />
                {uploadProgress < 100 ?
                    <LinearProgressWithPercentage value={uploadProgress} /> :
                    <LinearProgress variant="indeterminate" />
                }
            </Grid>
        )
    } else if (uploadProgress === true) {
        if (waitForFileReady) {
            if (!checkLoaded) {
                return (
                    <Grid item xs={12} className={classes.padding}>
                        <FormattedMessage id="info.upload_done" />
                        <br/>
                        <FormattedMessage id="info.waiting_ready" />
                        <br/>
                        {checkLoading ?
                            <FormattedMessage id="info.checking" /> :
                            <>
                                <FormattedMessage id="info.last_check" />
                                <FormattedRelativeTime value={0} numeric="auto" updateIntervalInSeconds={10} />
                            </>
                        }
                        <LinearProgress variant="indeterminate" />
                    </Grid>
                );
            } else {
                return (
                    <Grid item xs={12} className={classes.padding}>
                        <FormattedMessage id="info.upload_done" />
                        <br/>
                        <FormattedMessage id="info.file_ready" />{': '}
                        <Link component={RouterLink} to={linkPath}>
                            {fileName}
                        </Link>
                    </Grid>
                );
            }
        } else {
            return (
                <Grid item xs={12} className={classes.padding}>
                    <FormattedMessage id="info.upload_done" />
                    <LinearProgressWithPercentage value={100} />
                </Grid>
            );
        }
    }

    return null;
};

export const FileUploadDialogExternal = ({onClose, onSuccess, onError, waitForFileReady}) => {
    const [uploadProgress, setUploadProgress] = useState(null);
    const [uploadError, setUploadError] = useState(false);
    const [file, setFile] = useState(null);
    const [fileDisplayName, setFileDisplayName] = useState('');
    const [folder, setFolder] = useState('');
    const [description, setDescription] = useState('');
    const [fileTypeError, setFileTypeError] = useState(false);
    const [open, setOpen] = useState(true);

    const handleClose = () => setOpen(false);

    const handleFileDisplayNameChange = async event => setFileDisplayName(event.target.value);

    const checkIfAllowedFileExtension = (filename) => (
        acceptedFileTypes.findIndex(type => filename.endsWith(type)) !== -1
    );

    const checkIfAllowedFileMime = (mime) => {
        return acceptedMimeTypes.findIndex(type => {
            if (type.endsWith('/*')) {
                return mime.startsWith(type.split('*')[0]);
            }
            return mime === type;
        }) !== -1;
    };

    const handleFileChange = async event => {
        setUploadError(false);
        if (checkIfAllowedFileExtension(event.target.files[0].name.toLocaleLowerCase()) ||
            checkIfAllowedFileMime(event.target.files[0].type.toLocaleLowerCase())
        ){
            setFile(event.target.files[0]);
            if (fileDisplayName === '') {
                setFileDisplayName(event.target.files[0].name);
            }
            setUploadProgress(null);
            setFileTypeError(false);
        } else {
            setFile(null);
            setFileDisplayName('');
            setFileTypeError(true);
        }
    };

    const handleUploadProgress = (e) => {
        setUploadProgress(Math.round(100 * e.loaded / e.total));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let fileContainerHash = null, fileHash = null, cleanupUrl;

        try {
            setUploadError(false);
            setUploadProgress(0);

            const fileContainerResponse = await axios.post(`/api/media/upload/folders/${folder}/file_container`, {
                name: fileDisplayName.trim(),
                file: file.name + ';' + file.type,
                description: description.trim(),
            });

            const fileContainerId = fileContainerResponse.data.data.id;
            fileContainerHash = fileContainerResponse.data.data.hash.data.value;
            cleanupUrl = fileContainerResponse.data.data.cleanup.data.url;

            const fileResponse = await axios.post(`/api/media/upload/folders/${folder}/file_containers/${fileContainerId}/file`, {
                name: fileDisplayName.trim(),
                file: file.name + ';' + file.type,
                primary: true,
            });

            const fileId = fileResponse.data.data.id;
            fileHash = fileResponse.data.data.hash.data.value;
            cleanupUrl = fileResponse.data.data.cleanup.data.url;

            await axios.put(`/api/media/upload/folders/${folder}/file_containers/${fileContainerId}/files/${fileId}`,
                file,
                {
                    headers: { 'Content-Type': getContentType(file) },
                    onUploadProgress: handleUploadProgress,
                }
            );

            setUploadProgress(true);
            if (typeof onSuccess === 'function') {
                onSuccess({
                    folder: folder,
                    fileContainer: fileContainerId,
                    file: fileId,
                });
            }
            handleClose();
        } catch (e) {
            if (cleanupUrl && (fileHash || fileContainerHash)) {
                await axios.post(cleanupUrl, {
                    hash: {
                        file: fileHash,
                        container: fileContainerHash,
                    },
                    primary: true,
                }).catch(error => {
                    console.error('Cleanup failed: ', error);
                });
            }

            setUploadProgress(null);
            setUploadError(true);
            if (e.response.status === 415 || (e.response.status === 422 && e.response?.data?.errors?.file)) {
                setFileTypeError(true);
            }
            if (typeof onError === 'function') {
                onError(e.message);
            }
        }
    }

    return (
        <FileUploadDialog
            onSubmit={handleSubmit}
            waitForFileReady={waitForFileReady}
            isOpen={open}
            onClose={handleClose}
            onExited={onClose}
            uploadProgress={uploadProgress}
            uploadError={uploadError}
            onFileChange={handleFileChange}
            file={file}
            fileDisplayName={fileDisplayName}
            onFileDisplayNameChange={handleFileDisplayNameChange}
            folderSelectComponent={<FolderFieldExternalUpload value={folder} onChange={(v) => {setFolder(v)}} />}
            folder={folder}
            description={description}
            onDescriptionChange={setDescription}
            fileTypeError={fileTypeError}
            isExternal={true}
        />
    );
};

export const FileUploadDialogInternal = ({fileContainerId, folderId, onClose, onSuccess, onError, waitForFileReady}) => {
    const history = useHistory();
    const [uploadProgress, setUploadProgress] = useState(null);
    const [uploadError, setUploadError] = useState(false);
    const [file, setFile] = useState(null);
    const [fileDisplayName, setFileDisplayName] = useState('');
    const [folder, setFolder] = useState('');
    const [description, setDescription] = useState('');
    const [fileTypeError, setFileTypeError] = useState(false);
    const [linkPath, setLinkPath] = useState(null);
    const [open, setOpen] = useState(true);
    const { isAdmin } = useFolder();
    const {
        start: checkStart,
        stop: checkStop,
        running: checkRunning,
        loaded: checkLoaded,
        loading: checkLoading,
        error: checkError,
    } = usePolling(120000);

    useEffect(() => {
        if (checkRunning && !checkLoading && !checkError) {
            checkStop();
        }
    }, [checkError, checkLoading, checkStop, checkRunning]);

    const handleClose = () => setOpen(false);

    const handleFileDisplayNameChange = async event => setFileDisplayName(event.target.value);

    const handleFileChange = async event => {
        setUploadError(false);
        setFile(event.target.files[0]);
        if (fileDisplayName === '') {
            setFileDisplayName(event.target.files[0].name);
        }
        setUploadProgress(null);
        setFileTypeError(false);
    };

    const handleUploadProgress = (e) => {
        setUploadProgress(Math.round(100 * e.loaded / e.total));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const targetFolder = folderId || folder;
        let fileContainer, primary, fileId;
        let fileContainerHash = null, fileHash = null, cleanupUrl;

        try {
            setUploadError(false);
            setUploadProgress(0);

            if (!fileContainerId) {
                primary = true;
                const response = await axios.post(`/api/media/folders/${targetFolder}/file_containers`, {
                    name: fileDisplayName.trim(),
                    description: description.trim(),
                });

                fileContainer = response.data.data.id;
                fileContainerHash = response.data.data.hash.data.value;
                cleanupUrl = response.data.data.cleanup.data.url;
            } else {
                fileContainer = fileContainerId;
                primary = false;
            }

            const response = await axios.post(`/api/media/folders/${targetFolder}/file_containers/${fileContainer}/files`, {
                name: fileDisplayName.trim(),
                primary,
            });

            fileId = response.data.data.id;
            fileHash = response.data.data.hash.data.value;
            cleanupUrl = response.data.data.cleanup.data.url;

            const uploadResponse = await axios.put(`/api/media/folders/${targetFolder}/file_containers/${fileContainer}/files/${fileId}/upload`,
                file,
                {
                    headers: { 'Content-Type': getContentType(file) },
                    onUploadProgress: handleUploadProgress,
                }
            );

            if (waitForFileReady &&
                !(
                    fileIsOfType(uploadResponse.data.data, FILE_TYPE.AUDIO) ||
                    fileIsOfType(uploadResponse.data.data, FILE_TYPE.VIDEO)
                )
            ) {
                waitForFileReady = false;
            }

            setUploadProgress(true);
            if (typeof onSuccess === 'function') {
                onSuccess({
                    folder: targetFolder,
                    fileContainer: fileContainer,
                    file: fileId,
                }, response.data.data);
                if (!waitForFileReady) {
                    handleClose();
                }
            }
            if (waitForFileReady) {
                setLinkPath((isAdmin ? '/admin' : '') + `/folders/${folderId}/media/${fileContainer}`);
                checkStart(`/api/media/folders/${folderId}/file_containers/${fileContainer}/variants/master-playlist.m3u8`);
            } else {
                history.push((isAdmin ? '/admin' : '') + `/folders/${targetFolder}/media/${fileContainer}`);
            }
        } catch (e) {
            if (cleanupUrl && (fileHash || fileContainerHash)) {
                await axios.post(cleanupUrl, {
                    hash: {
                        file: fileHash,
                        container: fileContainerHash,
                    },
                    primary: primary,
                }).catch(error => {
                    console.error('Cleanup failed: ', error);
                });
            }

            setUploadProgress(null);
            setUploadError(true);
            if (typeof onError === 'function') {
                onError(e.message);
            }
        }
    }

    return (
        <FileUploadDialog
            onSubmit={handleSubmit}
            waitForFileReady={waitForFileReady}
            folderId={folderId}
            isOpen={open}
            onClose={handleClose}
            onExited={onClose}
            uploadProgress={uploadProgress}
            uploadError={uploadError}
            onFileChange={handleFileChange}
            file={file}
            fileDisplayName={fileDisplayName}
            onFileDisplayNameChange={handleFileDisplayNameChange}
            folderSelectComponent={<FolderFieldEmployeeMine value={folder} onChange={(v) => {setFolder(v)}} />}
            folder={folder}
            description={description}
            onDescriptionChange={setDescription}
            fileTypeError={fileTypeError}
            linkPath={linkPath}
            isPollCheckLoaded={checkLoaded}
            isPollCheckLoading={checkLoading}
            isExternal={false}
        />
    );
};

const FileUploadDialog = ({
    folderId,
    isOpen,
    onClose,
    onExited,
    waitForFileReady,
    onSubmit,
    uploadProgress,
    uploadError,
    onFileChange,
    file,
    fileDisplayName,
    onFileDisplayNameChange,
    folderSelectComponent,
    folder,
    description,
    onDescriptionChange,
    fileTypeError,
    linkPath,
    isPollCheckLoading,
    isPollCheckLoaded,
    isExternal,
}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [dialog, setDialog] = useState(null);

    const handleDialogClose = () => setDialog(null);
    const handleShowFileTypeHelp = () => setDialog(
        <FileTypeHelpDialog
            onClose={handleDialogClose}
            isExternalUser={isExternal}
        />
    );

    function canUpload() {
        return (
            file &&
            uploadProgress === null &&
            fileDisplayName.trim() !== '' &&
            (folder !== '' || folderId !== null)
        );
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            onExited={onExited}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>
                <FormattedMessage id="action.upload_from_my_device" />
            </DialogTitle>
            {dialog}
            <form onSubmit={onSubmit} encType="multipart/form-data">
                <DialogContent>
                    <Grid alignItems="center" container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Link
                                onClick={handleShowFileTypeHelp}
                            >
                                <FormattedMessage id="label.file_type_help" />
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                color="secondary"
                                component="label"
                                fullWidth
                                variant="contained"
                                disabled={uploadProgress !== null}
                            >
                                <FormattedMessage id="action.choose_file" />
                                <input
                                    onChange={onFileChange}
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept={acceptedFileTypes.concat(acceptedMimeTypes).join(',')}
                                />
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            {file !== null && <Typography>{file.name} (<FileSize value={file.size} />)</Typography>}
                            {!file && fileTypeError && (
                                <Typography color="textSecondary">
                                    <FormattedMessage id="error.file_type_unsupported" />
                                </Typography>
                            )}
                            {!file && !fileTypeError && (
                                <Typography color="textSecondary">
                                    <FormattedMessage id="notice.no_file_chosen" />
                                </Typography>
                            )}
                        </Grid>

                        <UploadStatus
                            checkLoaded={isPollCheckLoaded}
                            fileDisplayName={fileDisplayName}
                            haveFile={file !== null}
                            uploadError={uploadError}
                            uploadProgress={uploadProgress}
                            linkPath={linkPath}
                            waitForFileReady={waitForFileReady}
                            checkLoading={isPollCheckLoading}
                        />

                        {uploadError && fileTypeError &&
                            <Typography color="textSecondary">
                                <FormattedMessage id="error.file_type_unsupported" />
                            </Typography>
                        }
                        {uploadError && !fileTypeError &&
                            <Grid item xs={12}>
                                <ErrorIcon className={classes.uploadError} />
                                <FormattedMessage id="error.upload_failed" />
                            </Grid>
                        }

                        {!folderId && folderSelectComponent}

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label={<FormattedMessage id="label.name_of_uploaded_file" />}
                                variant="outlined"
                                value={fileDisplayName}
                                onChange={onFileDisplayNameChange}
                                disabled={uploadProgress !== null}
                                id="upload_filename"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<FormattedMessage id="label.description" />}
                                multiline
                                rows={2}
                                rowsMax={5}
                                variant="outlined"
                                onChange={e => onDescriptionChange(e.target.value)}
                                value={description}
                                disabled={uploadProgress !== null}
                                id="upload_description"
                                inputProps={{
                                    'aria-label': formatMessage({ id: 'label.description' }),
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        {uploadProgress === true ?
                            <FormattedMessage id="action.close" /> :
                            <FormattedMessage id="action.cancel" />
                        }
                    </Button>

                    <Button
                        color="primary"
                        disabled={!canUpload()}
                        type="submit"
                        variant="contained"
                    >
                        <FormattedMessage id="action.upload" />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

FileUploadDialog.propTypes = {
    folderId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onExited: PropTypes.func,
    waitForFileReady: PropTypes.bool,
    onSubmit: PropTypes.func,
    uploadProgress: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    uploadError: PropTypes.bool,
    onFileChange: PropTypes.func,
    file: PropTypes.object,
    fileDisplayName: PropTypes.string,
    onFileDisplayNameChange: PropTypes.func,
    folderSelectComponent: PropTypes.node,
    folder: PropTypes.string,
    description: PropTypes.string,
    onDescriptionChange: PropTypes.func,
    fileTypeError: PropTypes.bool,
    linkPath: PropTypes.string,
    isPollCheckLoading: PropTypes.bool,
    isPollCheckLoaded: PropTypes.bool,
};

FileUploadDialog.defaultProps = {
    folderId: null,
    isOpen: false,
    onClose: (() => {}),
    onExited: (() => {}),
    waitForFileReady: false,
    onSubmit: () => {},
    uploadProgress: null,
    uploadError: false,
    onFileChange: () => {},
    file: null,
    fileDisplayName: '',
    onFileDisplayNameChange: () => {},
    folderSelectComponent: null,
    folder: '',
    description: '',
    onDescriptionChange: () => {},
    fileTypeError: false,
    linkPath: null,
    isPollCheckLoading: false,
    isPollCheckLoaded: false,
};
