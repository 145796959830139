import { memo } from 'react';
import PropTypes from 'prop-types';

const FileSize = memo(({ value }) => {
    if (value < 1024) {
        return (value + ' B');
    } else if (value >= 1024 && value < 1048576) {
        return (value/1024).toFixed(1) + ' kB';
    } else if (value >= 1048576 && value < 1073741824) {
        return (value/1048576).toFixed(1) + ' MB';
    }

    return (value/1073741824).toFixed(1) + ' GB';
});

FileSize.propTypes = {
    value: PropTypes.number.isRequired,
};

FileSize.defaultProps = {};

export default FileSize;
