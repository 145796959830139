import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpen from '@material-ui/icons/FolderOpen';
import FolderSelected from '@material-ui/icons/Forward';
import { FormattedMessage } from 'react-intl';
import { axiosWithAuth as axios, useJsonRequest, CancelToken, isCancel } from '../utils/axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { folderAccess, useFolder } from '../routes/Folder';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        marginRight: theme.spacing(1),
    },
    sensitive: {
        color: theme.palette.statped.textSensitive,
    },
    nonSensitive: {
        color: theme.palette.statped.textOpen,
    },
}));

const MoveFileDialog = ({ fileContainerId, folderId, onClose, onSubmit }) => {
    const classes = useStyles();
    const { isAdmin } = useFolder();
    const sensitiveFolders = useJsonRequest(
        isAdmin ?
            '/api/media/admin/folders/sensitive' :
            '/api/media/folders/my_folders/sensitive'
    );
    const openFolders = useJsonRequest(
        isAdmin ?
            '/api/media/admin/folders/non_sensitive' :
            '/api/media/folders/my_folders/non_sensitive'
    );
    const [choice, setChoice] = useState(null);
    const [open, setOpen] = useState(true);
    const [error, setError] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [current, setCurrent] = useState(null);
    const [warningDialog, setWarningDialog] = useState(null);
    const cancelToken = useRef(CancelToken.source());

    useEffect(() => () => cancelToken.current.cancel(), []);

    const handleClose = () => {
        setOpen(false);
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleSubmit = async () => {
        if (current && current.personal && !choice.personal) {
            setWarningDialog(
                <MoveWarning
                    onClose={() => setWarningDialog(null)}
                    onMove={moveFile}
                />
            );
        } else {
            await moveFile();
        }
    };

    const moveFile = async () => {
        try {
            await axios.post(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/move_to/${choice.id}`, {
                cancelToken: cancelToken.current.token
            });
            if (typeof onSubmit === 'function') {
                onSubmit(choice.id, fileContainerId);
            }
        } catch (e) {
            if (!isCancel(e)) {
                if (e.response.data?.error) {
                    setError(<FormattedMessage id="error.move_failed_consent" />);
                } else {
                    setError(<FormattedMessage id="error.move_failed" />);
                }
            }
        }
    };

    useEffect(() => {
        if (current === null && sensitiveFolders.loaded && openFolders.loaded) {
            let folder = sensitiveFolders.data.find(f => f.id === folderId);

            if (!folder) {
                folder = openFolders.data.find(f => f.id === folderId);
            }
            setCurrent(folder);
        }
    }, [sensitiveFolders.loaded, openFolders.loaded]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            onExited={onClose}
            open={open}
            maxWidth="sm"
        >
            <DialogTitle>
                {warningDialog}
                <FormattedMessage id="action.move_to_folder" />
                <div>
                    <TextField
                        fullWidth
                        label={<FormattedMessage id="label.filter" />}
                        disabled={sensitiveFolders.loading || openFolders.loading}
                        onChange={(e) => setFilterText(e.currentTarget.value)}
                        helperText={<FormattedMessage id="help.folder.filter" />}
                        id="filter-input"
                    />
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {(sensitiveFolders.loading || openFolders.loading) && <CircularProgress />}
                <List dense component="nav">
                    <ListFolders
                        folders={
                            [
                                ...(sensitiveFolders.loaded ? sensitiveFolders.data : []),
                                ...(openFolders.loaded ? openFolders.data : [])
                            ].filter(folder => isAdmin ? true : folderAccess(folder?.role?.data?.role).writeAccess)
                        }
                        onSelect={setChoice}
                        selectedFolder={choice}
                        currentFolderId={folderId}
                        filterText={filterText}
                    />
                </List>
            </DialogContent>
            <DialogActions>
                <div className={classes.errorMsg}>
                    {error}
                </div>
                <Button onClick={onClose}>
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={choice === null || !!error}
                >
                    <FormattedMessage id="action.move" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

MoveFileDialog.propTypes = {
    fileContainerId: PropTypes.string.isRequired,
    folderId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

MoveFileDialog.defaultProps = {
    onClose: (() => {}),
    onSubmit: (() => {}),
};

export default MoveFileDialog;

const ListFolders = ({folders, onSelect, currentFolderId, selectedFolder, filterText}) => {
    const filter = filterText.trimLeft().trimRight().toLocaleLowerCase();
    return (folders
        .filter(item => filter.length > 1 ? item.name.toLocaleLowerCase().includes(filter) : true)
        .sort((a, b) => (a.name < b.name) ? -1 :  (b.name < a.name ? 1 : 0))
        .map(folder => (
            <Folder
                key={folder.id}
                folder={folder}
                onSelect={onSelect}
                isCurrent={folder.id === currentFolderId}
                isSelected={selectedFolder && folder.id === selectedFolder.id}
            />
        ))
    )
};

const Folder = ({folder, onSelect, isCurrent, isSelected}) => {
    const classes = useStyles();
    const { isAdmin } = useFolder();
    const clsName = folder.personal ? classes.sensitive : classes.nonSensitive;

    let icon;
    if (isSelected) {
        icon = <FolderSelected />;
    } else if (isCurrent) {
        icon = <FolderOpen />;
    } else {
        icon = <FolderIcon />;
    }

    const secondaryTitle = () => {
        if (isAdmin) {
            return folder.personal ?
                <FormattedMessage id="label.user_folder" /> :
                <FormattedMessage id="label.open_folder" />;
        } else {
            return folder.personal ?
                <FormattedMessage id="places.my_users" /> :
                <FormattedMessage id="places.my_open_folders" />;
        }
    };

    return (
        <ListItem
            button
            dense
            selected={isSelected}
            onClick={() => onSelect(folder)}
            disabled={isCurrent}
        >
            <ListItemIcon className={clsName}>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={folder.name}
                secondary={secondaryTitle()}
                secondaryTypographyProps={{variant: 'caption'}}
            />
        </ListItem>
    );
};

const MoveWarning = ({onClose, onMove}) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            onExited={onClose}
            open={open}
            maxWidth="sm"
        >
            <DialogTitle>
                <FormattedMessage id="prompt.file_move" />
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button color="primary" variant="contained" onClick={onMove}>
                    <FormattedMessage id="action.move" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
