export const getUserLocale = () => {
    switch (navigator.language.split('-')[0]) {
        case 'nb':
        case 'nn':
        case 'no':
            return 'nb';
        case 'en':
        default:
            return 'en';
    }
};
