import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NotSelectedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SelectedIcon from '@material-ui/icons/RadioButtonChecked';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CONSENT_STATE } from './Consents';
import { CONSENT_TYPES } from './ConsentFormFields';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

const ConsentConnectDialog = ({ folderId, fileContainerId, connectedConsents, onClose, onSuccess, isLicense }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const { data, loading, loaded, error } = useJsonRequest(
        isLicense ?
            `/api/media/folders/${folderId}/licenses` :
            `/api/media/folders/${folderId}/consents`
    );
    const [selected, setSelected] = useState(null);

    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        setSaving(true);
        try {
            const response = await axios.post(`/api/media/folders/${folderId}/consents/${selected}/file_containers/${fileContainerId}`);
            setSaving(false);
            onSuccess(response.data);
            handleClose();
        } catch (e) {
            setSaving(false);
            if (e.response.status === 400) {
                setSaveError(<FormattedMessage id="error.file_connect_not_allowed" />);
            } else {
                setSaveError(<FormattedMessage id="error.save_failed" />);
            }
        }
    };

    const canSubmit = (selected !== null);

    const renderState = () => {
        if (loading) {
            return <CircularProgress />;
        } else if (error !== false) {
            return (
                <Typography>
                    <FormattedMessage id="error.loading_failed" />
                </Typography>
            );
        } else if (getFilteredData().length === 0) {
            return (
                <Typography>
                    <FormattedMessage id="notice.empty_data" />
                </Typography>
            );
        }
    }

    function getFilteredData() {
        if (isLicense) {
            return (data ?? [])
                .filter(c =>
                    c.type === CONSENT_TYPES.FILE &&
                    c.state === CONSENT_STATE.DRAFT &&
                    connectedConsents.findIndex(cc => cc.id === c.id) === -1
                );
        }

        return (data ?? [])
            .filter(c =>
                c.type === CONSENT_TYPES.FILE &&
                !c.archived &&
                [
                    CONSENT_STATE.DRAFT,
                    CONSENT_STATE.SENT,
                    CONSENT_STATE.APPROVED,
                ].indexOf(c.state) !== -1 &&
                connectedConsents.findIndex(cc => cc.id === c.id) === -1
            );
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                {isLicense ?
                    <FormattedMessage id="label.connect_file_license" /> :
                    <FormattedMessage id="label.connect_file_consent" />
                }
            </DialogTitle>
            <DialogContent>
                <List dense component="nav">
                    {renderState()}
                    {!error && loaded && getFilteredData()
                        .map(consent => (
                            <ListItem
                                key={consent.id}
                                button
                                dense
                                selected={consent.id === selected}
                                onClick={() => setSelected(consent.id)}
                            >
                                <ListItemIcon>
                                    {consent.id === selected ? <SelectedIcon /> : <NotSelectedIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={consent.name}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                {saveError &&
                    <div className={classes.errorMsg}>
                        {saveError}
                    </div>
                }
                <Button
                    onClick={onClose}
                    variant="outlined"
                    disabled={saving}
                >
                    {(error || saveError) ?
                        <FormattedMessage id="action.close" /> :
                        <FormattedMessage id="action.cancel" />
                    }
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={loading || error || saving || !canSubmit || saveError !== null}
                    onClick={handleSubmit}
                >
                    <FormattedMessage id="action.create" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConsentConnectDialog.propTypes = {
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string.isRequired,
    connectedConsents: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    isLicense: PropTypes.bool,
};

ConsentConnectDialog.defaultProps = {
    onClose: (() => {}),
    onSuccess: (() => {}),
    isLicense: false,
};

export default ConsentConnectDialog;
