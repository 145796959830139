import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConsentFormFields, { CONSENT_CONCERNING, CONSENT_CATEGORY } from './ConsentFormFields';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';

const ConsentDeleteDialog = ({ consent, folderId, sensitive, onClose, onDelete }) => {
    const [open, setOpen] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const {
        data: useRights,
        loading: loadingUseRights
    } = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/use_rights`);
    const {
        data: participants,
        loading: loadingParticipants
    } = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/participants`);

    const handleClose = () => setOpen(false);

    const handleDelete = async () => {
        try {
            setDeleting(true);
            await axios.delete(`/api/media/folders/${folderId}/consents/${consent.id}`);
            setDeleting(false);
            setOpen(false);
            onDelete(consent.id);
        } catch {
            setDeleting(false);
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            closeAfterTransition
            open={open}
            onClose={handleClose}
            onExited={onClose}
        >
            <DialogTitle>
                {consent.category === CONSENT_CATEGORY.LICENSE ?
                    <FormattedMessage id="action.delete_license" /> :
                    <FormattedMessage id="action.delete_consent" />
                }
            </DialogTitle>
            {(loadingParticipants || loadingUseRights) && (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            )}
            {!(loadingParticipants || loadingUseRights) && (
                <>
                    <DialogContent>
                        <ConsentFormFields
                            sensitive={sensitive}
                            consentName={consent.name}
                            participants={participants}
                            useRights={useRights}
                            consentType={consent.type}
                            readOnly={true}
                            isLicense={consent.category === CONSENT_CATEGORY.LICENSE}
                            forServiceUser={consent.forServiceUser ? CONSENT_CONCERNING.FABRIS : CONSENT_CONCERNING.OTHER}
                            forRecordingUsage={consent.recordingUsage}
                            forPublishingUsage={consent.publishingUsage}
                            folderId={folderId}
                            consent={consent}
                            onClose={onClose}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">
                            <FormattedMessage id="action.cancel" />
                        </Button>

                        <Button disabled={deleting} variant="contained" onClick={handleDelete} color="secondary">
                            <FormattedMessage id="action.delete" />
                            {deleting && <CircularProgress size={20} />}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

ConsentDeleteDialog.propTypes = {
    consent: PropTypes.object.isRequired,
    folderId: PropTypes.string.isRequired,
    sensitive: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
};

ConsentDeleteDialog.defaultProps = {
    onClose: (() => {}),
    onDelete: (() => {}),
};

export default ConsentDeleteDialog;
