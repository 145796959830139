import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePolling from '../utils/usePolling';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

export const transportType =  {
    EMAIL: 'email',
    SMS: 'sms',
};

const SmsStatusPoll = ({ pollUrl, delay }) => {
    const [status, setStatus] = useState(null);
    const [isDone, setIsDone] = useState(false);
    const {
        start: pollStart,
        stop: pollStop,
        data: pollData,
        running: pollRunning,
        loaded: pollLoaded,
        loading: pollLoading,
        error: pollError,
    } = usePolling(delay);

    useEffect(() => {
        if (!isDone && !pollRunning && typeof pollUrl === 'string' && pollUrl.length > 0) {
            pollStart(
                pollUrl,
                { method: 'post' }
            )
        }
    }, [isDone, pollRunning, pollStart, pollUrl]);

    useEffect(() => {
        if (!pollLoading && pollError) {
            setStatus(<FormattedMessage id="info.message_sms_error" />);
        } else if (pollLoaded && pollData && pollData.length > 0) {
            switch (pollData[0].status) {
                case 'accepted':
                case 'scheduled':
                case 'queued':
                case 'sending':
                case 'sent':
                    setStatus(
                        <>
                            <FormattedMessage id="info.message_sms_sending" />
                            {' '}
                            <CircularProgress size={15} variant="indeterminate" />
                        </>
                    );
                    break;
                case 'delivered':
                case 'undelivered':
                case 'failed':
                case 'canceled':
                    pollStop();
                    setIsDone(true);
                    setStatus(
                        <FormattedMessage id={`info.message_sms_${pollData[0].status}`} />
                    );
                    break;
                default:
                    pollStop();
                    setIsDone(true);
                    setStatus(
                        <>
                            <FormattedMessage id="info.message_sms_unknown" />
                            {': '}
                            {pollData[0].status}
                        </>
                    );
                    break;
            }
        }
    }, [pollError, pollLoading, pollStop, pollData, pollLoaded]);

    return status;
};

SmsStatusPoll.propTypes = {
    pollUrl: PropTypes.string,
    delay: PropTypes.number,
};

SmsStatusPoll.defaultProps = {
    pollUrl: null,
    delay: 5000,
};

export default SmsStatusPoll;
