import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import Typography from '@material-ui/core/Typography';
import UploadFileIcon from '@material-ui/icons/UploadFile';
import { makeStyles } from '@material-ui/core/styles';
import DataTable, { COLUMN_STYLES, COLUMN_TYPES } from './DataTable';
import ExternalViewFileDialog from './ExternalViewFileDialog';
import { FileUploadDialogExternal } from './FileUploadDialog';
import { getLabelIdForContentType } from '../utils/file';
import { useJsonRequest } from '../utils/axios';
import { ROLE_TYPE } from './FolderAccess';
import { fileIsOfType, FILE_TYPE } from '../utils/file';

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(2),
    },
    link: {
        cursor: 'pointer',
    },
}));

const DashboardFiles = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [showUpload, setShowUpload] = useState(false);
    const { data: rolesData, loading: rolesLoading, error: rolesError} = useJsonRequest('/api/media/folders/roles/mine');
    const {
        data: fileContainersData,
        loading: fileContainersLoading,
        error: fileContainersError,
        reload: fileContainersReload,
    } = useJsonRequest('/api/media/file_containers/roles/mine');
    const [dialog, setDialog] = useState(null);

    useEffect(() => {
        if (!rolesLoading && !rolesError) {
            setShowUpload(typeof rolesData.find(r => r.role === ROLE_TYPE.UPLOAD) !== 'undefined');
        }
    }, [rolesData, rolesLoading, rolesError]);

    const handleCloseDialog = () => setDialog(null);
    const handleOpenUploadDialog = () => setDialog(
        <FileUploadDialogExternal
            onClose={handleCloseDialog}
            onSuccess={() => {fileContainersReload()}}
        />
    );

    const handleViewFileDialog = (data) => setDialog(
        <ExternalViewFileDialog
            fileContainer={data.externalFileContainer.data}
            file={data.externalFileContainer.data.primaryFile.data}
            onClose={handleCloseDialog}
        />
    );

    const getFileType = fileType => {
        const id = getLabelIdForContentType(fileType);
        return formatMessage({ id });
    };

    const mapRowData = (row) => {
        const folder = row.externalFileContainer.data.folder.data;
        const file = row.externalFileContainer.data.primaryFile.data;
        const isVideo = fileIsOfType(file, FILE_TYPE.VIDEO);
        const isAudio = fileIsOfType(file, FILE_TYPE.AUDIO);
        const isImage = fileIsOfType(file, FILE_TYPE.IMAGE);

        const result = {
            link: row.externalFileContainer.data.name,
            createdAt: row.externalFileContainer.data.createdAt,
            fileType: getFileType(file.contentType),
            sensitive: folder.personal
                ? formatMessage({ id: "text.yes" })
                : formatMessage({ id: "text.no" }),
            owner: folder.owner.data.name,
            actions: null,
        };

        let title;
        let icon;

        if (isVideo || isAudio) {
            title = formatMessage({ id: 'action.play' });
            icon = <PlayIcon />;
        } else if (isImage) {
            title = formatMessage({ id: 'action.view_image' });
            icon = <ImageIcon />;
        } else {
            return result;
        }

        return {
            ...result,
            ...{
                link: (
                    <Link
                        component="span"
                        onClick={() => handleViewFileDialog(row)}
                        className={classes.link}
                        aria-label={title  + ` ${row.externalFileContainer.data.name}`}
                    >
                        {row.externalFileContainer.data.name}
                    </Link>
                ),
                actions: (
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => handleViewFileDialog(row)}
                        aria-label={title  + ` ${row.externalFileContainer.data.name}`}
                        startIcon={icon}
                    >
                        {title}
                    </Button>
                ),
            },
        };
    };

    return (
        <Grid item xs={12}>
            <Paper elevation={1} variant="outlined">
                {dialog}
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid container item xs alignItems="center">
                        <Grid item>
                            <Typography className={classes.heading} component="h2" variant="h5">
                                <FormattedMessage id="places.files" />
                            </Typography>
                        </Grid>
                    </Grid>
                    {showUpload &&
                        <Grid item>
                            <Typography className={classes.heading}>
                                <Button
                                    disabled={rolesLoading}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleOpenUploadDialog}
                                    startIcon={<UploadFileIcon />}
                                    aria-label={formatMessage({ id: 'action.upload_media' })}
                                >
                                    <FormattedMessage id="action.upload_media" />
                                </Button>
                            </Typography>
                        </Grid>
                    }
                </Grid>

                <DataTable
                    columns={{
                        link: <FormattedMessage id="label.name" />,
                        createdAt: <FormattedMessage id="label.uploaded" />,
                        fileType: <FormattedMessage id="label.file_type" />,
                        sensitive: <FormattedMessage id="label.sensitive" />,
                        owner: <FormattedMessage id="label.owner" />,
                        actions: <FormattedMessage id="label.actions" />,
                    }}
                    columnTypes={{
                        createdAt: COLUMN_TYPES.DATE,
                        actions: COLUMN_TYPES.RAW,
                    }}
                    columnStyles={{
                        createdAt: COLUMN_STYLES.FIT_CONTENT,
                        fileType: COLUMN_STYLES.FIT_CONTENT,
                        sensitive: COLUMN_STYLES.FIT_CONTENT,
                        owner: COLUMN_STYLES.FIT_CONTENT,
                        actions: COLUMN_STYLES.FIT_CONTENT,
                    }}
                    rows={(fileContainersData || []).map(mapRowData)}
                    loading={fileContainersLoading || rolesLoading}
                    error={fileContainersError}
                    defaultSortColumn="createdAt"
                    defaultSortOrder="desc"
                    sortable={['link', 'createdAt', 'fileType', 'sensitive', 'owner']}
                    sortColumnMap={{ 'link': 'name' }}
                />
            </Paper>
        </Grid>
    );
};

export default DashboardFiles;
