import axios from 'axios';

const JWT_STORAGE_KEY = 'stpe-jwt';
const JWT_TIMESTAMP_KEY = 'stpe-jwt-timestamp';

// possible future improvement: calculate based on expiry in JWT
export const JWT_TIMEOUT_MS = 60000;

export class NeedsAuthenticationError extends Error {
}

const getCachedJwt = () => {
    const lastRefreshed = localStorage.getItem(JWT_TIMESTAMP_KEY);

    if (lastRefreshed && lastRefreshed > Date.now() - JWT_TIMEOUT_MS) {
        return localStorage.getItem(JWT_STORAGE_KEY);
    }
}

const setCachedJwt = jwt => {
    localStorage.setItem(JWT_STORAGE_KEY, jwt);
    localStorage.setItem(JWT_TIMESTAMP_KEY, String(Date.now()));
};

const clearCachedJwt = () => {
    localStorage.removeItem(JWT_STORAGE_KEY);
    localStorage.removeItem(JWT_TIMESTAMP_KEY);
};

export const getOrFetchJwt = async () => {
    let token = getCachedJwt();

    if (!token) {
        token = await fetchAndStoreJwt();
    }

    return token;
};

export const fetchAndStoreJwt = async () => {
    let token;

    try {
        const response = await axios.get('/api/identity/personal/jwt');
        token = response.data.jwt;
    } catch (e) {
        if (e.response && e.response.status === 401) {
            clearCachedJwt();

            throw new NeedsAuthenticationError();
        }

        throw e;
    }

    if (!token) {
        throw new Error('No token in data');
    }

    setCachedJwt(token);

    return token;
};
