import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import { getUserLocale } from '../language';
import { useJsonRequest } from '../utils/axios';

const useStyles = makeStyles(theme => ({
    tagsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const Tags = ({value, label, labelId, onChange, selected, onDelete, disabled}, props) => {
    const classes = useStyles();
    const { data, loaded } = useJsonRequest('/api/media/tags.json');
    const [selectedTags, setSelectedTags] = useState([]);
    const locale = useRef(getUserLocale());

    useEffect(() => {
        setSelectedTags(selected.map(tag => tag.tag));
    }, [selected]);

    const handleSelect = (event) => {
        if (typeof onChange === 'function') {
            onChange(event.target.value);
        }
    }

    const handleDelete = (id) => {
        if (typeof onDelete === 'function') {
            onDelete(id);
        }
    }

    return (
        <>
            <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id="tags">
                    <FormattedMessage id="label.tags" />
                </InputLabel>

                <Select
                    disabled={!loaded ? false : disabled}
                    value={!loaded ? '' : (value || '')}
                    label={label || <FormattedMessage id="label.tags" />}
                    labelId={labelId || 'tags'}
                    onChange={handleSelect}
                    id="tags-selector"
                    inputProps={{
                        'aria-labelledby': labelId || 'tags',
                    }}
                    {...props}
                >
                    {loaded && Object.entries(data).map(([key, { label }]) => (
                        <MenuItem key={key} value={key} disabled={selectedTags.includes(key)}>
                            {label[locale.current] ?? label.nb}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {selected.length > 0 &&
                <div className={classes.tagsContainer}>
                    {selected
                        .sort((a, b) => (a.tag > b.tag) ? 1 :  (a.tag < b.tag ? -1 : 0))
                        .map(tag => (
                            <Chip
                                key={tag.id}
                                variant="outlined"
                                size="small"
                                onDelete={() => handleDelete(tag.id)}
                                label={tag.tag}
                                disabled={disabled}
                            />
                        ))
                    }
                </div>
            }
        </>
    );
};

Tags.propTypes = {
    value: PropTypes.any,
    label: PropTypes.node,
    labelId: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    selected: PropTypes.array,
};

Tags.defaultProps = {
    value: '',
    label: '',
    labelId: '',
    onChange: () => {},
    onDelete: () => {},
    selected: [],
};

export default Tags;
