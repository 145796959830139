import { useJsonRequest } from './axios';

export const useStream = (url) => {
    const { data, error, loading, loaded, reload } = useJsonRequest(url, { method: 'post' });

    return {
        error,
        loaded,
        loading,
        reload,
        streamUrl: !loading && !error
            ? (data?.url ?? data?.streamingUrls?.defaultProgressiveUrl)
            : null,
        contentType: data?.contentType,
    };
};
