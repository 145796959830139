import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { useJsonRequest } from '../utils/axios';
import DataTable, {COLUMN_STYLES, COLUMN_TYPES} from './DataTable';
import { CONSENT_STATE } from './Consents';

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(2),
    },
    tableFlex: {
        alignItems: 'center',
        display: 'flex',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
    awaitingAnswer: {
        color: orange[700],
    },
    declined: {
        color: red[700],
    },
    approved: {
        color: green[700],
    },
}));

const Status = ({ state }) => {
    const classes = useStyles();
    let icon, label;

    switch (state) {
        case CONSENT_STATE.SENT:
            icon = <WarningIcon className={classes.awaitingAnswer} />;
            label = <FormattedMessage id="consent.awaiting_answer" />;
            break;
        case CONSENT_STATE.DECLINED:
            icon = <CancelIcon className={classes.declined} />;
            label = <FormattedMessage id="consent.declined" />;
            break;
        case CONSENT_STATE.APPROVED:
            icon = <CheckCircleIcon className={classes.approved} />;
            label = <FormattedMessage id="consent.consents" />;
            break;
        case CONSENT_STATE.WITHDRAWN:
            icon = <ErrorIcon className={classes.declined} />
            label = <FormattedMessage id="consent.withdrawn" />;
            break;
        default:
            return null;
    }

    return (
        <span className={classes.tableFlex}>
            {icon}
            <span>{label}</span>
        </span>
    );
};

const DashboardConsentsForApproval = () => {
    const classes = useStyles();
    const { data, loading, error } = useJsonRequest('/api/media/consents/mine');

    const handleStateSort = (a, b, key, sortOrder) => {
        const order = {
            [CONSENT_STATE.SENT]: 0,
            [CONSENT_STATE.APPROVED]: 1,
            [CONSENT_STATE.WITHDRAWN]: 2,
            [CONSENT_STATE.DECLINED]: 3,
        };
        const aState = order[a.answer?.data?.answer ?? CONSENT_STATE.SENT];
        const bState = order[b.answer?.data?.answer ?? CONSENT_STATE.SENT];
        const sorter = (a, b) => (a < b) ? 1 : (b < a ? -1 : 0);

        if (aState !== bState) {
            return sortOrder === 'desc' ? sorter(aState, bState) : -sorter(aState, bState);
        }

        return sortOrder === 'desc' ? -sorter(a.updatedAt, b.updatedAt) : sorter(a.updatedAt, b.updatedAt);
    };

    return (
        <Grid item xs={12}>
            <Paper elevation={1} variant="outlined">
                <Typography className={classes.heading} component="h2" variant="h5">
                    <FormattedMessage id="label.consents" />
                </Typography>

                <DataTable
                    columns={{
                        name: <FormattedMessage id="label.name" />,
                        updatedAt: <FormattedMessage id="label.date" />,
                        state: <FormattedMessage id="label.status" />,
                        actions: <FormattedMessage id="label.actions" />,
                    }}
                    columnTypes={{
                        updatedAt: COLUMN_TYPES.DATE,
                        actions: COLUMN_TYPES.RAW,
                    }}
                    columnStyles={{
                        actions: COLUMN_STYLES.FIT_CONTENT,
                    }}
                    rows={(data || []).map(consent => ({
                        ...consent,
                        state: <Status state={consent.answer.data.answer ?? CONSENT_STATE.SENT} />,
                        actions: (
                            <Button
                                component={RouterLink}
                                color={consent.answer.data.id ? 'default' : 'primary'}
                                to={`/consents/${consent.id}`}
                                size="small"
                                variant="contained"
                            >
                                {consent.answer.data.id ? (
                                    <FormattedMessage id="action.open_edit_consent" />
                                ) : (
                                    <FormattedMessage id="action.take_stance" />
                                )}
                            </Button>
                        ),
                    }))}
                    loading={loading}
                    error={error}
                    sortable={['name', 'updatedAt', 'state']}
                    defaultSortColumn="state"
                    defaultSortOrder="asc"
                    sortHandler={{ 'state': handleStateSort }}
                />
            </Paper>
        </Grid>
    );
};

export default DashboardConsentsForApproval;
