import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { CONSENT_STATE } from './Consents';

const ConsentAnswerForm = ({answer, disabled, onSelect}) => {
    const { formatMessage } = useIntl();
    const hasAnswered = !!answer;
    const [selected, setSelected] = useState(answer);

    const handleSelect = event => {
        setSelected(event.currentTarget.value);
        onSelect(event.currentTarget.value);
    };

    function getOptions() {
        const options = [];

        if ([CONSENT_STATE.APPROVED, CONSENT_STATE.WITHDRAWN].indexOf(answer) !== -1) {
            options.push(
                <FormControlLabel
                    key={CONSENT_STATE.WITHDRAWN}
                    control={<Radio value={CONSENT_STATE.WITHDRAWN} size="small" />}
                    label={<FormattedMessage id="label.consent_withdraw" />}
                    disabled={answer === CONSENT_STATE.WITHDRAWN || disabled}
                />,
                <FormControlLabel
                    key={CONSENT_STATE.APPROVED}
                    control={<Radio value={CONSENT_STATE.APPROVED} size="small" />}
                    label={<FormattedMessage id="label.consent.approved" />}
                    disabled={answer === CONSENT_STATE.WITHDRAWN || disabled}
                />
            );
        } else {
            options.push(
                <FormControlLabel
                    key={CONSENT_STATE.DECLINED}
                    control={<Radio value={CONSENT_STATE.DECLINED} size="small" />}
                    disabled={hasAnswered || disabled}
                    label={<FormattedMessage id="action.decline" />}
                />,
                <FormControlLabel
                    key={CONSENT_STATE.APPROVED}
                    control={<Radio value={CONSENT_STATE.APPROVED} size="small" />}
                    disabled={hasAnswered || disabled}
                    label={<FormattedMessage id="action.approve" />}
                />
            );
        }

        return options;
    }

    return (
        <fieldset
            id="consent-answer"
        >
            <legend>{formatMessage({ id: 'action.answer_consent' })}</legend>
            <RadioGroup
                onChange={handleSelect}
                value={selected}
            >
                {getOptions()}
            </RadioGroup>
        </fieldset>
    );
};

ConsentAnswerForm.protoTypes = {
    answer: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
};

ConsentAnswerForm.defaultProps = {
    answer: null,
    disabled: true,
    onSelect: () => {},
};

export default ConsentAnswerForm;
