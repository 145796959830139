import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../auth/AuthProvider';
import BasePage from './BasePage';

const AuthRoute = ({ strategy, ...props }) => {
    const { loading, isAuthenticated, strategy: currentStrategy } = useAuth();
    const { formatMessage } = useIntl();

    if (loading) {
        return (
            <BasePage>
                <CircularProgress />
            </BasePage>
        );
    }

    if (!isAuthenticated) {
        // TODO: enable returning to the page that took you to authentication
        return <Redirect to="/" />;
    }

    if (strategy && currentStrategy !== strategy) {
        return <BasePage title={formatMessage({ id: 'error.access_denied' })} />;
    }

    return <Route {...props} />;
};

export default AuthRoute;
