import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ChangeOwnerIcon from '@material-ui/icons/People';
import ConnectFabrisIcon from '@material-ui/icons/FlashOn';
import IconButton from '@material-ui/core/IconButton';
import LetExternalUploadIcon from '@material-ui/icons/Publish';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NewConsentIcon from '@material-ui/icons/PostAdd';
import RemoveFolderIcon from '@material-ui/icons/Cancel';
import UploadConsentIcon from '@material-ui/icons/Publish';
import UploadFileIcon from '@material-ui/icons/Publish';
import ConsentCreateDialog from './ConsentCreateDialog';
import ConsentCreateFromTemplate from './ConsentCreateFromTemplate';
import FileUploadDialog, { FileUploadDialogInternal } from './FileUploadDialog';
import { FolderAccessExternalUploadDialog } from './ExternalAccessDialogs';
import RemoveFolderDialog from './RemoveFolderDialog';
import { useFolder } from '../routes/Folder';
import { FolderConnectFabrisDialog } from './FolderConnectFabrisDialog';
import { FolderChangeOwnerDialog } from './FolderChangeOwnerDialog';

const FolderMenu = ({ folderId, folderName, sensitive, owner, onOwnerChangeSuccess, connectFabrisEnabled, consentRef, aboutRef, folderAccessRef }) => {
    const [dialog, setDialog] = useState(null);
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { formatMessage } = useIntl();
    const { isAdmin, ownerAccess, writeAccess, readAccess } = useFolder();

    const handleDialogClose = () => setDialog(null);
    const handleDeleteClick = async () => {
        setDialog(
            <RemoveFolderDialog
                folderId={folderId}
                folderName={folderName}
                onClose={handleDialogClose}
                onSuccess={() => {
                    history.push('/dashboard');
                }}
            />
        );
        handleMenuToggle();
    };

    const handleNewConsentClick = async () => {
        if (consentRef.current) {
            consentRef.current.create();
        } else {
            setDialog(
                <ConsentCreateDialog
                    folderId={folderId}
                    onClose={handleDialogClose}
                    onPersist={handleDialogClose}
                    sensitive={sensitive}
                />
            );
        }
        handleMenuToggle();
    };

    const handleConsentUploadClick = async () => {
        if (consentRef.current) {
            consentRef.current.uploadConsent();
        } else {
            setDialog(
                <ConsentCreateFromTemplate
                    folderId={folderId}
                    onClose={handleDialogClose}
                    onPersist={handleDialogClose}
                    sensitive={sensitive}
                />
            );
        }
        handleMenuToggle();
    };

    const handleFileUploadClick = async () => {
        setDialog(
            <FileUploadDialogInternal
                onClose={handleDialogClose}
                folderId={folderId}
                waitForFileReady={true}
            />
        );
        handleMenuToggle();
    };

    const handleFileUploadExternalClick = async () => {
        if (folderAccessRef.current) {
            folderAccessRef.current.externalUploader();
        } else {
            setDialog(
                <FolderAccessExternalUploadDialog
                    onClose={handleDialogClose}
                />
            );
        }
        handleMenuToggle();
    };

    const handleConnectToFabris = async () => {
        if (aboutRef.current) {
            aboutRef.current.connectToFabris();
        } else {
            setDialog(
                <FolderConnectFabrisDialog
                    folderId={folderId}
                    onClose={handleDialogClose}
                />
            );
        }
        handleMenuToggle();
    };

    const handleChangeOwnerClick = async () => {
        if (folderAccessRef.current) {
            folderAccessRef.current.changeOwner();
        } else {
            setDialog(
                <FolderChangeOwnerDialog
                    folderId={folderId}
                    ownerId={owner?.id}
                    ownerName={owner?.name}
                    sensitive={sensitive}
                    onSuccess={onOwnerChangeSuccess}
                    onClose={handleDialogClose}
                />
            );
        }
    };

    const handleMenuToggle = async (e) => {
        if (menuOpen) {
            setMenuOpen(false);
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            setMenuOpen(true);
        }
    };

    return (
        <Fragment>
            <IconButton
                onClick={handleMenuToggle}
                aria-haspopup={true}
                aria-controls="folder-menu"
                aria-label={formatMessage({id: 'label.actions'})}
            >
                <MenuIcon />
            </IconButton>
            {dialog}
            <Menu
                id="folder-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                keepMounted
                onClose={handleMenuToggle}
            >
                <MenuItem
                    button
                    onClick={isAdmin || readAccess ? handleFileUploadClick : null}
                    disabled={isAdmin ? false : !readAccess}
                >
                    <ListItemIcon>
                        <UploadFileIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id="action.upload_media" />
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    button
                    onClick={isAdmin || writeAccess ? handleFileUploadExternalClick : null}
                    disabled={isAdmin ? false : !writeAccess}
                >
                    <ListItemIcon>
                        <LetExternalUploadIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id="action.let_an_external_upload_a_file" />
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    button
                    onClick={isAdmin || writeAccess ? handleNewConsentClick : null}
                    disabled={isAdmin ? false : !writeAccess}
                >
                    <ListItemIcon>
                        <NewConsentIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id="action.new_consent" />
                    </ListItemText>
                </MenuItem>
                {!sensitive &&
                    <MenuItem
                        button
                        onClick={isAdmin || writeAccess ? handleConsentUploadClick : null}
                        disabled={isAdmin ? false : !writeAccess}
                    >
                        <ListItemIcon>
                            <UploadConsentIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="action.upload_consent" />
                        </ListItemText>
                    </MenuItem>
                }
                <MenuItem
                    button
                    onClick={(isAdmin && !sensitive) || ownerAccess ? handleChangeOwnerClick : null}
                    disabled={isAdmin ? sensitive : !ownerAccess}
                >
                    <ListItemIcon>
                        <ChangeOwnerIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id="action.change_owner" />
                    </ListItemText>
                </MenuItem>
                {!sensitive &&
                    <MenuItem
                        button
                        onClick={writeAccess ? handleConnectToFabris : null}
                        disabled={!connectFabrisEnabled || !writeAccess}
                    >
                        <ListItemIcon>
                            <ConnectFabrisIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="action.connect_to_fabris_case" />
                        </ListItemText>
                    </MenuItem>
                }
                <MenuItem
                    button
                    onClick={(isAdmin && !sensitive) || ownerAccess ? handleDeleteClick : null}
                    disabled={isAdmin ? sensitive : !ownerAccess}
                >
                    <ListItemIcon>
                        <RemoveFolderIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id="action.remove_folder" />
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

FolderMenu.propTypes = {
    folderId: PropTypes.string.isRequired,
    folderName: PropTypes.string.isRequired,
    sensitive: PropTypes.bool.isRequired,
    owner: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    onOwnerChangeSuccess: PropTypes.func,
    connectFabrisEnabled: PropTypes.bool,
    consentRef: PropTypes.object,
    aboutRef: PropTypes.object,
    folderAccessRef: PropTypes.object,
};

FolderMenu.defaultProps = {
    onOwnerChangeSuccess: () => {},
    connectFabrisEnabled: false,
    consentRef: null,
    aboutRef: null,
    folderAccessRef: null,
};

export default FolderMenu;
