import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth, AUTH_STRATEGY } from '../auth/AuthProvider';

const Crumbs = ({ crumbs, current }) => {
    const { strategy } = useAuth();

    const root = (
        <Link key="/dashboard" component={RouterLink} to="/dashboard" >
            {strategy === AUTH_STRATEGY.AUTH0 &&
                <FormattedMessage id="places.dashboard" />
            }
            {strategy === AUTH_STRATEGY.IDPORTEN &&
                <FormattedMessage id="places.the_portal" />
            }
        </Link>
    );
    const currentItem = (current !== null ? <Crumb title={current} /> : null);

    if (crumbs === true) {
        return (
            <Breadcrumbs>
                {currentItem}
            </Breadcrumbs>
        );
    } else if (Array.isArray(crumbs)) {
        return (
            <Breadcrumbs>
                {root}
                {crumbs.filter((crumb => crumb.display ?? true))
                    .map(crumb => <Crumb key={crumb.to} {...crumb} />)
                }
                {currentItem}
            </Breadcrumbs>
        );
    } else if (typeof crumbs === 'object' && crumbs.to) {
        const item = ((crumbs.display ?? true) ? <Crumb key={crumbs.to} {...crumbs} /> : null);
        return (
            <Breadcrumbs>
                {root}
                {item}
                {currentItem}
            </Breadcrumbs>
        );
    } else if (React.isValidElement(crumbs)) {
        return (
            <Breadcrumbs>
                {root}
                {crumbs}
                {currentItem}
            </Breadcrumbs>
        );
    }

    return false;
};

Crumbs.propTypes = {
    crumbs: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.node,
        PropTypes.shape({
            titleId: PropTypes.string,
            title: PropTypes.node,
            to: PropTypes.string,
        }),
        PropTypes.arrayOf(PropTypes.shape({
            titleId: PropTypes.string,
            title: PropTypes.node,
            to: PropTypes.string,
        })),
    ]),
    current: PropTypes.node,
};

Crumbs.defaultProps = {
    crumbs: false,
    current: null,
};

const Crumb = ({titleId, title, to }) => {
    const name = (titleId !== null ? <FormattedMessage id={titleId} /> : title);

    if (typeof to === 'string' && to.length > 0) {
        return (
            <Link component={RouterLink} to={to} >
                {name}
            </Link>
        );
    }

    return <Typography>{name}</Typography>;
};

Crumb.propTypes = {
    titleId: PropTypes.string,
    title: PropTypes.node,
    to: PropTypes.string,
    display: PropTypes.bool,
};

Crumb.defaultProps = {
    titleId: null,
    title: '',
    to: null,
    display: true,
};

export default Crumbs;
