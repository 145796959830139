import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';

const DeleteMediaFileDialog = ({ fileOnly, fileId, fileContainerId, folderId, name, onClose, onSubmit }) => {
    const cancelToken = useRef(CancelToken.source());
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const handleClose = () => setOpen(false);

    useEffect(() => () => cancelToken.current.cancel(), []);

    const handleSubmit = async () => {
        const url = fileOnly
            ? `/api/media/folders/${folderId}/file_containers/${fileContainerId}/files/${fileId}`
            : `/api/media/folders/${folderId}/file_containers/${fileContainerId}`;

        setLoading(true);

        try {
            await axios.delete(url, { cancelToken: cancelToken.current.token });
            setLoading(false);
            onSubmit(folderId, fileContainerId, fileId);
            handleClose();
        } catch (e) {
            if (!isCancel(e)) {
                if (e.response.data?.error) {
                    setError(<FormattedMessage id="error.delete_file_consent" />);
                }
                setLoading(false);
            }
        }
    };

    return (
        <Dialog open={open} onExited={onClose} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <FormattedMessage id="action.delete_media_file" />
            </DialogTitle>

            <DialogContent>
                {error}
                {loading && !error && <CircularProgress />}
                {!loading && !error && (
                    <Fragment>
                        <Typography>
                            <FormattedMessage id="prompt.confirm_media_removal" values={{ name }} />
                        </Typography>

                        {!fileOnly && (
                            <Typography>
                                <FormattedMessage id="notice.file_container_delete_cascade" />
                            </Typography>
                        )}
                    </Fragment>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!!error}
                >
                    <FormattedMessage id="action.delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteMediaFileDialog.propTypes = {
    fileOnly: PropTypes.bool,
    fileContainerId: PropTypes.string.isRequired,
    folderId: PropTypes.string.isRequired,
    fileId: (props, propName) => {
        if (props.fileOnly && props[propName] === null) {
            return new Error(`Prop ${propName} must be provided in file-only mode`);
        }

        if (!props.fileOnly && props[propName] !== null) {
            return new Error(`Prop ${propName} must not be provided without setting fileOnly`);
        }

        if (props.fileOnly && typeof props[propName] !== 'string') {
            return new Error(`Prop ${propName} must be string`);
        }
    },
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

DeleteMediaFileDialog.defaultProps = {
    fileId: null,
    fileOnly: false,
    onClose: () => {},
    onSubmit: () => {},
};

export default DeleteMediaFileDialog;
