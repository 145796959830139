import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { CONSENT_TYPES, CONSENT_CATEGORY } from './ConsentFormFields';
import { CONSENT_STATE } from './Consents';
import DataTable, { COLUMN_STYLES } from './DataTable';
import Section from '../components/Section';
import { useJsonRequest } from '../utils/axios';

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(2),
    },
    link: {
        cursor: 'pointer',
    },
}));

export const TemplateConsentByConsent = ({folderId, consent}) => {
    const history = useHistory();
    const classes = useStyles();

    const {data, loading, error } = useJsonRequest(
        consent !== null &&
        consent.templateId !== null ?
            `/api/media/folders/${folderId}/consents/${consent.templateId}`:
            null
    );

    const highlight = () => {
        history.replace({
            state: {
                highlight: {
                    section: CONSENT_CATEGORY.CONSENT,
                    id: consent.templateId,
                },
            },
        });
        const element = document.getElementById(consent.templateId);
        if (element) {
            element.scrollIntoView({
                block: 'center',
                inline: 'center',
            });
        }
    };

    return (
        <Typography component="div">
            <FormLabel component="span">
                <FormattedMessage id="label.template_consent" />{': '}
            </FormLabel>
            <Typography component="span">
                {loading && <CircularProgress />}
                {data !== null &&
                    <Link
                        component="span"
                        onClick={highlight}
                        className={classes.link}
                    >
                        <strong>
                            {data.name}
                        </strong>
                    </Link>
                }
                {error &&
                    <Typography component="div">
                        <i>
                            <FormattedMessage id="error.loading_failed" />
                        </i>
                    </Typography>
                }
            </Typography>
        </Typography>
    );
};

export const ConsentsByTemplateConsent = ({folderId, templateConsent}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const classes = useStyles();
    const { data, loading, error } = useJsonRequest(
        templateConsent !== null &&
        templateConsent.type === CONSENT_TYPES.TEMPLATE &&
        templateConsent.state !== CONSENT_STATE.DRAFT ?
            `/api/media/folders/${folderId}/consents/${templateConsent.id}/consents`:
            null
    );

    const highlight = (item) => {
        history.replace({
            state: {
                highlight: {
                    section: CONSENT_CATEGORY.CONSENT,
                    id: item.id,
                },
            },
        });
        const element = document.getElementById(item.id);
        if (element) {
            element.scrollIntoView({
                block: 'center',
                inline: 'center',
            });
        }
    };

    return (
        <Section
            title={<FormattedMessage id="label.consents" />}
            titleComponent="div"
            titleVariant="button"
        >
            <DataTable
                error={error}
                loading={loading}
                addRowId={false}
                columns={{
                    link: <FormattedMessage id="label.name" />,
                    state: <FormattedMessage id="label.status" />,
                }}
                columnStyles={{
                    state: COLUMN_STYLES.FIT_CONTENT,
                }}
                rows={(data || []).map(row => ({
                    name: row.name,
                    link: (
                        <Link
                            key={row.id}
                            component="div"
                            onClick={() => highlight(row)}
                            className={classes.link}
                        >
                            {row.name}
                        </Link>
                    ),
                    state: formatMessage({ id: `label.consent.${row.state}` }),
                }))}
                sortable={['link', 'state']}
                defaultSortColumn="link"
                defaultSortOrder="asc"
                sortColumnMap={{ 'link': 'name' }}
            />
        </Section>
    );
};
