import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    grid: {
        marginTop: 0,
        marginBottom: 0,
    },
}));

const ConsentTypeHelpDialog = ({ onClose }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="label.consent_type_help" />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4} className={classes.grid}>
                    <Grid item xs={12}>
                        <Typography variant="button" component="div">
                            <FormattedMessage id="label.folder_consent" />
                        </Typography>
                        <FormattedMessage id="help.consent_template.folder" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="button" component="div">
                            <FormattedMessage id="label.file_consent" />
                        </Typography>
                        <FormattedMessage id="help.consent_template.file" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="button" component="div">
                            <FormattedMessage id="label.template_consent" />
                        </Typography>
                        <FormattedMessage id="help.consent_template.template" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    <FormattedMessage id="action.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConsentTypeHelpDialog.propTypes = {
    onClose: PropTypes.func,
};

ConsentTypeHelpDialog.defaultProps = {
    onClose: (() => {}),
};

export default ConsentTypeHelpDialog;
