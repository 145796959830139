import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ConsentFormFields, { CONSENT_CONCERNING, CONSENT_CATEGORY, CONSENT_TYPES } from './ConsentFormFields';
import { CONSENT_STATE } from './Consents';
import { ConsentListConnectedFiles } from './ConsentFileList';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

const ConsentEditDialog = ({ folderId, onClose, onPersist, sensitive, consent, readOnly }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [saving, setSaving] = useState(false);
    const [consentName, setConsentName] = useState(consent.name);
    const [deletedParticipants, setDeletedParticipants] = useState([]);
    const [deletedUseRights, setDeletedUseRights] = useState([]);
    const [addedParticipants, setAddedParticipants] = useState([]);
    const [addedUseRights, setAddedUseRights] = useState([]);
    const [error, setError] = useState(false);
    const isFile = consent.type === CONSENT_TYPES.FILE;
    const isLicense = consent.category === CONSENT_CATEGORY.LICENSE;
    const {
        data: useRights,
        setData: setUseRights,
        loading: loadingUseRights
    } = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/use_rights`);
    const {
        data: participants,
        setData: setParticipants,
        loading: loadingParticipants
    } = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/participants`);
    const {
        data: files,
        loading: loadingFiles,
    } = useJsonRequest(isFile ? `/api/media/folders/${folderId}/consents/${consent.id}/file_containers` : null);

    const handleConsentNameChange = event => setConsentName(event.target.value);

    const handleClose = () => setOpen(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setSaving(true);

        try {
            if (consentName !== consent.name) {
                const response = await axios.put(`/api/media/folders/${folderId}/consents/${consent.id}`, {
                    name: consentName,
                });
                onPersist(response.data);
            }
            await Promise.all([
                ...addedParticipants.map(participant =>
                    axios.post(`/api/media/folders/${folderId}/consents/${consent.id}/participants`, participant)
                ),
                ...deletedParticipants.map(participant =>
                    axios.delete(`/api/media/folders/${folderId}/consents/${consent.id}/participants/${participant.personId}`)
                ),
                ...addedUseRights.map(useRight =>
                    axios.post(`/api/media/folders/${folderId}/consents/${consent.id}/use_rights`, useRight)
                ),
                ...deletedUseRights.map(useRight =>
                    axios.delete(`/api/media/folders/${folderId}/consents/${consent.id}/use_rights/${useRight.id}`)
                ),
            ]);
            setSaving(false);
            onPersist();
            handleClose();
        } catch {
            setSaving(false);
            setError(true);
        }
    };

    const handleAddParticipant = participant => {
        setAddedParticipants([...addedParticipants, participant]);
        setParticipants([...participants, participant]);
    }

    const handleRemoveParticipant = (participant, index) => {
        if (participant.id) {
            setDeletedParticipants([...deletedParticipants, participant]);
        } else {
            setAddedParticipants(addedParticipants.filter(p => p.birthNumber !== participant.birthNumber));
        }
        setParticipants([
            ...participants.slice(0, index),
            ...participants.slice(index + 1),
        ]);
    };

    const handleAddUseRight = useRight => {
        setAddedUseRights([...addedUseRights, useRight]);
        setUseRights([...useRights, useRight]);
    }

    const handleRemoveUseRight = (useRight, index) => {
        if (useRight.id) {
            setDeletedUseRights([...deletedUseRights, useRight]);
        }
        setUseRights([
            ...useRights.slice(0, index),
            ...useRights.slice(index + 1),
        ]);
    };

    const handleAnswerUpdated = (participantId, answer) => {
        setParticipants(
            participants.map(p => (
                p.id === participantId ? {...p, ...{answer: {data: answer}}} : p
            )
        ));
        onPersist();
    };

    useEffect(() => {
        if (useRights) {
            setAddedUseRights(useRights.filter(u => typeof u.id === 'undefined'));
        }
    }, [useRights]);

    const canSubmit = !error &&
        consent.state === CONSENT_STATE.DRAFT &&
        consentName.length > 0 &&
        (!consent.participationRequired || (participants && participants.length > 0)) &&
        (sensitive || !consent.publishingUsage || (useRights && useRights.length > 0)) &&
        (
            consentName !== consent.name ||
            addedParticipants.length > 0 ||
            deletedParticipants.length > 0 ||
            addedUseRights.length > 0 ||
            deletedUseRights.length > 0
        );

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
            disableBackdropClick={saving}
        >
            <DialogTitle>
                {isLicense ?
                    (readOnly ? <FormattedMessage id="action.view_license" /> : <FormattedMessage id="action.edit_license" />) :
                    (readOnly ? <FormattedMessage id="action.view_consent" /> : <FormattedMessage id="action.edit_consent" />)
                }
            </DialogTitle>
            {(loadingParticipants || loadingUseRights) && (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            )}
            {!(loadingParticipants || loadingUseRights) && (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <ConsentFormFields
                            sensitive={sensitive}
                            consentName={consentName}
                            setConsentName={sensitive && consent.participationRequired ? null : handleConsentNameChange}
                            participants={participants}
                            onParticipantAdd={handleAddParticipant}
                            onParticipantRemove={handleRemoveParticipant}
                            useRights={useRights}
                            onUseRightAdd={handleAddUseRight}
                            onUseRightRemove={handleRemoveUseRight}
                            consentType={consent.type}
                            readOnly={readOnly}
                            isLicense={isLicense}
                            forServiceUser={consent.forServiceUser ? CONSENT_CONCERNING.FABRIS : CONSENT_CONCERNING.OTHER}
                            forRecordingUsage={consent.recordingUsage}
                            forPublishingUsage={consent.publishingUsage}
                            folderId={folderId}
                            consent={consent}
                            onClose={handleClose}
                            onAnswerUpdated={handleAnswerUpdated}
                        />
                        {isFile && (consent.publishingUsage || isLicense) &&
                            <ConsentListConnectedFiles
                                loading={loadingFiles}
                                fileContainers={files}
                            />
                        }
                    </DialogContent>
                    {!readOnly &&
                        <DialogActions>
                            {error &&
                                <div className={classes.errorMsg}>
                                    <FormattedMessage id="error.update_failed" />
                                </div>
                            }
                            <Button onClick={handleClose} variant="outlined">
                                <FormattedMessage id="action.cancel" />
                            </Button>
                            <Button color="primary" type="submit" variant="contained" disabled={!canSubmit || saving}>
                                <FormattedMessage id="action.save" />
                                {saving && <CircularProgress size={20} />}
                            </Button>
                        </DialogActions>
                    }
                    {readOnly &&
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined">
                                <FormattedMessage id="action.close" />
                            </Button>
                        </DialogActions>
                    }
                </form>
            )}
        </Dialog>
    );
};

ConsentEditDialog.propTypes = {
    onClose: PropTypes.func,
    onPersist: PropTypes.func,
    sensitive: PropTypes.bool.isRequired,
    consent: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
};

ConsentEditDialog.defaultProps = {
    onClose: (() => {}),
    onPersist: (() => {}),
    readOnly: true,
};

export default ConsentEditDialog;
