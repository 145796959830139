import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SmsStatusPoll, { transportType } from './SmsStatusPoll';

export const MessageTransportStatus = ({messageTransport, pollUrl}) => {
    if (messageTransport === transportType.EMAIL) {
        return <FormattedMessage id="info.message_email" />;
    } else if (messageTransport === transportType.SMS) {
        if (pollUrl) {
            return <SmsStatusPoll pollUrl={pollUrl} />;
        }
        return <FormattedMessage id="info.message_sms_nostatus" />;
    } else if (messageTransport === false) {
        return <FormattedMessage id="info.message_none" />;
    } else {
        return <FormattedMessage id="info.message_failed" />;
    }
};

MessageTransportStatus.props = {
    messageTransport: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        null,
    ]).isRequired,
    pollUrl: PropTypes.oneOfType([
        PropTypes.string,
        null,
    ]),
};
