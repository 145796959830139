import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useJsonRequest } from '../utils/axios';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import RetryIcon from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { ROLE_TYPE } from './FolderAccess';

const useStyles = makeStyles(theme => ({
    folderName: {
        flexGrow: 1,
    },
    sensitive: {
        color: theme.palette.statped.textSensitive,
        fontSize: '0.8em',
        fontWeight: 'bold',
        marginLeft: '1em',
    },
    nonSensitive: {
        color: theme.palette.statped.textOpen,
        fontSize: '0.8em',
        fontWeight: 'bold',
        marginLeft: '1em',
    },
}));

const FolderField = ({ value, onChange, request, label }) => {
    const { data, error, loading, reload } = request;
    const classes = useStyles();

    if (loading) {
        return <Grid item xs={12}><CircularProgress /></Grid>;
    }

    if (error) {
        return (
            <Grid item xs={12}>
                <FormattedMessage id ="error.loading_failed" />
                <IconButton onClick={reload}>
                    <RetryIcon />
                </IconButton>
            </Grid>
        );
    }

    const handleChange = (e) => {
        if (typeof onChange === 'function') {
            onChange(e.target.value);
        }
    };

    const sortByName = (a, b) => (a.name < b.name) ? -1 :  (b.name < a.name ? 1 : 0);

    return (
        <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
                <InputLabel id="file-upload-target-label">
                    {label}
                </InputLabel>
                <Select
                    label={label}
                    labelId="file-upload-target-label"
                    onChange={handleChange}
                    value={value}
                    id="file-upload-target"
                    inputProps={{
                        'aria-labelledby': 'file-upload-target-label',
                    }}
                >
                    {data.sort(sortByName).map(folder => (
                        <MenuItem key={folder.id} value={folder.id}>
                            <span className={classes.folderName}>
                                {folder.name}
                            </span>
                            {typeof folder.personal !== 'undefined' &&
                                <span className={classes[folder.personal ? 'sensitive' : 'nonSensitive']}>
                                    {folder.personal
                                        ? <FormattedMessage id="label.sensitive" />
                                        : <FormattedMessage id="label.non_sensitive" />
                                    }
                                </span>
                            }
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

FolderField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    request: PropTypes.object.isRequired,
};

FolderField.defaultValues = {
    value: '',
    onChange: null,
};

export default FolderField;

export const FolderFieldEmployeeMine = forwardRef(({value, onChange}, ref) =>
    <FolderField
        ref={ref}
        value={value}
        onChange={onChange}
        request={useJsonRequest('/api/media/folders/my_folders')}
        label={<FormattedMessage id="label.target_folder" />}
    />
);

FolderFieldEmployeeMine.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

FolderFieldEmployeeMine.defaultValues = {
    value: '',
    onChange: null,
};

export const FolderFieldExternalUpload = forwardRef(({value, onChange}, ref) => {
    const { data, error, loading, reload } = useJsonRequest('/api/media/folders/roles/mine');
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        if (!loading && !error) {
            setFolders(
                data.filter(i => i.role === ROLE_TYPE.UPLOAD)
                    .map(u => {
                        const data = u.folder.data;
                        if (u?.createdBy?.data?.name) {
                            data.name = u.createdBy.data.name;
                        } else {
                            data.name = '<' + data.name + '>';
                        }

                        return data;
                    })
            );
        }
    }, [error, loading, data]);

    useEffect(() => {
        if (folders.length === 1) {
            onChange(folders[0].id);
        }
    }, [folders]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!loading && !error) {
        if (folders.length === 0) {
            return <FormattedMessage id="error.upload_not available" />;
        } else if (folders.length === 1) {
            return null;
        }
    }

    return (
        <FolderField
            ref={ref}
            value={value}
            onChange={onChange}
            request={
                {
                    data: folders,
                    error: error,
                    loading: loading,
                    reload: reload,
                }
            }
            label={<FormattedMessage id="label.receiver_upload" />}
        />
    );
});

FolderFieldExternalUpload.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

FolderFieldExternalUpload.defaultValues = {
    value: '',
    onChange: null,
};
