import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getUserLocale } from '../language';
import Grid from '@material-ui/core/Grid';
import VideoBookmarks from './VideoBookmarks';
import { axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';
import usePrevious from '../utils/usePrevious';
import Alert from '@material-ui/lab/Alert';
import { parseVTT, parseSRT, isSRT } from '../utils/caption';

const useStyles = makeStyles(theme => ({
    spinner: {
        position: 'absolute',
        top: 'calc(50% - 30px)',
        left: 'calc(50% - 30px)',
        color: '#FFFFFF',
    },
    error: {
        position: 'absolute',
        top: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#FFFFFF',
    },
    container: {
        position: 'relative',
    },
    spacingBookmarks: {
        marginTop: theme.spacing(2),
    },
    captionError: {
        marginTop: theme.spacing(1),
    },
    captionErrorAction: {
        '& button': {
            alignSelf: 'flex-start',
        },
    },
}));

const VideoPlayer = ({ stream, fileContainerId, folderId, file, enableBookmarks, editorOpen, captionFiles, autoPlay, ...props }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [playerReady, setPlayerReady] = useState(false);
    const nodeRef = useRef();
    const playerRef = useRef();
    const [isDisplayingCaption, setIsDisplayingCaption] = useState(false);
    const captionFilesCount = usePrevious(captionFiles.length);
    const cancelToken = useRef(CancelToken.source());
    const [captionError, setCaptionError] = useState([]);

    useEffect(() => () => cancelToken.current.cancel(), []);

    useEffect(() => {
        const locale = getUserLocale();

        playerRef.current = videojs(
            nodeRef.current,
            {
                autoplay: autoPlay,
                controls: true,
                preload: "metadata",
                fluid: true,
                language: locale,
                languages: {
                    [locale]: require(`video.js/dist/lang/${locale}.json`),
                },
                playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 2],
                html5: {
                    nativeTextTracks: false,
                    preloadTextTracks: false,
                    vhs: {
                        cacheEncryptionKeys: true,
                    },
                },
                userActions: {
                    doubleClick: false,
                },
                controlBar: {
                    chaptersButton: false,
                    pictureInPictureToggle: false,
                },
            }
        );

        playerRef.current.on(['canplay', 'error'], () => {setPlayerReady(true)});
        playerRef.current.on('texttrackchange', checkIsDisplayingCaption);

        return () => {
            if (playerRef.current && typeof playerRef.current.dispose === 'function') {
                playerRef.current.dispose();
            }
        };
    }, [nodeRef, autoPlay]);

    useEffect(() => {
        if (stream.loaded && stream.streamUrl) {
            const source = {
                src: stream.streamUrl,
                type: stream.contentType,
            };
            if (playerRef.current && playerRef.current.src() !== source.src) {
                setPlayerReady(false);
                playerRef.current.loadMedia({
                    src: source,
                    textTracks: [],
                    poster: "/statped-logo.png",
                });
            }
        }
    }, [stream]);

    // Check if adding or removing captions file
    useEffect(() => {
        if (playerRef.current && captionFiles) {
            if (typeof captionFilesCount === 'undefined' || captionFiles.length > captionFilesCount) {
                captionFiles.forEach(file => setCaption(file));
            } else if (captionFiles.length < captionFilesCount) {
                const removeTrack = playerRef.current.remoteTextTracks().tracks_.find(track => (
                    track.kind === 'captions' &&
                    !captionFiles.find(file => (file.id === track.id))
                ));
                if (removeTrack) {
                    playerRef.current.removeRemoteTextTrack(removeTrack);
                }
            }
        }
    }, [captionFiles]); // eslint-disable-line react-hooks/exhaustive-deps

    const checkIsDisplayingCaption = (event) => {
        const displaying = event.target.player.remoteTextTracks().tracks_
            .findIndex(track => (track.kind === 'captions' && track.mode === 'showing')) !== -1;

        setIsDisplayingCaption(displaying);
    }

    function logCaptionError(file, messageId) {
        setCaptionError(prevState => {
            return [
                ...prevState,
                <FormattedMessage
                    id={messageId}
                    values={{
                        file: file.name,
                    }}
                />
            ];
        });
    }

    async function setCaption(file) {
        let caption = playerRef.current.remoteTextTracks().getTrackById(file.id);

        if (caption === null) {
            try {
                const response = await axios.get(
                    `/api/media/folders/${folderId}/file_containers/${fileContainerId}/files/${file.id}/download`, {
                        cancelToken: cancelToken.current.token,
                    }
                );

                if (response && response.data && typeof response.data === 'string' && response.data !== '') {
                    playerRef.current.addRemoteTextTrack({
                        kind: 'captions',
                        label: file.name,
                        default: false,
                        id: file.id,
                        mode: 'disabled',
                    }, false);
                    const captionTrack = playerRef.current.remoteTextTracks().getTrackById(file.id);
                    captionTrack.mode = 'disabled';

                    const errors = isSRT(file) ?
                        parseSRT(response.data, captionTrack) :
                        parseVTT(response.data, captionTrack);
                    if (errors.length > 0) {
                        logCaptionError(file, 'error.vtt_parse');
                    }
                }
            } catch (e) {
                if (!isCancel(e)) {
                    logCaptionError(file, 'error.vtt_loading');
                }
            }
        }
    }

    return (
        <Grid container item xs={12} className={props.className}>
            <Grid item sm={12} md={editorOpen ? 6 : 12}>
                <div data-vjs-player="true" className={classes.container}>
                    <video-js ref={nodeRef} className="video-js" />
                    {playerReady === false && stream.error === false && (
                        <div className={classes.spinner}>
                            <CircularProgress size={60} color="inherit"/>
                        </div>
                    )}
                    {stream.error && (
                        <div className={classes.error}>
                            <FormattedMessage id="error.failed_loading_media" />
                        </div>
                    )}
                </div>
            </Grid>
            {!editorOpen && captionError.length > 0 &&
                <Grid container item sm={12} className={classes.captionError}>
                    <Alert
                        severity="warning"
                        variant="outlined"
                        onClose={() => {setCaptionError([])}}
                        classes={{
                            root: classes.captionErrorAction,
                        }}
                        closeText={formatMessage({ id: 'action.close' })}
                    >
                        <ul>
                            {captionError.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </Alert>
                </Grid>
            }
            <Grid item sm={12} className={editorOpen ? classes.spacingBookmarks : ''}>
                {playerReady && enableBookmarks &&
                    <VideoBookmarks
                        folderId={folderId}
                        fileContainerId={fileContainerId}
                        editMode={editorOpen}
                        playerRef={playerRef}
                        captionsDisplaying={isDisplayingCaption}
                    />
                }
            </Grid>
        </Grid>
    );
};

VideoPlayer.propTypes = {
    stream: PropTypes.object.isRequired,
    fileContainerId: PropTypes.string,
    folderId: PropTypes.string,
    file: PropTypes.object,
    enableBookmarks: PropTypes.bool,
    className: PropTypes.string,
    editorOpen: PropTypes.bool,
    captionFiles: PropTypes.array,
    autoPlay: PropTypes.bool,
};

VideoPlayer.defaultProps = {
    fileContainerId: null,
    folderId: null,
    file: null,
    enableBookmarks: false,
    className: '',
    captionFiles: [],
    editorOpen: false,
    autoPlay: false,
};

export default VideoPlayer;
