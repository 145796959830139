export const FILE_TYPE = {
    VIDEO: 'video',
    IMAGE: 'image',
    AUDIO: 'audio',
};

export const fileIsOfType = (file, type) => file && (
    file.type === type || (
        (file.type ?? 'unknown') === 'unknown' &&
        file.primary &&
        file.contentType &&
        file.contentType.startsWith(type + '/')
    )
);

export const getPrimaryFileInFileContainer = (fileContainer) =>
    fileContainer?.files.data.find(file => file.primary);

export const fileContainerIsOfType = (fileContainer, type) =>
    fileIsOfType(getPrimaryFileInFileContainer(fileContainer), type);

export const getFileContainerType = fileContainer => [FILE_TYPE.AUDIO, FILE_TYPE.IMAGE, FILE_TYPE.VIDEO]
    .find(type => fileContainerIsOfType(fileContainer, type)) || null;

export const getLabelIdForFileContainerType = fileContainer => (
    getLabelIdForFileType(getFileContainerType(fileContainer))
);

export const getLabelIdForContentType = (contentType) => {
    if (contentType) {
        const type = contentType.split('/')[0];
        const labelId = getLabelIdForFileType(type);

        if (labelId) {
            return labelId;
        }
    }

    return 'label.unknown';
};

const getLabelIdForFileType = type => {
    switch (type) {
        case FILE_TYPE.AUDIO:
            return 'label.audio';
        case FILE_TYPE.IMAGE:
            return 'label.image';
        case FILE_TYPE.VIDEO:
            return 'label.video';
        default:
            return 'label.unknown';
    }
};

export const hasVariant = (fileContainer, variant) =>
    fileContainer.variants.data.filter(v => v.variant === variant).length > 0;
