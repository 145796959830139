import React from 'react';
import Grid from '@material-ui/core/Grid';
import CollapsibleSection from '../components/CollapsibleSection';

const Section = props => (
    <Grid item xs={12}>
        <CollapsibleSection {...props} />
    </Grid>
);

Section.propTypes = CollapsibleSection.propTypes;

export default Section;
