import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import ArchiveIcon from '@material-ui/icons/Archive';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DisconnectIcon from '@material-ui/icons/LinkOff';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/FolderOpen';
import FolderTemplateIcon from '@material-ui/icons/DriveFolderUpload';
import FileIcon from '@material-ui/icons/DescriptionOutlined';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import PdfIcon from '@material-ui/icons/Assignment';
import TemplateIcon from '@material-ui/icons/DynamicFeed';
import SendIcon from '@material-ui/icons/Send';
import LicenseIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ConsentParticipantsView from './ConsentParticipantsView';
import DataTable, { COLUMN_TYPES, COLUMN_STYLES } from './DataTable';
import { CONSENT_TYPES } from './ConsentFormFields';
import { CONSENT_STATE } from './Consents';
import { NOTIFICATION_CATEGORY } from './DashboardNotifications';
import { useFolder } from '../routes/Folder';

const useStyles = makeStyles(theme => ({
    flexContainer: {
        display: 'flex',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(2),
        },
    },
    participantsTable: {
        tableLayout: 'fixed',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    participantsCell: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'visible',
    },
    participantContainer: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '90%',
        display: 'inline-block',
    },
}));

const ConsentList = ({
    data,
    fabrisCaseId,
    fileContainerListing,
    isLicense,
    folderId,
    loading,
    error,
    handleArchive,
    handleEdit,
    handleSendConsent,
    handleFileDisconnect,
    handleDelete,
}) => {
    const location = useLocation();
    const { formatMessage } = useIntl();
    const [dialog, setDialog] = useState(null);
    const classes = useStyles();
    const handleCloseDialog = () => setDialog(null);
    const timeoutHandle = useRef(null);
    const history = useHistory();
    const { isAdmin, writeAccess } = useFolder();
    let columns;
    const columnTypes = {
        type: COLUMN_TYPES.RAW,
        name: COLUMN_TYPES.TEXT,
        participants: COLUMN_TYPES.RAW,
        updatedAt: COLUMN_TYPES.DATETIME,
        state: COLUMN_TYPES.TEXT,
        pdf: COLUMN_TYPES.RAW,
        actions: COLUMN_TYPES.RAW,
    };
    const columnStyles = {
        type: COLUMN_STYLES.ICON,
        updatedAt: COLUMN_STYLES.FIT_CONTENT,
        state: COLUMN_STYLES.FIT_CONTENT,
        pdf: {width: 86},
        actions: fileContainerListing ? COLUMN_STYLES.ICON : COLUMN_STYLES.FIT_CONTENT,
    };

    const canArchive = (consent) => consent.state !== CONSENT_STATE.DRAFT && fabrisCaseId;

    const clearHighlight = useCallback(() => {
        if (timeoutHandle.current !== null) {
            clearTimeout(timeoutHandle.current);
            timeoutHandle.current = null;
            history.replace({
                state: {...location.state, highlight: undefined},
            });
        }
    }, [history, location.state]);

    useEffect(() => {
        const section = location?.state?.highlight?.section;
        if ((section === NOTIFICATION_CATEGORY.CONSENT && !isLicense) || (section === NOTIFICATION_CATEGORY.LICENSE && isLicense)) {
            timeoutHandle.current = setTimeout(clearHighlight, 10000);
            return () => {clearHighlight()};
        } else if (timeoutHandle.current !== null) {
            clearTimeout(timeoutHandle.current);
            timeoutHandle.current = null;
        }
    }, [location, isLicense, clearHighlight]);

    if (isLicense) {
        if (fileContainerListing) {
            columns = {
                name: <FormattedMessage id="label.name" />,
                updatedAt: <FormattedMessage id="label.updated" />,
                state: <FormattedMessage id="label.status" />,
                actions: <FormattedMessage id="label.actions" />,
            };
        } else {
            columns = {
                type: <FormattedMessage id="label.type" />,
                name: <FormattedMessage id="label.name" />,
                updatedAt: <FormattedMessage id="label.updated" />,
                state: <FormattedMessage id="label.status" />,
                actions: <FormattedMessage id="label.actions" />,
            };
        }
    } else {
        columnStyles.name = COLUMN_STYLES.FIT_CONTENT;
        if (fileContainerListing) {
            columns = {
                name: <FormattedMessage id="label.name" />,
                participants: <FormattedMessage id="label.receiver" />,
                updatedAt: <FormattedMessage id="label.updated" />,
                state: <FormattedMessage id="label.status" />,
                actions: <FormattedMessage id="label.actions" />,
            };
        } else {
            columns = {
                type: <FormattedMessage id="label.type" />,
                name: <FormattedMessage id="label.name" />,
                participants: <FormattedMessage id="label.receiver" />,
                updatedAt: <FormattedMessage id="label.updated" />,
                state: <FormattedMessage id="label.status" />,
                pdf: <FormattedMessage id="label.consent" />,
                actions: <FormattedMessage id="label.actions" />,
            };
        }
    }

    function getActionMenuItems(consent) {
        const isDraft = consent.state === CONSENT_STATE.DRAFT;
        const items = [
            <MenuItem
                key="edit"
                onClick={() => {
                    handleEdit(consent, isDraft ? (isAdmin ? false : !writeAccess) : true)
                    handleCloseDialog();
                }}
            >
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {isDraft && (isAdmin || writeAccess) ?
                        <FormattedMessage id="action.edit" />
                        :
                        <FormattedMessage id="action.open" />
                    }
                </Typography>
            </MenuItem>
            ,
            <MenuItem
                key="send"
                onClick={isDraft && (isAdmin || writeAccess) ?
                    () => {
                        handleSendConsent(consent);
                        handleCloseDialog();
                    } :
                    null
                }
                disabled={!isDraft || (isAdmin ? false : !writeAccess)}
            >
                <ListItemIcon>
                    {isLicense ?
                        <LicenseIcon fontSize="small" /> :
                        <SendIcon fontSize="small" />
                    }
                </ListItemIcon>
                <Typography variant="inherit">
                    {isLicense ?
                        <FormattedMessage id="action.activate" /> :
                        <FormattedMessage id="action.send" />
                    }
                </Typography>
            </MenuItem>
            ,
            canArchive(consent) && <MenuItem
                key="archive"
                onClick={() => {
                    handleArchive(folderId, consent.id);
                    handleCloseDialog();
                }}
            >
                <ListItemIcon>
                    <ArchiveIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                    <FormattedMessage id="action.archive" />
                </Typography>
            </MenuItem>
        ];

        if (fileContainerListing) {
            const canRemove = !consent.archived &&
                [
                    CONSENT_STATE.DRAFT,
                    CONSENT_STATE.SENT,
                    CONSENT_STATE.APPROVED,
                ].indexOf(consent.state) !== -1;

            items.push(
                <MenuItem
                    key="disconnect"
                    onClick={canRemove && (isAdmin || writeAccess) ?
                        () => {
                            handleFileDisconnect(consent.id, consent.name);
                            handleCloseDialog();
                        } :
                        null
                    }
                    disabled={!canRemove || (isAdmin ? false : !writeAccess)}
                >
                    <ListItemIcon>
                        <DisconnectIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {isLicense ?
                            <FormattedMessage id="action.disconnect_license_and_file" /> :
                            <FormattedMessage id="action.disconnect_consent_and_file" />
                        }
                    </Typography>
                </MenuItem>
            );
        } else {
            items.push(
                <MenuItem
                    key="delete"
                    onClick={() => {
                        handleDelete(consent);
                        handleCloseDialog();
                    }}
                    disabled={!isDraft}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        <FormattedMessage id="action.delete" />
                    </Typography>
                </MenuItem>
            );
        }

        return items;
    }

    const getActionMenu = (consent) => {
        return (
            <IconButton
                aria-label={formatMessage({ id: 'label.actions' })}
                aria-haspopup="true"
                onClick={e => {
                    setDialog(
                        <Menu
                            id="action_menu"
                            anchorEl={e.currentTarget}
                            open={true}
                            onClose={handleCloseDialog}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {getActionMenuItems(consent)}
                        </Menu>
                    );
                }}
            >
                <MenuIcon />
            </IconButton>
        );
    };

    function getActionButtons(consent) {
        const isDraft = consent.state === CONSENT_STATE.DRAFT;
        const isTemplate = consent.type === CONSENT_TYPES.TEMPLATE;

        return (
            <span className={classes.flexContainer}>
                <Button
                    key="edit"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={() => handleEdit(consent, isDraft ? (isAdmin ? false : !writeAccess) : true)}
                >
                    {isDraft && (isAdmin || writeAccess) ?
                        <FormattedMessage id="action.edit" />
                        :
                        <FormattedMessage id="action.open" />
                    }
                </Button>
                {fabrisCaseId &&
                    <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={canArchive(consent) ? () => handleArchive(folderId, consent.id) : null}
                        disabled={!canArchive(consent)}
                    >
                        <FormattedMessage id="action.archive" />
                    </Button>
                }
                <Button
                    key="send"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={isDraft && (isAdmin || writeAccess) ? () => handleSendConsent(consent) : null}
                    disabled={!isDraft || (isAdmin ? false : !writeAccess)}
                >
                    {isLicense || isTemplate ?
                        <FormattedMessage id="action.activate" /> :
                        <FormattedMessage id="action.send" />
                    }
                </Button>
                <IconButton
                    key="delete"
                    aria-label={formatMessage({ id: 'action.delete' })}
                    onClick={isDraft && (isAdmin || writeAccess) ? () => handleDelete(consent) : null}
                    size="small"
                    disabled={!isDraft || (isAdmin ? false : !writeAccess)}
                >
                    <DeleteIcon />
                </IconButton>
            </span>
        );
    }

    function getTypeIcon(consent) {
        const langId = 'label.' + consent.type + '_' + consent.category;
        const templateBased = consent.templateId !== null;
        let icon;

        switch (consent.type) {
            case CONSENT_TYPES.FILE:
                icon = <FileIcon arial-label={formatMessage({ id: langId })} />;
                break;
            case CONSENT_TYPES.FOLDER:
                icon = templateBased ?
                    <FolderTemplateIcon arial-label={formatMessage({ id: langId })} /> :
                    <FolderIcon aria-label={formatMessage({ id: langId})} />;
                break;
            case CONSENT_TYPES.TEMPLATE:
                icon = <TemplateIcon arial-label={formatMessage({ id: langId })} />;
                break;
            default:
                icon = null;
                break;
        }

        return (
            <Tooltip title={<FormattedMessage id={langId} />}>
                {icon}
            </Tooltip>
        );
    }

    function getStateText(consent) {
        let text;

        if (isLicense) {
            text = formatMessage({ id: `label.license.${consent.state}` });
        } else if (consent.type === CONSENT_TYPES.TEMPLATE) {
            text = formatMessage({ id: `label.template.${consent.state}` });
        } else {
            text = formatMessage({ id: `label.consent.${consent.state}` });
        }

        if (fabrisCaseId && consent.archived) {
            text += '/' + formatMessage({id: 'label.archived'});
        }

        return text;
    }

    function getPdfButton(consent) {
        if (consent.participants && consent.participants.data.length > 0) {
            return (
                <ConsentParticipantsView
                    folderId={folderId}
                    consent={consent}
                    disabled={isAdmin ? false : !writeAccess}
                >
                    <IconButton
                        size="small"
                        aria-label={formatMessage( { id: 'label.participants'} )}
                        disabled={isAdmin ? false : !writeAccess}
                    >
                        <PdfIcon />
                    </IconButton>
                </ConsentParticipantsView>
            );
        }
        if (consent.type === CONSENT_TYPES.TEMPLATE) {
            if (isAdmin || writeAccess) {
                return (
                    <Link
                        href={consent.templatePdf.data.url}
                        target="_blank"
                        variant="body2"
                        aria-label={formatMessage({ id: 'label.view_pdf' })}
                    >
                        <PdfIcon />
                    </Link>
                );
            } else {
                return (
                    <IconButton
                        size="small"
                        aria-label={formatMessage({ id: 'label.view_pdf' })}
                        disabled={true}
                    >
                        <PdfIcon />
                    </IconButton>
                );
            }
        }
    }

    const sortByName = (a, b) => (
        a.person.data.name < b.person.data.name) ? -1 :  (b.person.data.name < a.person.data.name ? 1 : 0
    );

    const mapData = (consent) => {
        const row = {
            id: consent.id,
        };

        Object.entries(columns).forEach(([key]) => {
            switch (key) {
                case 'type':
                    row[key] = getTypeIcon(consent);
                    break;

                case 'participants':
                    row[key] = (
                        consent.participants && consent.participants.data.length > 0 ?
                            <ConsentParticipantsView
                                folderId={folderId}
                                consent={consent}
                                disabled={isAdmin ? false : !writeAccess}
                            >
                                {
                                    consent.participants.data
                                        .sort(sortByName)
                                        .map(participant => participant.person.data.name)
                                        .join(', ')
                                }
                            </ConsentParticipantsView>
                            : null
                    );
                    break;

                case 'state':
                    row[key] = getStateText(consent);
                    break;

                case 'pdf':
                    row[key] = getPdfButton(consent);
                    break;

                case 'actions':
                    row[key] = fileContainerListing ? getActionMenu(consent) : getActionButtons(consent);
                    break;

                default:
                    row[key] = consent[key];
            }
        });

        return row;
    };

    return (
        <Fragment>
            {dialog}
            <DataTable
                loading={loading}
                columns={columns}
                columnTypes={columnTypes}
                columnStyles={columnStyles}
                rows={(data || []).map(mapData)}
                error={error}
                highlight={location?.state?.highlight?.id}
                sortable={true}
                defaultSortColumn="updatedAt"
                defaultSortOrder="desc"
            />
        </Fragment>
    );
};

ConsentList.propTypes = {
    data: PropTypes.array,
    fileContainerListing: PropTypes.bool,
    isLicense: PropTypes.bool,
    folderId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    handleEdit: PropTypes.func,
    handleSendConsent: PropTypes.func,
    handleFileDisconnect: PropTypes.func,
    handleDelete: PropTypes.func,
};

ConsentList.defaultProps = {
    data: [],
    fileContainerListing: false,
    isLicense: false,
    loading: false,
    error: false,
    handleEdit: () => {},
    handleSendConsent: () => {},
    handleFileDisconnect: () => {},
    handleDelete: () => {},
};

export default ConsentList;
