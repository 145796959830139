import React, { Fragment, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useJsonRequest } from '../utils/axios';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@material-ui/icons/PostAdd';
import Button from '@material-ui/core/Button';
import ConnectIcon from '@material-ui/icons/Link';
import UploadConsentIcon from '@material-ui/icons/Publish';
import ConsentConnectDialog from './ConsentConnectDialog';
import ConsentCreateDialog from './ConsentCreateDialog';
import ConsentDeleteDialog from './ConsentDeleteDialog';
import ConsentDisconnectDialog from './ConsentDisconnectDialog';
import ConsentEditDialog from './ConsentEditDialog';
import ConsentList from './ConsentList';
import ConsentSendDialog from './ConsentSendDialog';
import ConsentCreateFromTemplate from './ConsentCreateFromTemplate';
import ConsentArchiveDialog from './ConsentArchiveDialog';

const CreateButton = ({ onClick, isLicense, disabled }) =>
    <Button
        onClick={onClick}
        startIcon={<AddIcon />}
        variant="outlined"
        disabled={disabled}
    >
        {isLicense ?
            <FormattedMessage id="action.new_license" />
            :
            <FormattedMessage id="action.new_consent" />
        }
    </Button>
;

const UploadConsentButton = ({ onClick, disabled }) => (
    <Button
        onClick={onClick}
        startIcon={<UploadConsentIcon />}
        variant="outlined"
        disabled={disabled}
    >
        <FormattedMessage id="action.upload_consent" />
    </Button>
);

const ConnectFileButton = ({ onClick, isLicense, disabled }) => (
    <Button
        onClick={onClick}
        startIcon={<ConnectIcon />}
        variant="outlined"
        disabled={disabled}
    >
        {isLicense ?
            <FormattedMessage id="action.connect_existing_license_to_this_file" />
            :
            <FormattedMessage id="action.connect_existing_consent_to_this_file" />
        }
    </Button>
);

export const CONSENT_STATE = {
    DRAFT: 'draft',
    SENT: 'sent',
    APPROVED: 'approved',
    WITHDRAWN: 'withdrawn',
    DECLINED: 'declined',
};

const Consents = forwardRef(({ fabrisCaseId, folderId, fileContainerId, sensitive, isLicense, serviceUserName }, ref) => {
    const [dialog, setDialog] = useState(null);
    const handleCloseDialog = () => setDialog(null);
    const fileContainerListing = (typeof fileContainerId === 'string');
    const url = (
        isLicense ?
            fileContainerListing ?
                `/api/media/folders/${folderId}/file_containers/${fileContainerId}/licenses` :
                `/api/media/folders/${folderId}/licenses`
            :
            fileContainerListing ?
                `/api/media/folders/${folderId}/file_containers/${fileContainerId}/consents` :
                `/api/media/folders/${folderId}/consents`
    );
    const { data, setData, loading, reload, error } = useJsonRequest(url);

    useImperativeHandle(ref, () => ({
        create: handleCreateOpen,
        archiveConsent: handleArchive,
        uploadConsent: handleConsentUpload,
        connectFile: handleFileConnect,
    }));

    const handleArchive = (folderId, consentId) => setDialog(
        <ConsentArchiveDialog
            folderId={folderId}
            consentId={consentId}
            onClose={handleCloseDialog}
            onSuccess={() => setData(data.map(item => {
                if (item.id === consentId) {
                    item.archived = true;
                }
                return item;
            }))}
        />
    );

    const handleCreateOpen = () => setDialog(
        <ConsentCreateDialog
            folderId={folderId}
            onClose={handleCloseDialog}
            onPersist={newConsent => setData([...data, newConsent])}
            sensitive={sensitive}
            isLicense={isLicense}
            serviceUserName={serviceUserName}
        />
    );

    const handleConsentUpload = () => setDialog(
        <ConsentCreateFromTemplate
            folderId={folderId}
            onClose={handleCloseDialog}
            onPersist={newConsent => {
                setData([...data, newConsent]);
                handleEdit(newConsent, true);
            }}
            sensitive={sensitive}
        />
    );

    const handleEdit = (consent, readOnly) => {
        setDialog(
            <ConsentEditDialog
                sensitive={sensitive}
                folderId={folderId}
                onClose={handleCloseDialog}
                consent={consent}
                readOnly={readOnly}
                onPersist={updated => {
                    if (typeof updated !== 'undefined') {
                        setData(data.map(consent => consent.id === updated.data.id ? {...consent, ...updated.data} : consent));
                    } else {
                        reload();
                    }
                }}
                serviceUserName = {serviceUserName}
            />
        );
    };

    const handleDelete = (consent) => setDialog(
        <ConsentDeleteDialog
            folderId={folderId}
            consent={consent}
            onClose={handleCloseDialog}
            onDelete={() => setData(data.filter(item => item.id !== consent.id))}
            sensitive={sensitive}
        />
    );

    const handleSendConsent = (consent) => setDialog(
        <ConsentSendDialog
            consent={consent}
            folderId={folderId}
            sensitive={sensitive}
            onClose={handleCloseDialog}
            onAfterSend={updated => {
                setData(data.map(consent => consent.id === updated.id ? {...consent, ...updated} : consent));
            }}
        />
    );

    const handleFileConnect = () => {
        if (data && !loading && !error) {
            setDialog(
                <ConsentConnectDialog
                    folderId={folderId}
                    fileContainerId={fileContainerId}
                    connectedConsents={data}
                    onClose={handleCloseDialog}
                    onSuccess={() => {reload()}}
                    isLicense={isLicense}
                />
            );
        }
    };

    const handleFileDisconnect = (consentId, consentName) => setDialog(
        <ConsentDisconnectDialog
            consentId={consentId}
            consentName={consentName}
            folderId={folderId}
            fileContainerId={fileContainerId}
            onClose={handleCloseDialog}
            onSuccess={(id) => setData(data.filter(consent => consent.id !== id))}
        />
    );

    return (
        <Fragment>
            {dialog}
            <ConsentList
                data={data}
                fabrisCaseId={fabrisCaseId}
                fileContainerListing={fileContainerListing}
                isLicense={isLicense}
                folderId={folderId}
                loading={loading}
                error={error}
                handleArchive={handleArchive}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFileDisconnect={handleFileDisconnect}
                handleSendConsent={handleSendConsent}
            />
        </Fragment>
    );
});

Consents.propTypes = {
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string,
    sensitive: PropTypes.bool.isRequired,
    isLicense: PropTypes.bool,
    serviceUserName: PropTypes.string,
};

Consents.defaultProps = {
    fileContainerId: null,
    isLicense: false,
    serviceUserName: null,
};

CreateButton.propTypes = {
    onClick: PropTypes.func,
    isLicense: PropTypes.bool,
};
CreateButton.defaultProps = {
    onClick: () => {},
    isLicense: false,
};

UploadConsentButton.propTypes = {
    onClick: PropTypes.func,
};
UploadConsentButton.defaultProps = {
    onClick: () => {},
};

ConnectFileButton.propTypes = {
    onClick: PropTypes.func,
    isLicense: PropTypes.bool,
};
ConnectFileButton.defaultProps = {
    onClick: () => {},
    isLicense: false,
};

export {Consents, CreateButton, UploadConsentButton, ConnectFileButton };
