import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useJsonRequest, axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';
import { makeStyles } from '@material-ui/core/styles';
import { FabrisCaseLookupForm } from './FolderConnectFabrisDialog';

const useStyles = makeStyles((theme) => ({
    gridSize: {
        // Fix unwanted scrollbar
        width: 'auto',
        margin: 0,
    },
    folderType: {
        paddingLeft: theme.spacing(1),
        fontSize: '0.8em',
    },
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

const FolderCreateDialog = ({ onClose, sensitive }) => {
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [fabrisCaseId, setFabrisCaseId] = useState(null);
    const { data: folderData, loaded: folderCreated, load: submit, error, statusCode } = useJsonRequest(null);
    const [saving, setSaving] = useState(false);
    const [haveError, setHaveError] = useState(false);
    const [fabrisCaseIdVerified, setFabrisCaseIdVerified] = useState(false);
    const [fabrisCheckData, setFabrisCheckData] = useState(null);
    const [fabrisCheckError, setFabrisCheckError] = useState(false);
    const [fabrisChecking, setFabrisChecking] = useState(false);

    useEffect(() => {
        if (folderCreated) {
            history.push(`/folders/${folderData.id}`);
        }
    }, [folderCreated, folderData, history]);

    useEffect(() => {
        if (error) {
            setSaving(false);
            getFolderOwner();
        }
        setHaveError(error);
    }, [error]);

    const handleClose = () => setOpen(false);
    const handleNameChange = event => setName(event.target.value);
    const handleDescriptionChange = event => setDescription(event.target.value);
    const handleFabrisCaseFound = data => {
        setFabrisCaseIdVerified(true);
        setFabrisCaseId(data.serviceCode);
        if (sensitive || name === '') {
            setName(data.title);
        }
    };
    const handleFabrisCaseChange = caseId => {
        setHaveError(false);
        setFabrisCaseIdVerified(false);
        setFabrisCaseId(caseId === '' ? null : caseId);
        if (sensitive) {
            setName('');
        }
    };
    const handleFabrisError = () => {
        setHaveError(false);
        setFabrisCaseIdVerified(false);
        if (sensitive) {
            setName('');
        }
    };

    const handleSubmit = event => {
        event.preventDefault();

        setSaving(true);
        setFabrisCheckError(false);
        setFabrisCheckData(null);
        submit({
            url: '/api/media/folders',
            method: 'post',
            data: { name, personal: sensitive, description, fabrisCaseId },
        });
    };

    const getFolderOwner = () => {
        if (statusCode === 409) {
            const cancelToken = CancelToken.source();
            setFabrisChecking(true);
            (async () => {
                try {
                    const response = await axios.get(`/api/media/folders/fabris/${fabrisCaseId}`, {
                        cancelToken: cancelToken.token,
                    });
                    setFabrisCheckData(response.data.data);
                } catch (e) {
                    if (!isCancel) {
                        setFabrisCheckError(true);
                    }
                } finally {
                    setFabrisChecking(false);
                }
            })();
        }
    };

    function showErrorMessage() {
        if (statusCode === 409) {
            return (
                <div className={classes.errorMsg}>
                    <FormattedMessage id="error.fabis_already_connected" />
                    <div>
                        <FormattedMessage id="label.folder_owner" />{': '}
                        {fabrisCheckError ?
                            <FormattedMessage id="error.loading_failed" /> :
                            fabrisCheckData?.owner?.data?.name
                        }
                        {fabrisChecking ?
                            <CircularProgress size={15} /> :
                            null
                        }
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.errorMsg}>
                <FormattedMessage id="error.save_failed" />
            </div>
        );
    }

    const canCreate = ((sensitive && fabrisCaseId !== null) || !sensitive)
        && (fabrisCaseId === null || fabrisCaseIdVerified)
        && name !== '';

    return (
        <Dialog fullWidth onClose={handleClose} onExited={onClose} open={open}>
            <DialogTitle>
                <FormattedMessage id="action.new_folder" />
                <span className={classes.folderType}>
                    ({ sensitive ? <FormattedMessage id="label.sensitive" /> : <FormattedMessage id="label.non_sensitive" />})
                </span>
            </DialogTitle>

            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container spacing={3} alignItems="flex-end" className={classes.gridSize}>
                        <FabrisCaseLookupForm
                            onError={handleFabrisError}
                            onSuccess={handleFabrisCaseFound}
                            onChange={handleFabrisCaseChange}
                            required={sensitive}
                            sensitive={sensitive}
                            showError={true}
                            showSuccess={true}
                            caseId={fabrisCaseId}
                            disabled={saving}
                            label={sensitive ?
                                <FormattedMessage id="label.fabris_case_number" /> :
                                <FormattedMessage id="label.fabris_casenumber_open" />
                            }
                            shouldAutoFocus={sensitive}
                        />

                        <Grid item xs={12}>
                            <TextField
                                autoFocus={!sensitive}
                                disabled={sensitive || saving}
                                fullWidth
                                helperText={<FormattedMessage id="help.folder_name" />}
                                label={sensitive ?
                                    <FormattedMessage id="label.name" /> :
                                    <FormattedMessage id="label.folder_name" />
                                }
                                onChange={handleNameChange}
                                required={!sensitive}
                                value={name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<FormattedMessage id="label.description" />}
                                multiline
                                onChange={handleDescriptionChange}
                                rows={3}
                                rowsMax={3}
                                value={description}
                                disabled={saving}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {haveError && showErrorMessage()}
                    <Button onClick={onClose}>
                        <FormattedMessage id="action.cancel" />
                    </Button>

                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={!canCreate || saving}
                    >
                        <FormattedMessage id="action.create" />
                        {saving && <CircularProgress size={20} />}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

FolderCreateDialog.propTypes = {
    sensitive: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};

FolderCreateDialog.defaultProps = {
    onClose: (() => {}),
}

export default FolderCreateDialog;
