import { AuthStrategy } from './strategy';
import { AUTH_STRATEGY } from './AuthProvider';

class IdportenStrategy extends AuthStrategy {
    getName() {
        return AUTH_STRATEGY.IDPORTEN;
    }

    loginWithRedirect() {
        window.location = '/api/identity/idporten/login'+(this.locale !== undefined ? "?locale="+encodeURIComponent(this.locale) : "");
    }

    logout() {
        window.location = '/api/identity/logout';
    }

    didAuthenticate() {
        return window.location.search.includes('returnFrom=idporten');
    }

    async postAuthentication() {
        // noop
    }

    setLocale(locale) {
        this.locale = locale;
    }
}

export default IdportenStrategy;
