class AuthStrategy {
    /**
     * @abstract
     */
    getName() {
    }

    /**
     * @abstract
     */
    loginWithRedirect() {
    }

    /**
     * @abstract
     */
    logout() {
    }

    /**
     * @abstract
     */
    didAuthenticate() {
    }

    /**
     * @abstract
     */
    async postAuthentication() {
    }

    /**
     * @abstract
     */
    setLocale(locale) {
    }
}

export { AuthStrategy };
