import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { axiosWithAuth as axios } from '../utils/axios';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

const ConsentDisconnectDialog = ({ consentId, consentName, folderId, fileContainerId, onClose, onSuccess }) => {
    const [open, setOpen] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const classes = useStyles();

    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        try {
            setSaving(true);
            await axios.delete(`/api/media/folders/${folderId}/consents/${consentId}/file_containers/${fileContainerId}`);
            setSaving(false);
            onSuccess(consentId);
            setOpen(false);
        } catch (e) {
            setSaving(false);
            if (e.response.status === 400) {
                setError(<FormattedMessage id="error.file_disconnect_not_allowed" />);
            } else {
                setError(<FormattedMessage id="error.generic" />);
            }
        }
    };

    return (
        <Dialog closeAfterTransition open={open} onClose={handleClose} onExited={onClose}>
            <DialogTitle>
                <FormattedMessage id="action.disconnect_consent_and_file" />
            </DialogTitle>
            <DialogContent>
                <Typography>
                    <FormattedMessage
                        id="prompt.confirm_consent_disconnect"
                        values={{ name: <strong>{consentName}</strong> }}
                    />
                </Typography>
            </DialogContent>

            <DialogActions>
                {error &&
                    <div className={classes.errorMsg}>
                        {error}
                    </div>
                }
                <Button
                    onClick={handleClose}
                    variant="outlined"
                >
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    disabled={saving}
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    <FormattedMessage id="action.remove" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConsentDisconnectDialog.propTypes = {
    consentId: PropTypes.string.isRequired,
    consentName: PropTypes.string.isRequired,
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

ConsentDisconnectDialog.defaultProps = {
    onClose: (() => {}),
    onSuccess: (() => {}),
};

export default ConsentDisconnectDialog;
