import axios, { CancelToken, isCancel } from 'axios';
import { useCallback, useEffect, useReducer } from 'react';
import { getOrFetchJwt } from '../auth/jwt';

const axiosWithAuth = axios.create();

axiosWithAuth.interceptors.request.use(async requestConfig => {
    if (!requestConfig.headers.Authorization && !requestConfig._noRetry) {
        requestConfig.headers.Authorization = 'Bearer ' + await getOrFetchJwt();
    }

    return requestConfig;
});

axiosWithAuth.interceptors.response.use(res => res, async err => {
    if (err.config && err.config._noRetry) {
        // avoid infinite loop
        throw err;
    }

    if (!err.response || err.response.status !== 401) {
        throw err;
    }

    err.config._noRetry = true;
    err.config.headers.Authorization = 'Bearer ' + await getOrFetchJwt();

    return axiosWithAuth(err.config);
});

const useJsonRequest = (url, {
    axios = axiosWithAuth,
    method = 'get',
    requestOptions = {},
} = {}) => {
    const [state, dispatch] = useReducer((state, action) => ({
        ...state,
        ...action,
        requestOptions: action.requestOptions
            ? { ...state.requestOptions, ...action.requestOptions }
            : state.requestOptions
    }), {
        data: null,
        pagination: null,
        error: false,
        loading: Boolean(url),
        requestOptions: { url, method, ...requestOptions },
        statusCode: null,
        statusText: '',
    });

    useEffect(() => {
        if (state.loading) {
            const cancelToken = CancelToken.source();
            (async () => {
                try {
                    dispatch({ error: false, loading: true });

                    const response = await axios.request({
                        cancelToken: cancelToken.token,
                        ...state.requestOptions,
                    });

                    dispatch({
                        data: response.data.data || response.data,
                        pagination: response.data?.meta?.pagination,
                        loading: false,
                        statusCode: response.status || -1,
                        statusText: response.statusText || '',
                    });
                } catch (e) {
                    if (!isCancel(e)) {
                        dispatch({
                            error: true,
                            loading: false,
                            statusCode: e.response?.status || -1,
                            statusText: e.response?.statusText || '',
                        });
                    }
                }
            })();

            return () => cancelToken.cancel();
        }
    }, [axios, state.loading, state.requestOptions]);

    const setData = useCallback(data => dispatch({ data }), []);

    const load = useCallback(requestOptions => dispatch({
        error: false,
        loading: true,
        requestOptions,
        statusCode: null,
        statusText: '',
    }), []);

    const reload = useCallback(() => dispatch({
        error: false,
        loading: true,
        statusCode: null,
        statusText: '',
    }), []);

    return {
        data: state.data,
        setData,
        pagination: state.pagination,
        error: state.error,
        loading: state.loading,
        loaded: state.requestOptions.url && !state.error && !state.loading,
        load,
        reload,
        statusCode: state.statusCode,
        statusText: state.statusText,
    };
};

export {
    CancelToken,
    axiosWithAuth,
    isCancel,
    useJsonRequest,
};

export default axios;
