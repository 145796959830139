import React from 'react';
import PropTypes from 'prop-types';
import { useJsonRequest } from '../utils/axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getUserLocale } from '../language';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useId } from '../utils/id';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';

const urlByType = type => ({
    discipline: '/api/media/disciplines.json',
    tag: '/api/media/tags.json',
})[type] || (() => { throw new Error('Invalid type') })();

const MetadataSelector = ({ value, fullWidth, multiple, onChange, type, variant }) => {
    const { data, loaded } = useJsonRequest(urlByType(type));
    const labelId = useId();
    const locale = getUserLocale();

    const handleChange = event => onChange(event?.target?.value || event);

    const label = (
        (type === 'discipline' && multiple && <FormattedMessage id="label.subject_areas" />) ||
        (type === 'discipline' && <FormattedMessage id="label.subject_area" />) ||
        (type === 'tag' && multiple && <FormattedMessage id="label.tags" />) ||
        (type === 'tag' && <FormattedMessage id="label.tag" />)
    );

    return (
        <FormControl fullWidth={fullWidth} variant={variant}>
            <InputLabel id={labelId}>
                {label}
            </InputLabel>

            <Select
                value={value ?? (multiple ? [] : '')}
                disabled={!loaded}
                label={label}
                labelId={labelId}
                multiple={multiple}
                onChange={handleChange}
                variant={variant}
                id={labelId + '_select'}
                inputProps={{
                    'aria-labelledby': labelId,
                }}
                renderValue={multiple ? (selected) => selected.join(', ') : null}
            >
                {loaded && Object.entries(data).map(([key, { label }]) => (
                    <MenuItem key={key} value={key}>
                        {multiple &&
                            <Checkbox checked={multiple ? value.indexOf(key) > -1 : value === key} />
                        }
                        {label[locale] ?? label.nb}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

MetadataSelector.defaultProps = {
    fullWidth: false,
    multiple: false,
    onChange: (() => {}),
    variant: 'outlined',
};

MetadataSelector.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    fullWidth: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['discipline', 'tag']).isRequired,
    variant: PropTypes.string,
};

export default MetadataSelector;
