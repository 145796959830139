import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { useId } from '../utils/id';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
    details: {
        display: 'block',
        overflowX: 'auto',
    },
}));

const AccordionSummary = withStyles({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const CollapsibleSection = ({ actions, children, title, defaultExpanded, titleComponent, titleVariant }) => {
    const [loadedContent, setLoadedContent] = useState();
    const [loadedActions, setLoadedActions] = useState();
    const [expanded, setExpanded] = useState(defaultExpanded);
    const id = useId();
    const classes = useStyles();

    const toggleExpanded = () => {
        setExpanded(!expanded);

        if (!loadedContent && typeof children === 'function') {
            setLoadedContent(children({ setActions: setLoadedActions }));
        }
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={toggleExpanded}
            variant="outlined"
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary aria-controls={id} expandIcon={<ExpandMoreIcon />} id={`${id}-header`}>
                <Typography component={titleComponent} variant={titleVariant}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {typeof children === 'function' && loadedContent}
                {typeof children !== 'function' && children}
            </AccordionDetails>
            {(actions || loadedActions) && (
                <AccordionActions>
                    {loadedActions || actions}
                </AccordionActions>
            )}
        </Accordion>
    );
};

CollapsibleSection.propTypes = {
    actions: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    title: PropTypes.node.isRequired,
    defaultExpanded: PropTypes.bool,
    titleComponent: PropTypes.string,
    titleVariant: PropTypes.string,
}

CollapsibleSection.defaultProps = {
    actions: null,
    children: null,
    defaultExpanded: false,
    titleComponent: "h2",
    titleVariant: "h5",
};

export default CollapsibleSection;
