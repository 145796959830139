import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import { useId } from '../utils/id';
import Checkbox from '@material-ui/core/Checkbox';

const purposeFlatteningReducer = (acc, cur) => [
    ...acc,
    ...(cur.children
        ? cur.children.reduce(purposeFlatteningReducer, [])
        : [cur]
    ),
];

const UseRightPurposeSelector = ({ fullWidth, multiple, onChange, size, value, showHidden, purposes, purposesLoaded }) => {
    const purposeLabelId = useId();
    const filterHidden = (item) => showHidden ? true : (typeof item.hidden === 'boolean' ? !item.hidden : true);
    const flatPurposes = useMemo(() => purposes ? purposes.reduce(purposeFlatteningReducer, []) : [], [purposes]);

    const renderSelectPurposeItem = purpose => (
        purpose.children ? ([
            <ListSubheader key={purpose.label}>{purpose.title}</ListSubheader>,
            ...purpose.children.filter(filterHidden).map(child => renderSelectPurposeItem(child)),
        ]) : (
            <MenuItem key={purpose.label} value={purpose.label}>
                {multiple &&
                    <Checkbox checked={value.indexOf(purpose.label) > -1} />
                }
                {purpose.title}
            </MenuItem>
        )
    );

    const handleChange = event => {
        const value = event.target.value;
        const args = (multiple ? value : (value ? [value] : [])).map(label => {
            return flatPurposes.find(purpose => purpose.label === label);
        });

        onChange(multiple ? args : args[0]);
    };

    return (
        <FormControl fullWidth={fullWidth} size={size} variant="outlined">
            <InputLabel id={purposeLabelId}>
                <FormattedMessage id="label.right_of_use" />
            </InputLabel>

            <Select
                defaultValue=""
                disabled={!purposesLoaded}
                label={<FormattedMessage id="label.right_of_use" />}
                labelId={purposeLabelId}
                multiple={multiple}
                onChange={handleChange}
                value={value}
                id="right_of_use_selector"
                inputProps={{
                    'aria-labelledby': purposeLabelId,
                }}
                renderValue={multiple ?
                    selected => selected
                        .map(item => flatPurposes.find(p => p.label === item).title)
                        .join(', ')
                    : null
                }
            >
                {purposesLoaded && purposes.map(purpose => renderSelectPurposeItem(purpose))}
            </Select>
        </FormControl>
    );
};

UseRightPurposeSelector.propTypes = {
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium']),
    value: PropTypes.any,
    showHidden: PropTypes.bool,
    purposes: PropTypes.array,
    purposesLoaded: PropTypes.bool,
};

UseRightPurposeSelector.defaultProps = {
    fullWidth: false,
    onChange: (() => {}),
    size: 'medium',
    value: null,
    showHidden: false,
    purposes: [],
    purposesLoaded: false,
};

export default UseRightPurposeSelector;

