import srt2vtt from '../utils/srt2vtt';

export function isCaptionFile(file) {
    return (['text/vtt', 'text/srt'].indexOf(file.contentType) !== -1);
}

export function isSRT(file) {
    return (file.contentType === 'text/srt');
}

/**
 * Convert an SRT string into VTT that is parsed into cues
 *
 * @param {string} srtString
 * @param {TextTrack} textTrack
 */
export function parseSRT(srtString, textTrack) {
    try {
        return parseVTT(srt2vtt(srtString), textTrack);
    } catch (e) {
        const error = [`Conversion error: ${e.message}`];
        logErrors(error, textTrack);
        return error;
    }
}

/**
 * Parse a VTT string into cues
 *
 * @param {string} vttString
 * @param {TextTrack} textTrack
 */
export function parseVTT(vttString, textTrack) {
    const errors = [];

    if (window.WebVTT && window.vttjs && typeof window.WebVTT === 'function') {
        const parser = new window.WebVTT.Parser(window, window.vttjs, window.WebVTT.StringDecoder());

        parser.oncue = function (cue) {
            textTrack.addCue(cue);
        };

        parser.onparsingerror = function (error) {
            errors.push(error);
        };

        parser.onflush = function () {
            textTrack.trigger({
                type: 'loadeddata',
                target: textTrack
            });
        };

        parser.parse(vttString);
        parser.flush();
    } else {
        errors.push('WebVTT not supported, unable to parse VTT data');
    }

    if (errors.length > 0) {
        logErrors(errors, textTrack);
    }

    return errors;
}

function logErrors(errors, track) {
    if (window.console && window.console.groupCollapsed) {
        window.console.groupCollapsed('VTT parsing errors for ' + track.label);
    }

    errors.forEach(function (error) {
        return console.error(error);
    });

    if (window.console && window.console.groupEnd) {
        window.console.groupEnd();
    }
}
