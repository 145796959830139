import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../auth/AuthProvider';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Crumbs from './Crumbs';

const useStyles = makeStyles(theme => ({
    main: {
        marginTop: theme.spacing(3),
        position: 'relative',
    },
}));

const BasePage = ({ breadcrumbs, children, hideTitle, title, unwrapChildren }) => {
    const { forciblyLoggedOut, logout } = useAuth();
    const classes = useStyles();

    useEffect(() => {
        document.title = title;
    });

    return (
        <>
            <CssBaseline />
            {forciblyLoggedOut && (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open
                    message={<FormattedMessage id="error.forcibly_logged_out" />}
                    action={
                        <Button
                            color="inherit"
                            onClick={logout}
                            size="small"
                            variant="outlined"
                        >
                            <FormattedMessage id="action.log_in_again" />
                        </Button>
                    }
                />
            )}
            <NavBar breadcrumbs={breadcrumbs} />
            <Container className={classes.main}>
                <Grid container spacing={2}>
                    {breadcrumbs && (
                        <Grid item xs={12}>
                            <Crumbs
                                crumbs={breadcrumbs}
                                current={title}
                            />
                        </Grid>
                    )}
                    {title && !hideTitle && (
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h3">
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    {unwrapChildren ? children : <Grid item xs={12}>{children}</Grid>}
                </Grid>
            </Container>
        </>
    );
};

BasePage.propTypes = {
    breadcrumbs: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            titleId: PropTypes.string,
            title: PropTypes.string,
            to: PropTypes.string,
        }),
        PropTypes.arrayOf(PropTypes.shape({
            titleId: PropTypes.string,
            title: PropTypes.string,
            to: PropTypes.string,
        })),
    ]),
    children: PropTypes.node,
    hideTitle: PropTypes.bool,
    title: PropTypes.string,
    unwrapChildren: PropTypes.bool,
};

BasePage.defaultProps = {
    breadcrumbs: false,
    children: null,
    hideTitle: false,
    title: '',
    unwrapChildren: false,
};

export default BasePage;
