import createAuth0Client from '@auth0/auth0-spa-js';
import { AuthStrategy } from './strategy';
import axios from '../utils/axios';
import querystring from 'querystring';
import { AUTH_STRATEGY } from './AuthProvider';

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

class Auth0Strategy extends AuthStrategy {
    constructor({
                    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                    redirectUri,
                }) {
        super();

        this.onRedirectCallback = onRedirectCallback || DEFAULT_REDIRECT_CALLBACK;
        this.redirectUri = redirectUri;
    }

    getName() {
        return AUTH_STRATEGY.AUTH0;
    }

    loginWithRedirect() {
        this.getClient().then(client => client.loginWithRedirect());
    }

    logout() {
        this.getClient().then(client => client.logout());
    }

    didAuthenticate() {
        return (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
        );
    }

    async postAuthentication() {
        const client = await this.getClient();
        const { appState } = await client.handleRedirectCallback();

        /*
         * Establish a login at the identityservice using the auth0 access token.
         */
        const token = await client.getTokenSilently();
        await axios.post('/api/identity/auth0/login', querystring.stringify({'accessToken': token}));

        this.onRedirectCallback(appState);
    }

    async getClient() {
        if (!this.client) {
            const response = await axios.get('/api/identity/auth0/config');
            const { clientId, domain } = response.data;

            this.client = await createAuth0Client({
                domain: domain,
                client_id: clientId,
                redirect_uri: this.redirectUri,
            });
        }

        return this.client;
    };
}

export default Auth0Strategy;
