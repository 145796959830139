import React, { useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConsentFormFields, { CONSENT_CONCERNING, CONSENT_CATEGORY, CONSENT_TYPES } from './ConsentFormFields';
import { makeStyles } from '@material-ui/core/styles';
import { CONSENT_STATE } from './Consents';
import { ConsentListConnectedFiles } from './ConsentFileList';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

const ConsentSendDialog = ({ folderId, consent, sensitive, onClose, onAfterSend }) => {
    const [open, setOpen] = useState(true);
    const [saving, setSaving] = useState(false);
    const isLicense = consent.category === CONSENT_CATEGORY.LICENSE;
    const isTemplate = consent.type === CONSENT_TYPES.TEMPLATE;
    const isFile = consent.type === CONSENT_TYPES.FILE;
    const {
        data: useRights,
        loading: loadingUseRights,
    } = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/use_rights`);
    const {
        data: participants,
        loading: loadingParticipants,
        setData: setParticipantsData,
    } = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/participants`);
    const {
        data: files,
        loading: loadingFiles,
    } = useJsonRequest(isFile ? `/api/media/folders/${folderId}/consents/${consent.id}/file_containers` : null);
    const [error, setError] = useState(false);
    const classes = useStyles();
    const [isSent, setIsSent] = useState(consent.state !== CONSENT_STATE.DRAFT);

    const handleClose = () => setOpen(false);

    const handleSend = async () => {
        if (canSend) {
            setSaving(true);

            try {
                const response = await axios.post(`/api/media/folders/${folderId}/consents/${consent.id}/send`);
                onAfterSend(response.data);
                setIsSent(response.data.state !== CONSENT_STATE.DRAFT);
                setParticipantsData(participants.map(person => {
                    const state = response.data.messageStates.find(r => person.personId === r.personId);
                    return state ? {...person, ...{ messageTransport: state.transport, pollUrl: state.pollUrl }} : person;
                }));
                setSaving(false);
            } catch {
                setSaving(false);
                setError(true);
            }
        }
    };

    const canSend = !error && !saving && !isSent &&
        consent.name.length > 0 &&
        (isLicense || isTemplate || (participants && participants.length > 0)) &&
        (sensitive || !consent.publishingUsage || (useRights && useRights.length > 0)) &&
        (!isLicense || !isFile || (consent.recordingUsage && !consent.publishingUsage) || (files && files.length > 0));

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
            disableBackdropClick={saving}
        >
            <DialogTitle>
                {isLicense ?
                    <FormattedMessage id="label.activate_license" /> :
                    isTemplate ?
                        <FormattedMessage id="label.activate_template" /> :
                        <FormattedMessage id="label.send_consent" />
                }
            </DialogTitle>
            {(loadingParticipants || loadingUseRights || loadingFiles) && (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            )}
            {!(loadingParticipants || loadingUseRights || loadingFiles) && (
                <Fragment>
                    <DialogContent>
                        <ConsentFormFields
                            sensitive={sensitive}
                            consentName={consent.name}
                            participants={participants}
                            useRights={useRights}
                            consentType={consent.type}
                            readOnly={true}
                            isLicense={consent.category === CONSENT_CATEGORY.LICENSE}
                            forServiceUser={consent.forServiceUser ? CONSENT_CONCERNING.FABRIS : CONSENT_CONCERNING.OTHER}
                            forRecordingUsage={consent.recordingUsage}
                            forPublishingUsage={consent.publishingUsage}
                        />
                        {isFile && (consent.publishingUsage || isLicense) &&
                            <ConsentListConnectedFiles
                                fileContainers={files}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        {error &&
                            <div className={classes.errorMsg}>
                                <FormattedMessage id="error.send_consent_failed" />
                            </div>
                        }
                        {isSent &&
                            (isLicense ?
                                <div className={classes.errorMsg}>
                                    <FormattedMessage id="info.license_activated" />
                                </div> :
                                    isTemplate ?
                                        <div className={classes.errorMsg}>
                                            <FormattedMessage id="info.template_activated" />
                                        </div> :
                                        <div className={classes.errorMsg}>
                                            <FormattedMessage id="info.consent_sent" />
                                        </div>
                            )
                        }
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            disabled={saving}
                        >
                            {isSent ?
                                <FormattedMessage id="action.close" /> :
                                <FormattedMessage id="action.cancel" />
                            }
                        </Button>
                        <Button
                            color="primary"
                            onClick={canSend && !isSent ? handleSend : null}
                            variant="contained"
                            disabled={!canSend || isSent}
                        >
                            {consent.participationRequired ?
                                <FormattedMessage id="action.send" /> :
                                <FormattedMessage id="action.activate" />
                            }
                            {saving && <CircularProgress size={20} />}
                        </Button>
                    </DialogActions>
                </Fragment>
            )}
        </Dialog>
    );
};

ConsentSendDialog.propTypes = {
    folderId: PropTypes.string.isRequired,
    consent: PropTypes.object.isRequired,
    sensitive: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onAfterSend: PropTypes.func,
};

ConsentSendDialog.defaultProps = {
    onClose: (() => {}),
    onAfterSend: (() => {}),
};

export default ConsentSendDialog;
