import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ParticipantsList, ParticipantsForm, ParticipantsAddButton } from './ConsentParticipants';
import UseRightForm, { UseRightAddButton } from './UseRightForm';
import UseRightList from './UseRightList';
import { ConsentsByTemplateConsent, TemplateConsentByConsent } from './ConsentsTemplateListings';
import ConsentTypeHelpDialog from './ConsentTypeHelpDialog';
import { useAuth } from '../auth/AuthProvider';

const useStyles = makeStyles(theme => ({
    borderContainer: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
    },
    spacing: {
        'marginTop': theme.spacing(2),
    },
    helpHover: {
        cursor: 'pointer',
    },
}));

export const CONSENT_TYPES = {
    FOLDER: 'folder',
    FILE: 'file',
    TEMPLATE: 'template',
};

export const CONSENT_CONCERNING = {
    FABRIS: 'fabris',
    OTHER: 'other',
};

export const CONSENT_CATEGORY = {
    CONSENT: 'consent',
    LICENSE: 'license',
};

const ConsentFormFields = ({
    consentName,
    setConsentName,
    participants,
    onParticipantAdd,
    onParticipantRemove,
    useRights,
    onUseRightAdd,
    onUseRightRemove,
    sensitive,
    readOnly,
    consentType,
    setConsentType,
    isLicense,
    forServiceUser,
    forRecordingUsage,
    setForRecordingUsage,
    forPublishingUsage,
    setForPublishingUsage,
    folderId,
    consent,
    onClose,
    onAnswerUpdated,
}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [dialog, setDialog] = useState(null);
    const handleClose = () => setDialog(null);
    const isConsentType = ([CONSENT_TYPES.FILE, CONSENT_TYPES.FOLDER].indexOf(consentType) !== -1);
    const isTemplateType = (consentType === CONSENT_TYPES.TEMPLATE);
    const { user } = useAuth();

    const handleShowConsentTypeHelp = event => setDialog(
        <ConsentTypeHelpDialog onClose={handleClose} />
    );

    const onAddParticipantClick = () => {
        setDialog(
            <ParticipantsForm
                participants={participants}
                onAdd={onParticipantAdd}
                onClose={handleClose}
            />
        );
    };

    const onAddUseRightClick = () => {
        setDialog(
            <UseRightForm
                onAdd={onUseRightAdd}
                onClose={handleClose}
                isLicense={isLicense}
            />
        );
    };

    return (
        <Grid container spacing={2}>
            {dialog}
            <Grid item xs={12}>
                <FormLabel component="span">
                    <FormattedMessage id="label.created_by" />{': '}
                </FormLabel>
                {consent ? consent?.createdBy?.data?.name : user.name}
            </Grid>
            {!isLicense && isConsentType && consent && consent.templateId &&
                <Grid item xs={12}>
                    <TemplateConsentByConsent
                        folderId={folderId}
                        consent={consent}
                    />
                </Grid>
            }

            <Grid item xs={12}>
                <FormControl
                    component="fieldset"
                    fullWidth
                    disabled={readOnly || typeof setConsentType !== 'function'}
                    required
                >
                    <FormLabel component="legend">
                        {isLicense ? <FormattedMessage id="label.license_type" /> : <FormattedMessage id="label.consent_type" />}
                        {!isLicense && <HelpIcon
                            fontSize="inherit"
                            aria-label={formatMessage({ id: 'label.consent_type_help' })}
                            onClick={handleShowConsentTypeHelp}
                            className={classes.helpHover}
                        />}
                    </FormLabel>

                    <RadioGroup
                        row
                        onChange={setConsentType}
                        value={consentType || ''}
                    >
                        <FormControlLabel
                            control={<Radio />}
                            label={isLicense ? <FormattedMessage id="label.folder_license" /> : <FormattedMessage id="label.folder_consent" />}
                            value={CONSENT_TYPES.FOLDER}
                        />
                        <FormControlLabel
                            control={<Radio />}
                            label={isLicense ? <FormattedMessage id="label.file_license" /> : <FormattedMessage id="label.file_consent" />}
                            value={CONSENT_TYPES.FILE}
                        />
                        {!isLicense && !sensitive &&
                            <FormControlLabel
                                control={<Radio />}
                                label={<FormattedMessage id="label.template_consent" />}
                                value={CONSENT_TYPES.TEMPLATE}
                            />
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>
            {!sensitive && !isLicense &&
                <Grid item xs={12}>
                    <FormControl
                        component="fieldset"
                        fullWidth
                        disabled={readOnly || typeof setForRecordingUsage !== 'function' || typeof setForPublishingUsage !== 'function'}
                        required
                    >
                        <FormLabel component="legend">
                            <FormattedMessage id="label.consent_usage" />
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={forRecordingUsage}
                                        name="recordingUsage"
                                        onChange={setForRecordingUsage}
                                    />
                                }
                                label={<FormattedMessage id="label.recording" />}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={forPublishingUsage}
                                        name="publishingUsage"
                                        onChange={setForPublishingUsage}
                                    />
                                }
                                label={<FormattedMessage id="label.publishing" />}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            }

            {!isLicense && isConsentType &&
                <Grid item xs={12} className={classes.borderContainer}>
                    <Typography variant="h6">
                        <FormattedMessage id="label.consent_concerning" />
                    </Typography>

                    {(!sensitive || forServiceUser !== null) &&
                        <TextField
                            fullWidth
                            onChange={readOnly ? null : setConsentName}
                            required={true}
                            label={<FormattedMessage id="label.name" />}
                            aria-label={formatMessage({ id: 'label.name' })}
                            variant="outlined"
                            value={consentName}
                            disabled={readOnly || forServiceUser === CONSENT_CONCERNING.FABRIS || typeof setConsentName !== 'function'}
                            className={classes.spacing}
                            size="small"
                            id="consent-conserning"
                            inputProps={{
                                'aria-labelledby': "consent-conserning-label",
                            }}
                        />
                    }
                </Grid>
            }

            {isLicense &&
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        onChange={readOnly ? null : setConsentName}
                        required
                        label={<FormattedMessage id="label.license_title" />}
                        variant="outlined"
                        value={consentName}
                        disabled={readOnly}
                        id="license-title"
                    />
                </Grid>
            }

            {isTemplateType &&
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        onChange={readOnly ? null : setConsentName}
                        required
                        label={<FormattedMessage id="label.title" />}
                        variant="outlined"
                        value={consentName}
                        disabled={readOnly}
                        id="template-title"
                    />
                </Grid>
            }

            {!isLicense && isConsentType &&
                <Grid item xs={12} className={classes.borderContainer}>
                    <Typography variant="h6">
                        <FormattedMessage id="label.participants" />
                    </Typography>

                    <ParticipantsList
                        participants={participants}
                        onRemove={onParticipantRemove}
                        readOnly={readOnly}
                        folderId={folderId}
                        consent={consent}
                        onAnswerUpdated={onAnswerUpdated}
                    />

                    {!readOnly &&
                        <ParticipantsAddButton onClick={onAddParticipantClick} />
                    }
                </Grid>
            }

            {!sensitive && (forPublishingUsage || isLicense) &&
                <Grid item xs={12} className={classes.borderContainer}>
                    <Typography variant="h6">
                        <FormattedMessage id="label.rights_of_use" />
                    </Typography>

                    <UseRightList
                        useRights={useRights}
                        onRemove={onUseRightRemove}
                        readOnly={readOnly || typeof onUseRightRemove !== 'function'}
                    />
                    {!readOnly && typeof onUseRightAdd === 'function' &&
                        <UseRightAddButton onClick={onAddUseRightClick} />
                    }
                </Grid>
            }

            {!isLicense && isTemplateType &&
                <ConsentsByTemplateConsent
                    folderId={folderId}
                    templateConsent={consent}
                />
            }
        </Grid>
    );
};

ConsentFormFields.propTypes = {
    consentName: PropTypes.string,
    setConsentName: PropTypes.func,
    participants: PropTypes.array,
    onParticipantAdd: PropTypes.func,
    onParticipantRemove: PropTypes.func,
    useRights: PropTypes.array,
    onUseRightAdd: PropTypes.func,
    onUseRightRemove: PropTypes.func,
    sensitive: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    consentType: PropTypes.string,
    setConsentType: PropTypes.func,
    isLicense: PropTypes.bool,
    forServiceUser: PropTypes.oneOf([CONSENT_CONCERNING.FABRIS, CONSENT_CONCERNING.OTHER]),
    forRecordingUsage: PropTypes.bool,
    setForRecordingUsage: PropTypes.func,
    forPublishingUsage: PropTypes.bool,
    setForPublishingUsage: PropTypes.func,
    folderId: PropTypes.string,
    consent: PropTypes.object,
    onClose: PropTypes.func,
    onAnswerUpdated: PropTypes.func,
};

ConsentFormFields.defaultProps = {
    consentName: '',
    setConsentName: null,
    participants: [],
    onParticipantAdd: () => {},
    onParticipantRemove: () => {},
    useRights: [],
    onUseRightAdd: null,
    onUseRightRemove: null,
    readOnly: true,
    consentType: null,
    setConsentType: null,
    isLicense: false,
    forServiceUser: null,
    forRecordingUsage: false,
    setForRecordingUsage: null,
    forPublishingUsage: false,
    setForPublishingUsage: null,
    folderId: null,
    consent: null,
    onClose: () => {},
    onAnswerUpdated: () => {},
};

export default ConsentFormFields;
