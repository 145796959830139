import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#81407d',
        },
        secondary: {
            main: '#a71e38',
        },
        text: {
            disabled: "rgb(0 0 0 / 55%)",
        },
        error: {
            main: "rgb(178, 20, 10)",
        },
        statped: {
            backgroundSensitive: '#f7bebc',
            textSensitive: red[500],
            backgroundOpen: '#bfe3c2',
            textOpen: green[800],
        },
        action: {
            hover: 'rgba(0, 0, 0, 0.06)',
            selected: 'rgba(0, 0, 0, 0.12)',
        },
    },
    overrides: {
        MuiDialog: {
            paper: {
                border: '1px solid white',
            },
        },
        MuiMenu: {
            paper: {
                border: '1px solid white',
            },
        },
        MuiInput: {
            underline: {
                '@media(forced-colors: active)': {
                    '&::before': {
                        left: -10, //Fixes first character cutoff in high-contrast mode
                    },
                },
            },
        },
        MuiLink: {
            root: {
                '@media(forced-colors: active)': {
                    color: 'LinkText',
                },
            },
        },
        MuiButtonBase: {
            root: {
                '@media(forced-colors: active)': {
                    color: 'ButtonText',
                    backgroundColor: 'ButtonFace',
                },
            },
        },
        MuiFab: {
            root: {
                '@media(forced-colors: active)': {
                    border: '1px solid ButtonText',
                    '&:hover': {
                        color: 'LinkText',
                    },
                },
            },
        },
        MuiTableRow: {
            root: {
                '@media(forced-colors: active)': {
                    '&.Mui-selected': {
                        color: 'HighlightText',
                        backgroundColor: 'Highlight',
                    },
                },
            },
        },
        MuiListItem: {
            root: {
                '@media(forced-colors: active)': {
                    '&:hover': {
                        color: 'LinkText',
                    },
                    '&.Mui-selected': {
                        color: 'HighlightText',
                        backgroundColor: 'Highlight',
                        '&:hover': {
                            color: 'HighlightText',
                            backgroundColor: 'Highlight',
                        },
                    },
                },

            },
        },
        MuiButton: {
            root: {
                '@media(forced-colors: active)': {
                    '&:hover': {
                        color: 'LinkText',
                    },
                },
            }
        },
        MuiIconButton: {
            root: {
                '@media(forced-colors: active)': {
                    '&:hover': {
                        color: 'LinkText',
                    },
                },
            }
        },
    },
});
