import React, { useEffect, useReducer, useState } from 'react';
import { stringify as qs } from 'qs';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import BasePage from '../components/BasePage';
import DataTable, { COLUMN_STYLES, COLUMN_TYPES } from '../components/DataTable';
import SearchBar from '../components/SearchBar';
import UseRightPurposeSelector from '../components/UseRightPurposeSelector';
import { useJsonRequest } from '../utils/axios';
import { useDebouncedState } from '../utils/debounce';
import { getLabelIdForFileContainerType, getPrimaryFileInFileContainer } from '../utils/file';
import MetadataSelector from '../components/MetadataSelector';

const paramsSerializer = p => qs(Object.fromEntries(
    Object.entries(p)
        // strip false and empty strings
        .filter(([, v]) => v !== '' && v !== false)
        // turn true into 1
        .map(([k, v]) => [k, v === true ? 1 : v])
));

const Search = () => {
    const { formatMessage } = useIntl();
    const [disciplines, setDisciplines] = useState([]);
    const [query, setQuery,, flushQuery] = useDebouncedState('');
    const [purposes, setPurposes] = useState([]);
    const [formats, setFormats] = useReducer((state, action) => ({ ...state, ...action }), {
        audioUseAllowed: false,
        imageUseAllowed: false,
        videoUseAllowed: false,
    });
    const [tags, setTags] = useState([]);
    const { data: results, error, load, loading } = useJsonRequest(null, {
        requestOptions: { paramsSerializer },
    });
    const { data: availablePurposes, loaded: purposesLoaded } = useJsonRequest('/api/media/use_right_purposes.json');

    useEffect(() => {
        load({ url: '/api/media/search', params: {
            query,
            purposes,
            disciplines,
            tags,
            ...formats,
        } });
    }, [disciplines, formats, load, purposes, query, tags]);

    const handleSubmit = event => {
        event.preventDefault();

        flushQuery();
    };

    const handleDisciplineChange = value => setDisciplines(value);

    const handleQueryChange = event => setQuery(event.target.value);

    const handleFormatChange = event => setFormats({
        ...formats,
        [event.target.name]: event.target.checked,
    });

    const handlePurposeChange = purposes => setPurposes(purposes.map(purpose => purpose.label));

    const handleTagsChange = tags => setTags(tags);

    const getFileType = row => {
        const id = getLabelIdForFileContainerType(row);
        return formatMessage({ id });
    };

    const getOriginator = row => {
        const orig = getPrimaryFileInFileContainer(row);
        return orig ? orig?.createdBy?.data?.name : null;
    };

    return (
        <BasePage title={formatMessage({ id: 'action.search' })}>
            <Grid
                alignItems="center"
                component="form"
                container
                onSubmit={handleSubmit}
                spacing={2}
            >
                <Grid item xs={12}>
                    <FormattedMessage id="help.search" />
                </Grid>
                <Grid item xs={12}>
                    <SearchBar onChange={handleQueryChange} />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <UseRightPurposeSelector
                        fullWidth
                        multiple
                        onChange={handlePurposeChange}
                        value={purposes}
                        purposes={availablePurposes}
                        purposesLoaded={purposesLoaded}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <MetadataSelector
                        value={disciplines}
                        fullWidth
                        multiple
                        onChange={handleDisciplineChange}
                        type="discipline"
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <MetadataSelector
                        value={tags}
                        fullWidth
                        multiple
                        onChange={handleTagsChange}
                        type="tag"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormGroup control="fieldset" onChange={handleFormatChange} row>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<FormattedMessage id="label.audio" />}
                            name="audioUseAllowed"
                            value={formats.audioUseAllowed}
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<FormattedMessage id="label.image" />}
                            name="imageUseAllowed"
                            value={formats.imageUseAllowed}
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<FormattedMessage id="label.video" />}
                            name="videoUseAllowed"
                            value={formats.videoUseAllowed}
                        />
                    </FormGroup>
                </Grid>

                <DataTable
                    maxHeight="100%"
                    columns={{
                        link: <FormattedMessage id="label.name" />,
                        type: <FormattedMessage id="label.file_type" />,
                        updatedAt: <FormattedMessage id="label.edited_date" />,
                        originator: <FormattedMessage id="label.originator" />,
                        folder: <FormattedMessage id="label.folder_name" />,
                    }}
                    columnTypes={{
                        updatedAt: COLUMN_TYPES.DATETIME,
                    }}
                    columnStyles={{
                        type: COLUMN_STYLES.FIT_CONTENT,
                    }}
                    error={error}
                    loading={loading}
                    rows={(results || []).map(row => ({
                        name: row.name,
                        link: (
                            <Link component={RouterLink} to={`/folders/${row.folderId}/media/${row.id}`}>
                                {row.name}
                            </Link>
                        ),
                        type: getFileType(row),
                        originator: getOriginator(row),
                        updatedAt: row.updatedAt,
                        folder: (
                            <Link component={RouterLink} to={`/folders/${row.folderId}`}>
                                {row.folder.data.name}
                            </Link>
                        ),
                    }))}
                    sortable={['link', 'type', 'updatedAt']}
                    defaultSortColumn="link"
                    defaultSortOrder="asc"
                    sortColumnMap={{ 'link': 'name' }}
                />
            </Grid>
        </BasePage>
    );
};

export default Search;
