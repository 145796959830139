import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AttachedFiles from '../components/AttachedFiles';
import BasePage from '../components/BasePage';
import DeleteMediaFileDialog from '../components/DeleteMediaFileDialog';
import EditableText from '../components/EditableText';
import FileAccess, { FileAccessButton } from '../components/FileAccess';
import BaseImageViewer from '../components/ImageViewer';
import BaseVideoPlayer from '../components/VideoPlayer';
import MoveFileDialog from '../components/MoveFileDialog';
import { fileContainerIsOfType, FILE_TYPE, hasVariant } from '../utils/file';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import { isCaptionFile } from '../utils/caption';
import Section from '../components/Section';
import Tags from '../components/Tags';
import {Consents, ConnectFileButton } from '../components/Consents';
import { useStream } from '../utils/stream';
import { FolderContext, folderAccess } from './Folder';
import { useAuth } from '../auth/AuthProvider';
import downloader from "js-file-download";
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
    infoBox: {
        padding: theme.spacing(2),
    },
    videoBox: {
        marginTop: theme.spacing(2),
    },
    downloadError: {
        color: red[700],
    },
}));

const getStreamUrl = (folderId, fileContainerId, fileId) =>
    `/api/media/folders/${folderId}/file_containers/${fileContainerId}/files/${fileId}/streaming_requests`;

const VideoPlayer = ({ folderId, fileContainerId, fileId, ...props }) => (
    <BaseVideoPlayer
        folderId={folderId}
        fileContainerId={fileContainerId}
        stream={useStream(getStreamUrl(folderId, fileContainerId, fileId))}
        {...props}
    />
);

const ImageViewer = ({ folderId, fileContainerId, fileId }) => (
    <BaseImageViewer
        stream={useStream(getStreamUrl(folderId, fileContainerId, fileId))}
    />
);

const DownloadMediaButton = ({ contentType, downloadUrl, filename, ...props }) => {
    const { data, load, loading, loaded } = useJsonRequest(null, {
        method: 'get',
        requestOptions: { responseType: 'blob' },
    });

    useEffect(() => {
        if (loaded) {
            downloader(data, filename, contentType);
        }
    }, [loaded, filename, contentType]);

    return (
        <Button
            {...props}
            disabled={loading}
            onClick={() => load({ url: downloadUrl })}
        >
            <FormattedMessage id="action.download" />
        </Button>
    )
};

const DownloadVariantButton = ({ folderId, fileContainerId, variant, buttonVariant, ...props }) => {
    const { data, load, loaded, loading } = useJsonRequest();

    const handleLoad = () => load({
        url: `/api/media/folders/${folderId}/file_containers/${fileContainerId}/variants/${variant}`,
    });

    useEffect(() => {
        if (loaded) {
            window.location = data.url;
        }
    }, [data, loaded]);

    return (
        <Button
            onClick={handleLoad}
            disabled={loading}
            variant={props.buttonVariant}
            {...props}
        />
    );
};

const TranscodingProcessInfo = ({ status }) => {
    const message = {
        'initial': <FormattedMessage id="transcoding_status.initial" />,
        'pre_transcoding': <FormattedMessage id="transcoding_status.pre_transcoding" />,
        'transcoding': <FormattedMessage id="transcoding_status.transcoding" />,
        'transcoding_done': <FormattedMessage id="transcoding_status.transcoding_done" />,
        'transcoding_failed': <FormattedMessage id="transcoding_status.transcoding_failed" />,
    }[status] || <FormattedMessage id="label.unknown" />;

    return (
        <div>
            <FormattedMessage id="label.transcoding_status" />:
            {' '}
            <strong>{message}</strong>
        </div>
    );
};

const MediaFile = ({ asAdmin }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { folderId, id } = useParams();
    const {
        data: folderData,
        loaded: folderLoaded,
        load: folderLoad,
        error: folderError,
        statusCode: folderStatusCode,
    } = useJsonRequest(`/api/media/folders/${folderId}`);
    const {
        data,
        loaded,
        setData,
        load,
        error,
        statusCode,
    } = useJsonRequest(`/api/media/folders/${folderId}/file_containers/${id}`);
    const [dialog, setDialog] = useState(null);
    const [editorOpen, setEditorOpen] = useState(false);
    const history = useHistory();
    const [breadCrumb, setBreadcrumb] = useState([]);
    const consentRef = useRef();
    const licenseRef = useRef();
    const fileAccessRef = useRef();
    const [isUploaded, setIsUploaded] = useState(true);
    const {
        ownerAccess: folderOwnerAccess,
        writeAccess: folderWriteAccess,
        readAccess: folderReadAccess,
    } = folderAccess(folderData?.role?.data?.role);
    const { user } = useAuth();
    const isAdmin = asAdmin && user.admin;

    useEffect(() => {
        folderLoad({url: `/api/media/folders/${folderId}` });
        load({ url: `/api/media/folders/${folderId}/file_containers/${id}` });
    }, [folderId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (folderLoaded && folderData !== null) {
            setIsUploaded(folderData.default);

            if (folderData.default === false) {
                if (isAdmin) {
                    setBreadcrumb([
                        folderData.personal ? (
                            {
                                titleId: 'places.all_users',
                                to: "/admin/folders/sensitive",
                            }
                        ) : (
                            {
                                titleId: 'places.all_open_folders',
                                to: '/admin/folders',
                            }
                        ),
                        {
                            title: folderData.name,
                            to: `/admin/folders/${folderId}`,
                        }
                    ]);
                } else {
                    setBreadcrumb([
                        folderData.personal ? (
                            {
                                titleId: 'places.my_users',
                                to: "/folders/sensitive",
                            }
                        ) : (
                            {
                                titleId: 'places.my_open_folders',
                                to: '/folders',
                            }
                        ),
                        {
                            title: folderData.name,
                            to: `/folders/${folderId}`,
                        }
                    ]);
                }
            }
        }
    }, [folderData, folderId, folderLoaded]);

    const handleDeleteSubmit = folderId => history.push((isAdmin ? '/admin' : '') + `/folders/${folderId}`);
    const handleDialogClose = () => setDialog(null);

    const handleDeleteOpen = () => setDialog(
        <DeleteMediaFileDialog
            fileContainerId={id}
            folderId={folderId}
            name={data.name}
            onClose={handleDialogClose}
            onSubmit={handleDeleteSubmit}
            fileOnly={isUploaded}
        />
    );

    const handleAuxDeleteSubmit = (fileId) => {
        setData({
            ...data,
            files: {
                ...data.files,
                data: data.files.data.filter(file => file.id !== fileId),
            },
        });
    };

    const handleAuxAddSubmit = (containers, file) => {
        const files = data.files.data.slice();
        files.push(file);
        setData({
            ...data,
            files: {
                ...data.files,
                data: files,
            },
        });
    };

    const handleMoveDialogOpen = () => setDialog(
        <MoveFileDialog
            fileContainerId={id}
            folderId={folderId}
            onClose={handleDialogClose}
            onSubmit={handleMoveSubmit}
        />
    );

    const handleMoveSubmit = (folderId, fileContainerId) => {
        handleDialogClose();
        history.push(`/folders/${folderId}/media/${fileContainerId}`);
    };

    const handleSave = async (field, value) => {
        const response = await axios.put(`/api/media/folders/${folderId}/file_containers/${id}`, { [field]: value });
        setData({ ...data, ...response.data.data });
    }

    function getCaptionFiles() {
        return data.files.data.filter(isCaptionFile);
    }

    const handleConnectFileConsent = () => {
        if (consentRef.current) {
            consentRef.current.connectFile();
        }
    };

    const handleConnectFileLicense = () => {
        if (licenseRef.current) {
            licenseRef.current.connectFile();
        }
    };

    const handleFileAccessExternal = () => {
        if (fileAccessRef.current) {
            fileAccessRef.current.externalReadAccess();
        }
    };

    const handleAddTag = async (value) => {
        const response = await axios.put(`/api/media/folders/${folderId}/file_containers/${id}/tags`, {tag: value});
        const values = data.tags.data.slice();
        values.push(response.data.data);
        setData({
            ...data,
            tags: {
                data: values,
            },
        });
    };

    const handleDeleteTag = async (tagId) => {
        await axios.delete(`/api/media/folders/${folderId}/file_containers/${id}/tags/${tagId}`);
        setData({
            ...data,
            tags: {
                data: data.tags.data.filter(tag => tag.id !== tagId)
            },
        });
    };

    const isAudio = fileContainerIsOfType(data, FILE_TYPE.AUDIO);
    const isImage = fileContainerIsOfType(data, FILE_TYPE.IMAGE);
    const isVideo = fileContainerIsOfType(data, FILE_TYPE.VIDEO);
    const primaryFile = data?.files?.data?.find(file => file.primary);

    if (folderError || error) {
        const code = statusCode !== 200 ? statusCode : folderStatusCode;
        let title = '';

        if (code) {
            switch (code) {
                case 403:
                    title = formatMessage({ id: 'error.access_denied' });
                    break;
                case 404:
                    title = formatMessage({ id: 'error.not_found' });
                    break;
                default:
                    title = formatMessage({ id: 'error.loading_failed' });
            }
        }

        return <BasePage title={title} />;
    }

    return (
        <BasePage
            breadcrumbs={breadCrumb}
            title={(loaded && data.name) || ''}
            unwrapChildren
            hideTitle
        >
            <FolderContext.Provider
                value={{
                    id: data?.id,
                    role: data?.role?.data?.role,
                    ownerAccess: folderOwnerAccess,
                    writeAccess: folderWriteAccess,
                    readAccess: folderReadAccess,
                    isAdmin: isAdmin,
                }}
            >
                {dialog}
                <Fragment>
                    {(!loaded || !folderLoaded) &&
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    }
                    {(loaded && folderLoaded && data !== null && folderData !== null) && (
                        <Grid container spacing={2}>
                            <Grid container direction="column" item spacing={2} xs={9}>
                                <Grid item>
                                    <Typography component="h1" variant="h4">
                                        <EditableText
                                            text={data.name}
                                            id={data.id}
                                            name={data.name}
                                            onSave={isAdmin || folderWriteAccess ? value => handleSave('name', value) : null}
                                            readOnly={editorOpen || (isAdmin ? false : !folderWriteAccess)}
                                            inputProps={{
                                                id: 'file_name',
                                                inputProps: {
                                                    'aria-label': formatMessage({ id: 'label.file_name' }),
                                                },
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!editorOpen && !isUploaded &&
                                <Grid container item xs={12} md={6} alignContent="flex-start" spacing={1}>
                                    <Grid item xs={12}>
                                        <Paper elevation={1} variant="outlined">
                                            <Grid container spacing={2} className={classes.infoBox}>
                                                <Grid item xs={12}>
                                                    <InputLabel id="folder-description-label">
                                                        <FormattedMessage id="label.description" />
                                                    </InputLabel>
                                                    <Typography component="div" variant="body1">
                                                        <EditableText
                                                            label={formatMessage({ id: 'label.description' })}
                                                            outlined
                                                            id="input_description"
                                                            multiline
                                                            onSave={isAdmin || folderWriteAccess ? value => handleSave('description', value) : null}
                                                            text={data.description}
                                                            readOnly={isAdmin ? false : !folderWriteAccess}
                                                            inputProps={{
                                                                id: 'input_description_input',
                                                                inputProps: {
                                                                    'aria-label': formatMessage({ id: 'label.description' }),
                                                                },
                                                            }}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                {!folderData.personal &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Tags
                                                                onChange={isAdmin || folderWriteAccess ? handleAddTag : null}
                                                                onDelete={isAdmin || folderWriteAccess ? handleDeleteTag : null}
                                                                selected={data.tags.data}
                                                                disabled={isAdmin ? false : !folderWriteAccess}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <InputLabel id="folder-usageinfo-label">
                                                                <FormattedMessage id="label.usage_info" />
                                                            </InputLabel>
                                                            <Typography component="div" variant="body1">
                                                                <EditableText
                                                                    label={formatMessage({ id: 'label.usage_info' })}
                                                                    outlined
                                                                    id="input_usageinfo"
                                                                    multiline
                                                                    onSave={isAdmin || folderWriteAccess ? value => handleSave('usageInfo', value) : null}
                                                                    text={data.usageInfo}
                                                                    readOnly={isAdmin ? false : !folderWriteAccess}
                                                                    inputProps={{
                                                                        id: 'input_usageinfo_input',
                                                                        inputProps: {
                                                                            'aria-label': formatMessage({ id: 'label.usage_info' }),
                                                                        },
                                                                    }}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </Paper>
                                    </Grid>

                                    <Section
                                        title={<FormattedMessage id="label.consents" />}
                                        actions={[
                                            <ConnectFileButton
                                                key="connectConsent"
                                                onClick={isAdmin || folderWriteAccess ? handleConnectFileConsent : null}
                                                disabled={isAdmin ? false : !folderWriteAccess}
                                            />,
                                        ]}
                                    >
                                        {() => (
                                            <Consents
                                                fabrisCaseId={folderData.serviceCode}
                                                fileContainerId={id}
                                                folderId={folderId}
                                                ref={consentRef}
                                                sensitive={folderData.personal}
                                            />
                                        )}
                                    </Section>

                                    {!folderData.personal &&
                                        <Section
                                            title={<FormattedMessage id="label.licenses" />}
                                            actions={[
                                                <ConnectFileButton
                                                    key="connectLicense"
                                                    onClick={isAdmin || folderWriteAccess ? handleConnectFileLicense : null}
                                                    isLicense={true}
                                                    disabled={isAdmin ? false : !folderWriteAccess}
                                                />,
                                            ]}
                                        >
                                            {() => (
                                                <Consents
                                                    fabrisCaseId={folderData.serviceCode}
                                                    fileContainerId={id}
                                                    folderId={folderId}
                                                    ref={licenseRef}
                                                    sensitive={false}
                                                    isLicense={true}
                                                />
                                            )}
                                        </Section>
                                    }

                                    <Section
                                        title={<FormattedMessage id="label.file_permissions" />}
                                        actions={[
                                            <FileAccessButton
                                                key="fileAccess"
                                                onClick={isAdmin || folderWriteAccess ? handleFileAccessExternal : null}
                                                disabled={isAdmin ? false : !folderWriteAccess}
                                            />,
                                        ]}
                                    >
                                        {() =>
                                            <FileAccess
                                                folderId={folderId}
                                                fileContainerId={id}
                                                ref={fileAccessRef}
                                            />
                                        }
                                    </Section>

                                    <AttachedFiles
                                        files={data.files.data.filter(file => !file.primary)}
                                        onAfterDelete={handleAuxDeleteSubmit}
                                        onAfterUpload={handleAuxAddSubmit}
                                    />
                                </Grid>
                            }

                            <Grid item xs={12} md={editorOpen? 12 : 6}>
                                <Button
                                    variant="outlined"
                                    disabled={editorOpen || (isAdmin ? false : !folderWriteAccess)}
                                    onClick={!editorOpen && (isAdmin || folderWriteAccess) ? handleMoveDialogOpen : null}
                                >
                                    <FormattedMessage id="action.move" />
                                </Button>

                                <Button
                                    onClick={!editorOpen && (isAdmin || folderWriteAccess) ? handleDeleteOpen : null}
                                    variant="outlined"
                                    disabled={editorOpen || (isAdmin ? false : !folderOwnerAccess)}
                                >
                                    <FormattedMessage id="action.delete" />
                                </Button>

                                {!isUploaded && (isVideo || isAudio) &&
                                    <Button
                                        variant={editorOpen ? 'contained' : 'outlined'}
                                        onClick={() => {setEditorOpen(!editorOpen)}}
                                        color={editorOpen ? 'primary' : 'default'}
                                        disabled={!folderWriteAccess}
                                    >
                                        <FormattedMessage id="action.edit" />
                                    </Button>
                                }

                                {primaryFile && !folderData.personal && !isVideo && (
                                    <DownloadMediaButton
                                        color={editorOpen ? 'primary' : 'default'}
                                        contentType={primaryFile.contentType}
                                        downloadUrl={`/api/media/folders/${folderId}/file_containers/${id}/files/${primaryFile.id}/download`}
                                        filename={primaryFile.name}
                                        variant={editorOpen ? 'contained' : 'outlined'}
                                    />
                                )}

                                {!folderData.personal && isVideo && hasVariant(data, '480.mp4') && (
                                    <DownloadVariantButton
                                        folderId={folderId}
                                        fileContainerId={id}
                                        variant="480.mp4"
                                        color={editorOpen ? 'primary' : 'default'}
                                        buttonVariant={editorOpen ? 'contained' : 'outlined'}
                                    >
                                        480p
                                    </DownloadVariantButton>
                                )}

                                {!folderData.personal && isVideo && hasVariant(data, '1080.mp4') && (
                                    <DownloadVariantButton
                                        folderId={folderId}
                                        fileContainerId={id}
                                        variant="1080.mp4"
                                        color={editorOpen ? 'primary' : 'default'}
                                        buttonVariant={editorOpen ? 'contained' : 'outlined'}
                                    >
                                        1080p
                                    </DownloadVariantButton>
                                )}

                                {(isVideo || isAudio) ?
                                    hasVariant(data, 'master-playlist.m3u8') ?
                                        <VideoPlayer
                                            fileContainerId={id}
                                            folderId={folderId}
                                            fileId={data.files.data.find(file => file.primary)?.id}
                                            enableBookmarks={!isUploaded}
                                            editorOpen={editorOpen}
                                            className={classes.videoBox}
                                            captionFiles={getCaptionFiles()}
                                        />
                                        :
                                        <>
                                            <TranscodingProcessInfo
                                                status={data.transcodingProcessInfo?.data?.status || 'initial'}
                                            />
                                            <Typography>
                                                <FormattedMessage id="info.not_ready_for_streaming" />
                                            </Typography>
                                        </>
                                    : null
                                }

                                {isImage && (
                                    <ImageViewer
                                        fileContainerId={id}
                                        folderId={folderId}
                                        fileId={data.files.data.find(file => file.primary)?.id}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Fragment>
            </FolderContext.Provider>
        </BasePage>
    );
};

MediaFile.propTypes = {
    asAdmin: PropTypes.bool,
};

MediaFile.defaultProps = {
    asAdmin: false,
};

export default MediaFile;
