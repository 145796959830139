import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { FileAccessExternalReadDialog } from './ExternalAccessDialogs';
import { useJsonRequest } from '../utils/axios';
import { AccessTable } from './FolderAccess';
import RemoveAccessDialog from './RemoveAccessDialog';
import { useFolder } from '../routes/Folder';

const useStyles = makeStyles(theme => ({
    ownerRow: {
        display: 'flex',
        flexDirection: 'column',
        '& > :not(:first-child)': {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            flexDirection: 'row',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(2),
                marginTop: 0,
            },
        },
    },
    subSection: {
        marginTop: theme.spacing(2),
    },
}));

const FileAccess = forwardRef(({folderId, fileContainerId}, ref) => {
    const classes = useStyles();
    const [dialog, setDialog] = useState(null);
    const { isAdmin, writeAccess } = useFolder();
    const {
        data: fileRoles,
        loading: fileRolesLoading,
        error: fileRolesError,
        reload: fileRolesReload,
    } = useJsonRequest(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/roles`);

    useImperativeHandle(ref, () => ({
        externalReadAccess: handleExternalAccessClick,
    }));

    const handleDialogClose = () => setDialog(null);

    const handleDelete = role => {
        setDialog(
            <RemoveAccessDialog
                folderId={folderId}
                fileContainerId={fileContainerId}
                role={role}
                isFile={true}
                onClose={handleDialogClose}
                onAfterRemove={fileRolesReload}
            />
        );
    };

    const handleExternalAccessClick = () => setDialog(
        <FileAccessExternalReadDialog
            onClose={handleDialogClose}
            onSuccess={fileRolesReload}
        />
    );

    return (
        <Grid container spacing={2}>
            {dialog}
            <Grid item xs={12} className={classes.subSection}>
                <Typography variant="h6" component="div">
                    <FormattedMessage id="label.access_external" />
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" component="div">
                        <FormattedMessage id="label.access_file_read" />:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccessTable
                        data={fileRoles || []}
                        loading={fileRolesLoading}
                        error={fileRolesError}
                        onDelete={isAdmin || writeAccess ? handleDelete : null}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
});

export const FileAccessButton = ({ onClick, disabled }) => (
    <Button
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
    >
        <FormattedMessage id="action.give_external_access_to_file" />
    </Button>
);

FileAccess.propTypes = {
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string.isRequired,
};

FileAccess.defaultProps = {
};

export default FileAccess;
