import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import UseRightPurposeSelector from './UseRightPurposeSelector';
import { useJsonRequest } from '../utils/axios';

const useStyles = makeStyles(theme => ({
    flexGap: {
        alignItems: 'center',
            display: 'flex',
            marginLeft: theme.spacing(-1),
            marginTop: theme.spacing(-1),
            '& > *': {
            marginLeft: theme.spacing(1),
                marginTop: theme.spacing(1),
        },
    },
    spacing: {
        '& > * + *': {
            'marginTop': theme.spacing(2),
        },
    },
}));

export const UseRightAddButton = ({ onClick, disabled }) => {
    return (
        <Button
            color="secondary"
            disabled={disabled || false}
            onClick={onClick}
            startIcon={<AddIcon />}
            variant="contained"
        >
            <FormattedMessage id="action.add" />
        </Button>
    );
};

const UseRightForm = ({ onAdd, onClose, isLicense }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { data: useRights, loaded: useRightsLoaded } = useJsonRequest('/api/media/use_right_purposes.json');
    const [selectedPlatforms, setSelectedPlatforms] = useState({});
    const [platformRequired, setPlatformRequired] = useState(false);
    const [purpose, setPurpose] = useState('');
    const [purposeTitle, setPurposeTitle] = useState('');
    const [availablePlatforms, setAvailablePlatforms] = useState([]);
    const [expiresAt, setExpiresAt] = useState(null);
    const [open, setOpen] = useState(true);
    const [fileTypes, setFileTypes] = useState({
        audioUseAllowed: false,
        imageUseAllowed: false,
        videoUseAllowed: false,
    });

    const handleFileTypesChange = event => setFileTypes({
        ...fileTypes,
        [event.target.name]: event.target.checked,
    });

    const handleAdd = () => {
        let platforms = null;

        if (availablePlatforms) {
            const selected = Object.keys(selectedPlatforms);
            platforms = selected.length > 0 ? selected : null;
        }

        onAdd({
            purpose,
            publishingPlatforms: platforms,
            platformValues: selectedPlatforms,
            ...fileTypes,
            ...{ expiresAt },
            purposeTitle, // for display purposes
        });
        setOpen(false);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();

            if (canAdd) {
                handleAdd();
            }
        }
    }

    const handleClearExpiry = () => setExpiresAt(null);
    const handleExpiryChange = date => setExpiresAt(date);
    const handlePurposeChange = ({ label = '', title = '', platforms = [], platformRequired = true } = {}) => {
        setPurpose(label);
        setAvailablePlatforms(platforms);
        setPurposeTitle(title);
        setSelectedPlatforms({});
        setPlatformRequired(platformRequired);
    };
    const handlePublishingPlatformSelect = event => {
        if (event.target.checked) {
            const platform = availablePlatforms.find(p => p.title === event.target.value);
            setSelectedPlatforms({
                ...selectedPlatforms,
                [event.target.value]: platform.canSpecify ? '' : null,
            });
        } else {
            const values = {...selectedPlatforms};
            delete(values[event.target.value]);
            setSelectedPlatforms(values);
        }
    };
    const handlePublishingPlatformValue = event => {
        setSelectedPlatforms({
            ...selectedPlatforms,
            [event.target.name]: event.target.value,
        });
    };
    const handleClose = () => setOpen(false);

    const canAdd = purpose &&
        (availablePlatforms.length === 0 || (
            (platformRequired === false || Object.keys(selectedPlatforms).length > 0) &&
            Object.values(selectedPlatforms).every(p => p === null || p.trim() !== '')
        )) &&
        Object.values(fileTypes).filter(v => v).length > 0;

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="label.add_useright" />
            </DialogTitle>
            <DialogContent>
                <div
                    className={clsx(classes.spacing)}
                    onKeyDown={handleKeyDown}
                >
                    <UseRightPurposeSelector
                        onChange={handlePurposeChange}
                        value={purpose}
                        fullWidth
                        size="small"
                        showHidden={isLicense}
                        purposes={useRightsLoaded ? useRights : []}
                        purposesLoaded={useRightsLoaded}
                    />

                    {availablePlatforms.length > 0 && (
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">
                                <FormattedMessage id="label.publishing_platform" />
                            </FormLabel>
                            <FormGroup row>
                                {availablePlatforms.map(platform => (
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        key={platform.label}
                                        label={platform.title}
                                        value={platform.title}
                                        onChange={handlePublishingPlatformSelect}
                                        checked={Object.prototype.hasOwnProperty.call(selectedPlatforms, platform.title)}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    )}
                    {availablePlatforms
                        .filter(platform => (
                            platform.canSpecify && Object.prototype.hasOwnProperty.call(selectedPlatforms, platform.title)
                        ))
                        .map(platform => (
                            <TextField
                                key={platform.label + '_text'}
                                fullWidth
                                label={platform.title}
                                name={platform.title}
                                onChange={handlePublishingPlatformValue}
                                size="small"
                                value={Object.prototype.hasOwnProperty.call(selectedPlatforms, platform.title) ?
                                    selectedPlatforms[platform.title] :
                                    ''
                                }
                                variant="outlined"
                                required={true}
                            />
                        ))
                    }

                    <div>
                        <div className={classes.flexGap}>
                            <DatePicker
                                autoOk
                                disablePast
                                emptyLabel={formatMessage({ id: 'label.never_expires' })}
                                inputVariant="outlined"
                                label={<FormattedMessage id="label.expires" />}
                                onChange={handleExpiryChange}
                                size="small"
                                value={expiresAt}
                                variant="inline"
                                id="expires-date"
                            />

                            <IconButton
                                aria-label={formatMessage({ id: 'action.clear_expiry_date' })}
                                disabled={!expiresAt}
                                onClick={handleClearExpiry}
                                variant="contained"
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                <FormattedMessage id="label.allowed_file_types" />
                            </FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={fileTypes.audioUseAllowed}
                                        name="audioUseAllowed"
                                        onChange={handleFileTypesChange}
                                    />}
                                    label={<FormattedMessage id="label.audio" />}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={fileTypes.imageUseAllowed}
                                        name="imageUseAllowed"
                                        onChange={handleFileTypesChange}
                                    />}
                                    label={<FormattedMessage id="label.image" />}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={fileTypes.videoUseAllowed}
                                        name="videoUseAllowed"
                                        onChange={handleFileTypesChange}
                                    />}
                                    label={<FormattedMessage id="label.video" />}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    <FormattedMessage id="action.cancel" />
                </Button>
                <UseRightAddButton
                    disabled={!canAdd}
                    onClick={handleAdd}
                />
            </DialogActions>
        </Dialog>
    );
};

UseRightForm.propTypes = {
    onAdd: PropTypes.func,
    isLicense: PropTypes.bool,
};

UseRightForm.defaultProps = {
    onAdd: () => {},
    isLicense: false,
};

export default UseRightForm;
