import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { TableSortLabel } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';

const useStyles = makeStyles(() => ({
    fitContent: {
        width: 1,
        whiteSpace: 'nowrap',
    },
    iconContent: {
        width: 50,
    },
    verticalScrollbarFix: { // Fixes unwanted vertical scrollbar when stickyHeader is used on table
        borderCollapse: 'collapse',
    },
}));

export const COLUMN_TYPES = {
    DATE: 'date',
    DATETIME: 'datetime',
    NUMBER: 'number',
    RAW: 'raw',
    TIME: 'time',
    TEXT: 'text',
};

export const COLUMN_STYLES = {
    NO_PADDING: 'noPadding',
    FIT_CONTENT: 'fitContent',
    ICON: 'icon',
    ALIGN_RIGHT: 'alignRight',
};

const SORTABLE_COLUMN_TYPES = [
    COLUMN_TYPES.DATE,
    COLUMN_TYPES.DATETIME,
    COLUMN_TYPES.TIME,
    COLUMN_TYPES.TEXT,
    COLUMN_TYPES.NUMBER,
];

const COLUMN_TYPE_DEFAULT_SORT_ORDER = {
    [COLUMN_TYPES.DATE]: 'desc',
    [COLUMN_TYPES.DATETIME]: 'desc',
    [COLUMN_TYPES.TIME]: 'desc',
    [COLUMN_TYPES.TEXT]: 'asc',
    [COLUMN_TYPES.NUMBER]: 'asc',
};

const DataTable = ({
    columns,
    rows,
    rowProps,
    loading,
    columnTypes,
    error,
    columnStyles,
    maxHeight,
    highlight,
    addRowId,
    hideHeader,
    sortable,
    defaultSortColumn,
    defaultSortOrder,
    sortColumnMap,
    sortHandler,
    ...props
}) => {
    const classes = useStyles();
    const realRows = (!error && !loading)
        ? (typeof rows === 'function' ? rows() : rows)
        : [];
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [sortColumn, setSortColumn] = useState(defaultSortColumn);
    const handleSortChange = (column, order) => () => {
        sortByColumn(column, order);
    };
    const sortByColumn = (column, order) =>  {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(order);
            setSortColumn(column);
        }
    };

    const getDefaultSortOrder = (columnKey, columnType) => {
        if (columnKey === defaultSortColumn) {
            return defaultSortOrder;
        }

        if (Object.prototype.hasOwnProperty.call(COLUMN_TYPE_DEFAULT_SORT_ORDER, columnType)) {
            return COLUMN_TYPE_DEFAULT_SORT_ORDER[columnType];
        }

        return 'asc';
    };

    const sortData = (a, b) => {
        const key = sortColumnMap[sortColumn] ?? sortColumn;
        if (typeof sortHandler === 'function') {
            return sortHandler(a, b, key, sortOrder);
        }
        if (sortHandler !== null &&
            Object.prototype.hasOwnProperty.call(sortHandler, sortColumn) &&
            typeof sortHandler[sortColumn] === 'function'
        ) {
            return sortHandler[sortColumn](a, b, key, sortOrder);
        }

        if (typeof key === 'string' &&
            Object.prototype.hasOwnProperty.call(a, key) &&
            Object.prototype.hasOwnProperty.call(b, key) &&
            ['string', 'number'].includes(typeof a[key]) &&
            ['string', 'number'].includes(typeof b[key])
        ) {
            const valueA = typeof a[key] === 'string' ? a[key].toLocaleLowerCase() : a[key];
            const valueB = typeof b[key] === 'string' ? b[key].toLocaleLowerCase() : b[key];
            const sorter = (a, b) => (a < b) ? 1 : (b < a ? -1 : 0);

            return sortOrder === 'desc' ? sorter(valueA, valueB) : -sorter(valueA, valueB);
        }

        return 0;
    };

    const formattedCell = (key, value) => {
        let formatted = '';
        const cellProps = {...columnStyle(key)};

        if (typeof value !== 'undefined' && value !== null) {
            switch (columnTypes[key] ?? '') {
                case COLUMN_TYPES.DATE:
                    formatted = (
                        <Typography variant="inherit">
                            <FormattedDate value={value} format="short" />
                        </Typography>
                    );
                    break;
                case COLUMN_TYPES.TIME:
                    formatted = (
                        <Typography variant="inherit">
                            <FormattedTime value={value} format="short" />
                        </Typography>
                    );
                    break;
                case COLUMN_TYPES.DATETIME:
                    formatted = (
                        <Typography variant="inherit">
                            <FormattedDate value={value} format="short" />
                            {' '}
                            <FormattedTime value={value} format="short" />
                        </Typography>
                    );
                    break;
                case COLUMN_TYPES.NUMBER:
                    formatted = <Typography variant="inherit"><FormattedNumber value={value} /></Typography>;
                    break;
                case COLUMN_TYPES.RAW:
                    formatted = value;
                    break;

                case COLUMN_TYPES.TEXT:
                    // fall through
                default:
                    formatted = <Typography variant="inherit">{value}</Typography>;
            }
        }

        return (
            <TableCell key={key} {...cellProps}>
                {formatted}
            </TableCell>
        );
    }

    const formattedHeader = (key, title) => {
        let label = title;
        const columnType = columnTypes[key] ?? '';

        if ((sortable === true && SORTABLE_COLUMN_TYPES.includes(columnType)) ||
            (Array.isArray(sortable) && sortable.includes(key))
        ) {
            const defaultOrder = getDefaultSortOrder(key, columnType);
            label = (
                <TableSortLabel
                    active={sortColumn === key}
                    direction={sortColumn === key ? sortOrder : defaultOrder}
                    onClick={handleSortChange(key, defaultOrder)}
                >
                    {title}
                </TableSortLabel>
            );
        }

        return (
            <TableCell key={key} {...columnStyle(key)}>
                {label}
            </TableCell>
        );
    };

    const columnStyle = (key) => {
        switch (columnStyles[key] ?? '') {
            case COLUMN_STYLES.NO_PADDING:
                return {
                    padding: 'none',
                };

            case COLUMN_STYLES.FIT_CONTENT:
                return {
                    className: classes.fitContent,
                };

            case COLUMN_STYLES.ICON:
                return {
                    padding: 'none',
                    className: classes.iconContent,
                    align: 'center',
                };
            case COLUMN_STYLES.ALIGN_RIGHT:
                return {
                    className: classes.fitContent,
                    align: 'right',
                };
            default:
                if (typeof columnStyles[key] === 'object') {
                    return columnStyles[key];
                }

                return {};
        }
    };

    const renderState = () => {
        let state;

        if (loading) {
            state = <CircularProgress />;
        } else if (error !== false) {
            state = (
                <Typography>
                    <FormattedMessage id="error.loading_failed" />
                </Typography>
            );
        } else if (realRows.length === 0) {
            state = (
                <Typography>
                    <FormattedMessage id="notice.empty_data" />
                </Typography>
            );
        }

        if (typeof state !== 'undefined') {
            return (
                <TableRow {...rowProps}>
                    <TableCell
                        align={loading ? 'left' : 'center'}
                        colSpan={Object.keys(columns).length}
                    >
                        {state}
                    </TableCell>
                </TableRow>
            );
        }

        return null;
    };

    const highlightRow = (row) => {
        return (row.id && highlight === row.id);
    };

    const style = {};
    if (typeof maxHeight === 'number' && maxHeight > 0) {
        style.maxHeight = `${maxHeight}px`;
    } else if (typeof maxHeight === 'string') {
        style.maxHeight = maxHeight;
    } else if (maxHeight !== null) {
        style.maxHeight = '600px';
    }

    const buildRowProps = (row, i) => {
        const props = {
            key: row.id ?? i,
            selected: highlightRow(row),
            ...rowProps
        };
        if (addRowId && row.id) {
            props.id = row.id;
        }

        return props;
    };

    return (
        <TableContainer style={style}>
            <Table stickyHeader {...props} className={classes.verticalScrollbarFix}>
                {!hideHeader &&
                    <TableHead>
                        <TableRow>
                            {Object.keys(columns).map(key => formattedHeader(key, columns[key]))}
                        </TableRow>
                    </TableHead>
                }
                <TableBody>
                    {renderState()}
                    {realRows.length > 0 &&
                        realRows
                            .sort(sortData)
                            .map((row, i) => (
                                <TableRow {...buildRowProps(row, i)}>
                                    {Object.keys(columns).map(key => formattedCell(key, row[key]))}
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

DataTable.propTypes = {
    columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loading: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    rowProps: PropTypes.object,
    columnTypes: PropTypes.object,
    columnStyles: PropTypes.object,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    highlight: PropTypes.string,
    addRowId: PropTypes.bool,
    hideHeader: PropTypes.bool,
    sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    defaultSortOrder: PropTypes.oneOf(['asc', 'desc']),
    defaultSortColumn: PropTypes.string,
    sortColumnMap: PropTypes.object,
    sortHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

DataTable.defaultProps = {
    columns: {},
    loading: false,
    rows: [],
    rowProps: {},
    columnTypes: {},
    columnStyles: {},
    maxHeight: 600,
    error: false,
    highlight: null,
    addRowId: true,
    hideHeader: false,
    sortable: false,
    defaultSortOrder: 'asc',
    defaultSortColumn: null,
    sortColumnMap: {},
    sortHandler: null,
};

export default DataTable;
