import React, { createRef, Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1),
            padding: theme.spacing(.5),
        },
    },
    staticText: {
        flexGrow: 1,
        whiteSpace: 'pre-wrap',
    },
    staticPlaceholder: {
        color: theme.palette.text.disabled,
    },
    clickable: {
        cursor: 'pointer',
    },
    multilineText: {
        whiteSpace: 'pre-line',
    },
    input: {
        font: 'inherit',
    },
    applyIcon: {
        color: green[800],
    },
    cancelIcon: {
        color: red[800],
    },
    outlined: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '17.5px 13px',
        borderRadius: theme.shape.borderRadius,
    },
}));

const EditableText = ({ className, inputProps, label, multiline, onSave, placeholder, text, outlined, readOnly }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const inputRef = createRef();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleInputKeyDown = event => {
        inputProps.onKeyDown && inputProps.onKeyDown(event);

        if (!multiline && !event.isDefaultPrevented() && event.key === 'Enter') {
            handleSave();
        }
    }

    const handleEdit = () => setEditing(true);

    const handleAbort = () => setEditing(false);

    const handleSave = () => {
        if (inputRef.current.value === text) {
            setEditing(false);

            return;
        }

        setLoading(true);

        (async () => {
            try {
                await onSave(inputRef.current.value);
                setEditing(false);
            } finally {
                setLoading(false);
            }
        })();
    };

    return (
        <span className={clsx(classes.wrapper, className)}>
            {editing && (
                <Fragment>
                    <TextField
                        variant={outlined ? 'outlined' : 'standard'}
                        {...inputProps}
                        aria-label={label}
                        autoFocus={editing}
                        className={classes.input}
                        defaultValue={text}
                        disabled={loading}
                        fullWidth
                        inputRef={inputRef}
                        InputProps={{ classes: { root: classes.input } }}
                        multiline={multiline}
                        onKeyDown={handleInputKeyDown}
                        placeholder={placeholder}
                    />
                    {!loading && (
                        <Fragment>
                            <IconButton
                                aria-label={formatMessage({ id: 'action.save' })}
                                className={classes.applyIcon}
                                color="inherit"
                                onClick={handleSave}
                                size="medium"
                            >
                                <CheckIcon />
                            </IconButton>
                            <IconButton
                                aria-label={formatMessage({ id: 'action.cancel' })}
                                className={classes.cancelIcon}
                                onClick={handleAbort}
                                size="medium"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Fragment>
                    )}
                    {loading && <CircularProgress />}
                </Fragment>
            )}
            {!editing && (
                <Fragment>
                    <span
                        className={clsx({
                            [classes.staticPlaceholder]: !text,
                            [classes.clickable]: !text && !readOnly,
                            [classes.staticText]: true,
                            [classes.multilineText]: multiline,
                            [classes.outlined]: outlined,
                        })}
                        onClick={text || readOnly ? null : handleEdit}
                    >
                        {text || placeholder}
                    </span>
                    {onSave && !readOnly && (
                        <IconButton
                            aria-label={formatMessage({ id: 'action.edit' })}
                            color="inherit"
                            onClick={handleEdit}
                            size="medium"
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                </Fragment>
            )}
        </span>
    );
};

EditableText.propTypes = {
    className: PropTypes.string,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    onSave: PropTypes.func,
    placeholder: PropTypes.string,
    text: PropTypes.string,
    outlined: PropTypes.bool,
    readOnly: PropTypes.bool,
};

EditableText.defaultProps = {
    className: '',
    inputProps: {},
    label: null,
    multiline: false,
    onSave: null,
    placeholder: '',
    text: '',
    outlined: false,
    readOnly: false,
};

export default EditableText;
