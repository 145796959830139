import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import BaseVideoPlayer from './VideoPlayer';
import BaseImageViewer from './ImageViewer';
import { fileIsOfType, FILE_TYPE } from '../utils/file';
import { useStream } from '../utils/stream';

const getStreamUrl = (fileContainerId, fileId) =>
    `/api/media/file_containers/${fileContainerId}/file/${fileId}/streaming_request`;

const VideoPlayer = ({ fileContainerId, fileId }) => (
    <BaseVideoPlayer
        stream={useStream(getStreamUrl(fileContainerId, fileId))}
        autoPlay={true}
    />
);

const ImageViewer = ({ fileContainerId, fileId }) => (
    <BaseImageViewer
        stream={useStream(getStreamUrl(fileContainerId, fileId))}
    />
);

const ExternalViewFileDialog = ({ fileContainer, file, onClose }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => setOpen(false);

    const isVideo = fileIsOfType(file, FILE_TYPE.VIDEO);
    const isAudio = fileIsOfType(file, FILE_TYPE.AUDIO);
    const isImage = fileIsOfType(file, FILE_TYPE.IMAGE);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onExited={onClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
        >
            <DialogTitle>
                {fileContainer.name}
            </DialogTitle>

            <DialogContent>
                {(isVideo || isAudio) && (
                    <VideoPlayer
                        fileContainerId={fileContainer.id}
                        fileId={file.id}
                    />
                )}
                {isImage &&
                    <ImageViewer
                        fileContainerId={fileContainer.id}
                        fileId={file.id}
                    />
                }
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    <FormattedMessage id="action.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ExternalViewFileDialog.propTypes = {
    fileContainer: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

ExternalViewFileDialog.defaultProps = {
    onClose: (() => {}),
};

export default ExternalViewFileDialog;
