import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { FormattedMessage } from 'react-intl';
import { axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';

const RemoveFolderDialog = ({ folderId, folderName, onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(true);
    const cancelToken = useRef(CancelToken.source());

    useEffect(() => () => cancelToken.current.cancel(), []);

    const handleClose = () => setOpen(false);
    const handleSubmit = async event => {
        event.preventDefault();

        try {
            setLoading(true);
            await axios.delete(`/api/media/folders/${folderId}`, {
                cancelToken: cancelToken.current.token,
            });
            setLoading(false);
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (e) {
            if (!isCancel(e)) {
                if (e.response.data?.error) {
                    setError(<FormattedMessage id="error.delete_folder_consent" />);
                } else {
                    setError(<FormattedMessage id="error.delete_folder_failed" />);
                }
                setLoading(false);
            }
        }
    };

    return (
        <Dialog open={open} fullWidth onClose={handleClose} onExited={onClose}>
            <DialogTitle>
                <FormattedMessage id="action.remove_folder" />
            </DialogTitle>

            {loading && (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            )}
            {!loading && (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        {error}
                        {!error &&
                            <FormattedMessage
                                id="prompt.confirm_folder_removal"
                                values={{ name: <strong>{folderName}</strong> }}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">
                            <FormattedMessage id="action.cancel" />
                        </Button>

                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={!!error}
                        >
                            <FormattedMessage id="action.delete" />
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

RemoveFolderDialog.propTypes = {
    onClose: PropTypes.func,
    folderId: PropTypes.string.isRequired,
    folderName: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
};

RemoveFolderDialog.defaultProps = {
    onClose: (() => {}),
    onSuccess: () => {},
};

export default RemoveFolderDialog;
