import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import BasePage from '../components/BasePage';
import DashboardFolders from '../components/DashboardFolders';
import DashboardNotifications from '../components/DashboardNotifications';
import DashboardTodo from '../components/DashboardTodo';
import { useAuth, AUTH_STRATEGY } from '../auth/AuthProvider';
import DashboardConsentsForApproval from '../components/DashboardConsentsForApproval';
import DashboardFiles from '../components/DashboardFiles';

const Dashboard = () => {
    const { formatMessage } = useIntl();
    const { strategy } = useAuth();

    return (
        <BasePage
            breadcrumbs
            title={formatMessage({ id: strategy === AUTH_STRATEGY.AUTH0 ? 'places.dashboard' : 'places.the_portal' })}
            unwrapChildren
        >
            {strategy === AUTH_STRATEGY.AUTH0 && (
                <Fragment>
                    <DashboardTodo />
                    <DashboardNotifications />
                    <DashboardFolders sensitive />
                    <DashboardFolders />
                </Fragment>
            )}
            {strategy === AUTH_STRATEGY.IDPORTEN && (
                <Fragment>
                    <DashboardConsentsForApproval />
                    <DashboardFiles />
                </Fragment>
            )}
        </BasePage>
    );
};

export default Dashboard;
