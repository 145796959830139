import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import BasePage from './components/BasePage';
import Front from './routes/Front';
import Dashboard from './routes/Dashboard';
import MediaFile from './routes/MediaFile';
import {
    AdminNonSensitiveFolders,
    AdminSensitiveFolders,
    MyNonSensitiveFolders,
    MySensitiveFolders
} from './routes/Folders';
import Folder from './routes/Folder';
import Search from './routes/Search';
import MyConsent from './routes/MyConsent';
import { AUTH_STRATEGY } from './auth/AuthProvider';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'

const App = () => {
    const { formatMessage } = useIntl();

    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <Route exact path="/" component={Front} />
                <AuthRoute exact path="/dashboard" component={Dashboard} />

                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/admin/folders" component={AdminNonSensitiveFolders} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/admin/folders/sensitive" component={AdminSensitiveFolders} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/admin/folders/:id" render={props => (
                    <Folder {...props} asAdmin={true} />
                )} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/admin/folders/:folderId/media/:id" render={props => (
                    <MediaFile {...props} asAdmin={true} />
                )} />

                <AuthRoute strategy={AUTH_STRATEGY.IDPORTEN} exact path="/consents/:consent" component={MyConsent} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/folders" component={MyNonSensitiveFolders} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/folders/sensitive" component={MySensitiveFolders} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/folders/:id" component={Folder} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/folders/:folderId/media/:id" component={MediaFile} />
                <AuthRoute strategy={AUTH_STRATEGY.AUTH0} exact path="/search" component={Search} />

                <Route>
                    <BasePage title={formatMessage({ id: 'error.not_found' })} />
                </Route>
            </Switch>
        </ThemeProvider>
    );
};

export default App;
