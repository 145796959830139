import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import history from './utils/history';
import { IntlProvider } from 'react-intl';
import { getUserLocale } from './language';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from './auth/AuthProvider';
import Auth0Strategy from './auth/auth0';
import IdportenStrategy from './auth/idporten';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateFnsUtilsLocalized, intlProviderFormats } from './language/dateTimeFormats';

// A function that routes the user to the right place after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const userLocale = getUserLocale();
const dateFnsLocale = userLocale === 'en' ? 'en-GB' : userLocale;
document.documentElement.setAttribute('lang', userLocale);

Promise.all([
    import(`./language/${userLocale}.js`),
    import(`date-fns/locale/${dateFnsLocale}/index.js`),
]).then(([{ default: messages }, { default: dateFnsLocale }]) => {
    ReactDOM.render(
        <MuiPickersUtilsProvider
            locale={dateFnsLocale}
            utils={DateFnsUtilsLocalized}
        >
            <IntlProvider
                locale={userLocale}
                defaultLocale="nb"
                messages={messages}
                formats={intlProviderFormats}
            >
                <AuthProvider
                    strategies={[
                        new Auth0Strategy({
                            onRedirectCallback,
                            redirectUri: window.origin,
                        }),
                        new IdportenStrategy(),
                    ]}
                >
                    <Router>
                        <App />
                    </Router>
                </AuthProvider>
            </IntlProvider>
        </MuiPickersUtilsProvider>,
        document.getElementById('root')
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
