import { useCallback, useEffect, useRef } from 'react';
import { useJsonRequest } from './axios';

export const usePolling = (delay) => {
    const timeoutId = useRef(null);
    const savedDelay = useRef(typeof delay === 'number' ? Math.max(delay, 1000) : 120000);
    const savedUrl = useRef(null);
    const isRunning = useRef(false);

    const {
        load,
        reload,
        data,
        loaded,
        loading,
        statusCode,
        error,
        statusText,
    } = useJsonRequest(null);

    useEffect(() => {
        return () => {
            if (typeof timeoutId.current === 'number' && isRunning.current) {
                clearTimeout(timeoutId.current);
                timeoutId.current = null;
                isRunning.current = false;
            }
        };
    }, []);

    useEffect(() => {
        if (isRunning.current && savedDelay.current && !loading && savedUrl.current) {
            timeoutId.current = setTimeout(() => {reload()}, savedDelay.current);
        }
    }, [reload, loading]);

    const start = useCallback((url, requestOptions = {}) => {
        if (!isRunning.current && typeof url === 'string' && url.length > 0) {
            savedUrl.current = url;
            isRunning.current = true;
            load({ url: url, ...requestOptions });
        }
    }, [load]);

    const stop = useCallback(() => {
        if (typeof timeoutId.current === 'number' && isRunning.current) {
            clearTimeout(timeoutId.current)
            timeoutId.current = null;
            isRunning.current = false;
        }
    }, []);

    return {
        data,
        error,
        loaded,
        loading,
        running: isRunning.current,
        start,
        statusCode,
        statusText,
        stop,
    };
};

export default usePolling;
