import React, { useRef, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BasePage from '../components/BasePage';
import EditableText from '../components/EditableText';
import { FolderAccessExternalUploadDialog } from '../components/ExternalAccessDialogs';
import FolderMenu from '../components/FolderMenu';
import FolderAbout from '../components/FolderAbout';
import FolderAccess from '../components/FolderAccess';
import FolderFabris from '../components/FolderFabris';
import FolderFiles, { UploadButton } from '../components/FolderFiles';
import Section from '../components/Section';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import { Consents, CreateButton, UploadConsentButton } from '../components/Consents';
import { ExternalUploaderButton } from '../components/FolderAccess';
import { NOTIFICATION_CATEGORY } from '../components/DashboardNotifications';
import { useAuth } from '../auth/AuthProvider';

const useStyles = makeStyles((theme) => ({
    sensitive: {
        color: theme.palette.statped.textSensitive,
    },
    nonSensitive: {
        color: theme.palette.statped.textOpen,
    },
    error: {
        paddingBottom: theme.spacing(1),
    },
    borderSensitive: {
        borderBottomColor: theme.palette.statped.backgroundSensitive,
        borderBottomStyle: 'solid',
        borderBottomWidth: 5,
    },
    borderOpen: {
        borderBottomColor: theme.palette.statped.backgroundOpen,
        borderBottomStyle: 'solid',
        borderBottomWidth: 5,
    },
}));

export const FolderContext = createContext({
    id: null,
    role: '',
    ownerId: null,
    ownerAccess: false,
    writeAccess: false,
    readAccess: false,
    isAdmin: false,
});
export const useFolder = () => useContext(FolderContext);

export const folderAccess = (role) => ({
    ownerAccess: ['OWNER'].includes(role),
    writeAccess: ['OWNER', 'WRITE'].includes(role),
    readAccess: ['OWNER', 'WRITE', 'READ'].includes(role),
});

const Folder = ({ asAdmin }) => {
    const location = useLocation();
    const classes = useStyles();
    const { id } = useParams();
    const { formatMessage } = useIntl();
    const { data, setData, error, loading, statusCode } = useJsonRequest(`/api/media/folders/${id}`);
    const [dialog, setDialog] = useState(null);
    const consentRef = useRef();
    const fileRef = useRef();
    const aboutRef = useRef();
    const licenseRef = useRef();
    const folderAccessRef = useRef();
    const { ownerAccess, writeAccess, readAccess } = folderAccess(data?.role?.data?.role);
    const { user } = useAuth();
    const isAdmin = asAdmin && user.admin;

    const handleDialogClose = () => setDialog(null);

    const handleAddConsent = () => {
        if (consentRef.current) {
            consentRef.current.create();
        }
    };

    const handleAddLicense = () => {
        if (licenseRef.current) {
            licenseRef.current.create();
        }
    };

    const handleUploadConsent = () => {
        if (consentRef.current) {
            consentRef.current.uploadConsent();
        }
    };

    const handleUploadFile = () => {
        if (fileRef.current) {
            fileRef.current.upload();
        }
    };

    const handleExternalUploader = () => {
        if (folderAccessRef.current) {
            folderAccessRef.current.externalUploader();
        } else {
            setDialog(
                <FolderAccessExternalUploadDialog
                    onClose={handleDialogClose}
                />
            );
        }
    };

    if (error) {
        let title;
        switch (statusCode) {
            case 403:
                title = formatMessage({ id: 'error.access_denied' });
                break;
            case 404:
                title = formatMessage({ id: 'error.folder_not_found' });
                break;
            default:
                title = formatMessage({ id: 'error.loading_failed' });
        }
        return <BasePage title={title} />;
    } else if (loading) {
        return (
            <BasePage title="..." hideTitle>
                <CircularProgress />
            </BasePage>
        );
    }

    const handleSaveFolderName = async name => {
        try {
            await axios.patch(`/api/media/folders/${id}`, { name });
            updateData({ name });
        } catch {
        }
    };

    const handleOwnerChangeSuccess = (ownerId, ownerName, role) => {
        updateData({
            ownerId,
            owner: { data: { id: ownerId, name: ownerName }},
            role: { data: { role: role }}
        });
        if (folderAccessRef.current) {
            folderAccessRef.current.refreshFolderRoleLists();
        }
    };

    const handleEmployeeReadChange = async (checked) => {
        try {
            const response = await axios.patch(`/api/media/folders/${id}`, { employeeRead: checked });
            updateData({
                employeeRead: response.employeeRead,
            });
            return true;
        } catch {
            return false;
        }
    };

    const updateData = updated => {
        setData({ ...data, ...updated });
    };

    function showFabrisError() {
        switch (data?.fabris?.data?.error) {
            case 401:
                return <FormattedMessage id="error.access_denied" />;
            case 404:
                return <FormattedMessage id="error.case_not_found" />;
            default:
                return data?.fabris?.data?.error || '500';
        }
    }

    let breadCrumb = false;

    if (!data.default) {
        if (isAdmin) {
            breadCrumb = data.personal
                ? (
                    {
                        titleId: 'places.all_users',
                        to: "/admin/folders/sensitive",
                    }
                ): (
                    {
                        titleId: 'places.all_open_folders',
                        to: '/admin/folders',
                    }
                );
        } else {
            breadCrumb = data.personal
                ? (
                    {
                        titleId: 'places.my_users',
                        to: "/folders/sensitive",
                    }
                ): (
                    {
                        titleId: 'places.my_open_folders',
                        to: '/folders',
                    }
                );
        }
    }

    return (
        <BasePage
            breadcrumbs={breadCrumb}
            hideTitle
            title={data.name}
        >
            <FolderContext.Provider
                value={{
                    id: data?.id,
                    role: data?.role?.data?.role,
                    ownerId: data?.ownerId,
                    ownerAccess,
                    writeAccess,
                    readAccess,
                    isAdmin
                }}
            >
                {dialog}
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={true}>
                        <Typography
                            component="h1"
                            variant="h4"
                            className={data.personal ? classes.borderSensitive : classes.borderOpen}
                        >
                            <EditableText
                                text={data.name}
                                id={data.id}
                                name={data.name}
                                onSave={handleSaveFolderName}
                                readOnly={data.personal || (isAdmin ? false : !writeAccess)}
                                inputProps={{
                                    id: 'folder-name',
                                    inputProps: {
                                        'aria-label': formatMessage({ id: 'label.folder_name' }),
                                    }
                                }}
                            />
                        </Typography>

                        <Typography variant="h6" component="div" className={clsx({
                            [classes.sensitive]: data.personal,
                            [classes.nonSensitive]: !data.personal,
                        })}>
                            {data.personal
                                ? <FormattedMessage id="label.sensitive" />
                                : <FormattedMessage id="label.non_sensitive" />
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={false}>
                        <FolderMenu
                            folderId={data.id}
                            folderName={data.name}
                            sensitive={data.personal}
                            owner={{
                                id: data?.owner?.data?.id,
                                name: data?.owner?.data?.name,
                            }}
                            onOwnerChangeSuccess={handleOwnerChangeSuccess}
                            connectFabrisEnabled={data.serviceCode === null}
                            consentRef={consentRef}
                            aboutRef={aboutRef}
                            folderAccessRef={folderAccessRef}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid container item spacing={1} sx={12}>
                        {data.personal &&
                            <Section title={<FormattedMessage id="label.fabris_info" />}>
                                {data?.fabris?.data?.error && (
                                    <Typography variant="body1" className={classes.error}>
                                        <FormattedMessage id="error.loading_failed" />
                                        {': '}
                                        {showFabrisError()}
                                    </Typography>
                                )}
                                <FolderFabris
                                    serviceCode={data?.serviceCode}
                                    {...data?.fabris?.data}
                                />
                            </Section>
                        }

                        <Section
                            title={<FormattedMessage id="label.media_files" />}
                            actions={[
                                <UploadButton
                                    key="fileUpload"
                                    onClick={handleUploadFile}
                                    disabled={isAdmin ? false : !readAccess}
                                />,
                                <ExternalUploaderButton
                                    key="externalUploader"
                                    onClick={handleExternalUploader}
                                    disabled={isAdmin ? false : !writeAccess}
                                />
                            ]}
                            defaultExpanded={location?.state?.highlight?.section === NOTIFICATION_CATEGORY.FILE}
                        >
                            <FolderFiles
                                folderId={id}
                                sensitive={data.personal}
                                ref={fileRef}
                            />
                        </Section>

                        <Section
                            title={<FormattedMessage id="label.consents" />}
                            actions={[
                                <CreateButton
                                    key="addConsent"
                                    onClick={isAdmin || writeAccess ? handleAddConsent : null}
                                    disabled={isAdmin ? false : !writeAccess}
                                />,
                                !data.personal &&
                                    <UploadConsentButton
                                        key="uploadConsent"
                                        onClick={isAdmin || writeAccess ? handleUploadConsent : null}
                                        disabled={isAdmin ? false : !writeAccess}
                                    />,
                            ]}
                            defaultExpanded={location?.state?.highlight?.section === NOTIFICATION_CATEGORY.CONSENT}
                        >
                            <Consents
                                fabrisCaseId={data.serviceCode}
                                folderId={id}
                                ref={consentRef}
                                sensitive={data.personal}
                                serviceUserName={data.personal ? data?.fabris?.data?.userName : null}
                            />
                        </Section>

                        {!data.personal &&
                            <Section
                                title={<FormattedMessage id="label.licenses" />}
                                actions={[
                                    <CreateButton
                                        key="addConsent"
                                        onClick={isAdmin || writeAccess ? handleAddLicense : null}
                                        isLicense={true}
                                        disabled={isAdmin ? false : !writeAccess}
                                    />,
                                ]}
                                defaultExpanded={location?.state?.highlight?.section === NOTIFICATION_CATEGORY.LICENSE}
                            >
                                <Consents
                                    fabrisCaseId={data.serviceCode}
                                    folderId={id}
                                    ref={licenseRef}
                                    sensitive={false}
                                    isLicense={true}
                                />
                            </Section>
                        }

                        <Section title={<FormattedMessage id="label.about_the_folder" />}>
                            <FolderAbout
                                folderId={id}
                                sensitive={data.personal}
                                description={data.description}
                                onAfterSave={updateData}
                                serviceCode={data.serviceCode}
                                discipline={data.discipline}
                                tags={data.tags.data}
                                createdAt={data.createdAt}
                                ref={aboutRef}
                            />
                        </Section>

                        <Section title={<FormattedMessage id="label.access" />}>
                            <FolderAccess
                                onOwnerChangeSuccess={handleOwnerChangeSuccess}
                                ownerId={data.owner.data.id}
                                ownerName={data.owner.data.name}
                                folderId={data.id}
                                sensitive={data.personal}
                                employeeRead={data.employeeRead}
                                onEmployeeReadChange={handleEmployeeReadChange}
                                ref={folderAccessRef}
                            />
                        </Section>
                    </Grid>
                </Grid>
            </FolderContext.Provider>
        </BasePage>
    );
};

Folder.propTypes = {
    asAdmin: PropTypes.bool,
};

Folder.defaultProps = {
    asAdmin : false,
};

export default Folder;
