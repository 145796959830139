import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, useIntl } from 'react-intl';

const SearchBar = ({ buttonLabel, inputRef, label, onChange }) => {
    const { formatMessage } = useIntl();

    return (
        <Grid container>
            <Grid item xs={12} sm={8} md={6}>
                <TextField
                    color="primary"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button color="secondary" type="submit" variant="contained">
                                    {buttonLabel || <FormattedMessage id="action.search" />}
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    inputRef={inputRef}
                    label={label || <FormattedMessage id="action.search" />}
                    onChange={onChange}
                    variant="outlined"
                    id="input_search"
                    helperText={formatMessage({id: "help.search_input"})}
                />
            </Grid>
        </Grid>
    );
};

SearchBar.propTypes = {
    buttonLabel: PropTypes.node,
    label: PropTypes.node,
    onSubmit: PropTypes.func,
};

SearchBar.defaultProps = {
    buttonLabel: null,
    label: null,
    onSubmit: (() => {}),
};

export default SearchBar;
