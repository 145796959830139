import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useJsonRequest } from '../utils/axios';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

const purposeFlatteningReducer = (acc, cur) => [
    ...acc,
    ...(cur.children
            ? cur.children.reduce(purposeFlatteningReducer, [])
            : [{ label: cur.label, title: cur.title }]
    ),
];

const UseRightList = ({ useRights, onRemove, readOnly, showListAvatar }) => {
    const { formatMessage } = useIntl();
    const { data, loading, error } = useJsonRequest('/api/media/use_right_purposes.json');

    function getPurposeTitle(useRight) {
        if (useRight.purposeTitle) {
            return useRight.purposeTitle;
        }

        if (!loading && !error) {
            const purpose = data
                .reduce(purposeFlatteningReducer, [])
                .find(purpose => purpose.label === useRight.purpose);

            return purpose?.title ?? '';
        }

        return '';
    }

    function buildPurposeValues(useRight) {
        if (Object.prototype.hasOwnProperty.call(useRight, 'publishingPlatforms')) {
            return useRight.publishingPlatforms.map(ur => {
                if (Object.prototype.hasOwnProperty.call(useRight, 'platformValues') &&
                    Object.prototype.hasOwnProperty.call(useRight.platformValues, ur) &&
                    useRight.platformValues[ur] !== null
                ) {
                    return ur + ' (' + useRight.platformValues[ur] + ')';
                }
                return ur;
            });
        }

        return [];
    }

    return (
        <List>
            {loading && <CircularProgress />}
            {!loading && useRights.map((useRight, i) => (
                <ListItem key={useRight.id ?? i}>
                    {showListAvatar &&
                        <ListItemAvatar>
                            <Avatar>
                                <AssignmentIcon />
                            </Avatar>
                        </ListItemAvatar>
                    }
                    <ListItemText
                        primary={(
                            <>
                                <strong>{getPurposeTitle(useRight)}</strong>
                                {': '}
                                {[
                                    useRight.audioUseAllowed && <FormattedMessage id="label.audio" key="audio" />,
                                    useRight.imageUseAllowed && <FormattedMessage id="label.image" key="image" />,
                                    useRight.videoUseAllowed && <FormattedMessage id="label.video" key="video" />,
                                ].filter(v => v).reduce((acc, cur) => acc.length > 0 ? [...acc, ', ', cur] : [cur], [])}
                                {', '}
                                {useRight.expiresAt ?
                                    <>
                                        <FormattedMessage id="label.license_expires" />{' '}
                                        <FormattedDate value={useRight.expiresAt} format="short" />
                                    </>
                                    : <FormattedMessage id="label.never_expires" />
                                }
                            </>
                        )}
                        secondary={useRight.publishingPlatforms && useRight.publishingPlatforms.length > 0 && (
                            <>
                                {buildPurposeValues(useRight).join(', ')}
                            </>
                        )}
                    />
                    {!readOnly && typeof onRemove === 'function' &&
                        <ListItemSecondaryAction>
                            <IconButton
                                aria-label={formatMessage({ id: 'action.remove' })}
                                onClick={() => onRemove(useRight, i)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    }
                </ListItem>
            ))}
            {!loading && useRights.length === 0 && (
                <ListItem>
                    <ListItemText>
                        <FormattedMessage id="error.no_userights"/>
                    </ListItemText>
                </ListItem>
            )}
        </List>
    );
};

UseRightList.propTypes = {
    useRights: PropTypes.array,
    onRemove: PropTypes.func,
    readOnly: PropTypes.bool,
    showListAvatar: PropTypes.bool,
};
UseRightList.defaultProps = {
    useRights: [],
    onRemove: null,
    readOnly: true,
    showListAvatar: true,
};

export default UseRightList;
