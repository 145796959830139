import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';

const DeleteBookmarkDialog = ({ folderId, fileContainerId, cueId, name, onClose, onSuccess }) => {
    const [open, setOpen] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState(false);
    const handleClose = () => setOpen(false);
    let cancelToken;

    useEffect(() => {
        return () => {
            if (cancelToken) {
                cancelToken.cancel();
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async () => {
        setDeleting(true);
        cancelToken = CancelToken.source();

        try {
            await axios.delete(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/chapters/${cueId}`, {
                cancelToken: cancelToken.token,
            });
            onSuccess(cueId);
            handleClose();
        } catch (e) {
            if (!isCancel(e)) {
                setError(true);
                setDeleting(false);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onExited={onClose}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick={deleting}
        >
            <DialogTitle>
                {name}
            </DialogTitle>

            <DialogContent>
                {!error &&
                    <Typography>
                        <FormattedMessage id="prompt.delete_bookmark" />
                    </Typography>
                }
                {error &&
                    <Typography>
                        <FormattedMessage id="error.delete_bookmark_failed" />
                    </Typography>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    disabled={deleting || error}
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                    endIcon={deleting ? <CircularProgress /> : null}
                >
                    <FormattedMessage id="action.delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteBookmarkDialog.propTypes = {
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string.isRequired,
    cueId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

DeleteBookmarkDialog.defaultProps = {
    onClose: () => {},
    onSuccess: () => {},
};

export default DeleteBookmarkDialog;
