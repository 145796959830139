import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import AddedIcon from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import Select from '@material-ui/core/Select';

import { ROLE_TYPE } from './FolderAccess';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

const FolderAccessEmployeeDialog = ({ sensitive, unSelectable, ownerId, onClose, onSuccess }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { id: folderId } = useParams();
    const [ open, setOpen ] = useState(true);
    const handleClose = () => setOpen(false);
    const [ accessType, setAccessType ] = useState('');
    const [ selected, setSelected ] = useState([]);
    const [ itemsSaved, setItemsSaved ] = useState([]);
    const [ itemsFailed, setItemsFailed ] = useState([]);
    const { data: employees, loaded, loading, error } = useJsonRequest(
        sensitive ?
        `/api/media/folders/${folderId}/employees` :
        '/api/media/employees'
    );
    const [ saving, setSaving ] = useState(false);
    const sortByName = (a, b) => (a.name < b.name) ? -1 :  (b.name < a.name ? 1 : 0);

    useEffect(() => {
        if (!saving && selected.length > 0 && (itemsSaved.length + itemsFailed.length === selected.length)) {
            if (typeof onSuccess === 'function' && itemsSaved.length > 0) {
                onSuccess();
            }
            if (itemsFailed.length === 0) {
                setOpen(false);
            }
        }
    }, [itemsSaved, itemsFailed, saving, selected, onSuccess]);

    const handleSubmit = async () => {
        for (const item of selected) {
            setSaving(item.personId);
            try {
                await axios.post(`/api/media/folders/${folderId}/roles`, {
                    userId: item.personId,
                    role: accessType,
                    isExternalUser: false,
                    notify: false,
                    notifyMessage: null,
                });
                setItemsSaved(s => [...s, item.personId]);
            } catch {
                setItemsFailed(f => [...f, item.personId]);
            }
        }
        setSaving(false);
    };

    const handleTypeChange = event => setAccessType(event.target.value);

    const handleAddSelect = (event) => {
        const personId = event.target.value;
        const person = employees.find(e => e.personId === personId);
        if (person) {
            setSelected([ ...selected, person ].sort(sortByName));
        }
    };

    const handleRemoveSelect = (id) => {
        const index = selected.findIndex(s => s.personId === id);
        if (index > -1) {
            setSelected([
                ...selected.slice(0, index),
                ...selected.slice(index + 1)
            ]);
        }
    };

    const getStateIcon = (personId) => {
        if (saving === personId) {
            return <CircularProgress size={20} />;
        } else if (itemsSaved.includes(personId)) {
            return <AddedIcon />;
        } else if (itemsFailed.includes(personId)) {
            return <ErrorIcon aria-label={formatMessage({ id: 'error.save_failed' })} />;
        }

        return <PersonIcon />;
    };

    const canSave = accessType !== '' && selected.length > 0;
    const disableInput = loading || error || !!saving || itemsSaved.length > 0 || itemsFailed.length > 0;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onExited={onClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>
                <FormattedMessage id="label.access_internal" />
            </DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            fullWidth
                            required
                            disabled={disableInput}
                        >
                            <FormLabel component="legend">
                                <FormattedMessage id="label.access" />
                            </FormLabel>

                            <RadioGroup
                                row
                                onChange={handleTypeChange}
                                value={accessType}
                            >
                                <FormControlLabel
                                    control={<Radio />}
                                    label={<FormattedMessage id="label.access_read" />}
                                    value={ROLE_TYPE.READ}
                                />
                                <FormControlLabel
                                    control={<Radio />}
                                    label={<FormattedMessage id="label.access_write" />}
                                    value={ROLE_TYPE.WRITE}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {loading && <CircularProgress size={20} />}
                        {loaded &&
                            <FormControl
                                fullWidth
                                disabled={disableInput}
                            >
                                <InputLabel id="employees-select-label">
                                    <FormattedMessage id="action.choose_employee" />
                                </InputLabel>
                                <Select
                                    defaultValue=""
                                    fullWidth
                                    id="employees-select"
                                    label={<FormattedMessage id="label.owner" />}
                                    labelId="employees-select-label"
                                    onChange={handleAddSelect}
                                    value={''}
                                    inputProps={{
                                        'aria-labelledby': 'employees-select-label',
                                    }}

                                >
                                    {employees.sort(sortByName).map(({ name, personId }, idx) => (
                                        <MenuItem
                                            key={personId || idx}
                                            value={personId}
                                            disabled={
                                                personId === null ||
                                                selected.findIndex(e => e.personId === personId) > -1 ||
                                                personId === ownerId ||
                                                unSelectable.findIndex(us => us?.user?.data?.id === personId) > -1
                                            }
                                        >
                                            {name}
                                            {personId === null && (
                                                <>
                                                    {' ('}
                                                    <FormattedMessage id="info.portaluser_not_found" />
                                                    {')'}
                                                </>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {(selected || []).map(({ personId, name }) => {
                                const labelId = `selected-${personId}`;
                                return (
                                    <ListItem
                                        key={'employee-' + personId}
                                        dense
                                    >
                                        <ListItemIcon>
                                            {getStateIcon(personId)}
                                        </ListItemIcon>
                                        <ListItemText
                                            id={labelId}
                                            primary={name}
                                        />
                                        {!saving && itemsSaved.length === 0 && itemsFailed.length === 0 &&
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label={formatMessage({ id: 'action.remove' })}
                                                    onClick={() => handleRemoveSelect(personId)}
                                                    disabled={!!saving || itemsSaved.includes(personId)}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                {itemsFailed.length > 0 &&
                    <div className={classes.errorMsg}>
                        <FormattedMessage id="error.save_failed" />
                    </div>
                }
                {error &&
                    <div className={classes.errorMsg}>
                        <FormattedMessage id="error.loading_failed" />
                    </div>
                }
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={!!saving}
                >
                    <FormattedMessage id="action.close" />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!canSave || disableInput}
                >
                    <FormattedMessage id="action.give_access" />
                    {!!saving && <CircularProgress size={20} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

FolderAccessEmployeeDialog.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

FolderAccessEmployeeDialog.defaultProps = {
    onClose: () => {},
    onSuccess: () => {},
};

export default FolderAccessEmployeeDialog;
