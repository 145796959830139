import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { axiosWithAuth as axios, CancelToken, isCancel, useJsonRequest } from '../utils/axios';
import DataTable, { COLUMN_TYPES, COLUMN_STYLES } from './DataTable';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { getLabelIdForContentType } from '../utils/file';
import { makeStyles } from '@material-ui/core/styles';
import MoveFileDialog from './MoveFileDialog';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteMediaFileDialog from '../components/DeleteMediaFileDialog';

const useStyles = makeStyles(theme => ({
    actions: {
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
    todoHeading: {
        padding: theme.spacing(2),
        '& + &': {
            paddingTop: 0,
        }
    }
}));

const useDashboardFiles = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fileContainers, setFileContainers] = useState([]);

    useEffect(() => {
        if (loading) {
            let response;
            const cancelToken = CancelToken.source();

            (async () => {
                try {
                    setError(false);
                    response = await axios.get('/api/media/folders/my_primary_folder', {
                        cancelToken: cancelToken.token,
                    });
                    response = await axios.get(`/api/media/folders/${response.data.data.id}/file_containers`, {
                        cancelToken: cancelToken.token,
                    })

                    setFileContainers(response.data.data);
                    setLoading(false);
                } catch (e) {
                    if (!isCancel(e)) {
                        setError(true);
                        setLoading(false);
                    }
                }
            })();

            return () => cancelToken.cancel();
        }
    }, [loading]);

    return {
        error,
        fileContainers,
        loading,
        loaded: !loading && !error,
        reload: () => setLoading(true),
    };
};

const DashboardTodo = () => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const history = useHistory();
    const recordings = useDashboardFiles();
    const [dialog, setDialog] = useState(null);

    const handleMoveDialogOpen = (fileContainer) => {
        setDialog(
            <MoveFileDialog
                fileContainerId={fileContainer.id}
                folderId={fileContainer.folderId}
                onClose={handleDialogClose}
                onSubmit={handleMoveSubmit}
            />
        )
    };

    const handleDialogClose = () => setDialog(null);

    const handleMoveSubmit = (folderId, fileContainerId) => {
        handleDialogClose();
        history.push(`/folders/${folderId}/media/${fileContainerId}`);
    };

    const handleDeleteDialogOpen = (fileContainer) => {
        setDialog(
            <DeleteMediaFileDialog
                fileContainerId={fileContainer.id}
                folderId={fileContainer.folderId}
                name={fileContainer.name}
                onClose={handleDialogClose}
                onSubmit={handleDeleteSubmit}
            />
        )
    };

    const handleDeleteSubmit = () => recordings.reload();

    const handleSort = (a, b) => {
        return (a.createdAt < b.createdAt) ? -1 : (b.createdAt < a.createdAt ? 1 : 0);
    };

    const handleRefresh = () => {
        recordings.reload();
    };

    const getFileType = fileType => {
        const id = getLabelIdForContentType(fileType);
        return formatMessage({ id });
    };

    return (
        <Grid item xs={12}>
            {dialog}

            <Paper elevation={1} variant="outlined">
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs>
                        <Typography className={classes.todoHeading} component="h3" variant="h6">
                            <FormattedMessage
                                id="places.new_recordings"
                                values={{count: (recordings.fileContainers || []).length}}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.todoHeading}>
                            <IconButton
                                size="small"
                                disabled={recordings.loading}
                                onClick={handleRefresh}
                                title={formatMessage({id: 'action.refresh'})}
                                aria-label={formatMessage({id: 'action.refresh'})}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>

                <DataTable
                    columns={{
                        link: <FormattedMessage id="label.file_name" />,
                        fileType: <FormattedMessage id="label.file_type" />,
                        createdAt: <FormattedMessage id="label.uploaded" />,
                        createdBy: <FormattedMessage id="label.originator" />,
                        actions: <FormattedMessage id="label.actions" />,
                    }}
                    columnTypes={{
                        createdAt: COLUMN_TYPES.DATETIME,
                        actions: COLUMN_TYPES.RAW,
                    }}
                    columnStyles={{
                        actions: COLUMN_STYLES.FIT_CONTENT,
                    }}
                    rows={(recordings.fileContainers || []).sort(handleSort).map(file => ({
                        ...file,
                        link: (
                            <Link component={RouterLink} to={`/folders/${file.folderId}/media/${file.id}`}>
                                {file.name}
                            </Link>
                        ),
                        fileType: getFileType(file?.primaryFile?.data?.contentType),
                        createdBy: file?.primaryFile?.data?.createdBy?.data?.name,
                        actions: (
                            <span className={classes.actions}>
                                <Button
                                    onClick={() => handleMoveDialogOpen(file)}
                                    size="small"
                                    variant="outlined"
                                >
                                    <FormattedMessage id="action.move_to_folder" />
                                </Button>
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteDialogOpen(file)}
                                    aria-label={formatMessage({id: 'action.delete'})}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        )
                    }))}
                    loading={recordings.loading}
                    error={recordings.error}
                    sortable={['link', 'fileType', 'createdAt', 'createdBy']}
                    defaultSortColumn="createdAt"
                    defaultSortOrder="desc"
                    sortColumnMap={{ 'link': 'name' }}
                />
            </Paper>
        </Grid>
    );
};

export default DashboardTodo;
