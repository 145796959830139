import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconDetails from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

import { useJsonRequest } from '../utils/axios';
import { ParticipantsList } from './ConsentParticipants';

const useStyles = makeStyles(() => ({
    participantsTableContainer: {
        display: 'block',
    },
    participantsTable: {
        tableLayout: 'fixed',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    participantsCell: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'visible',
    },
    participantsContainer: {
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    clickable: {
        cursor: 'pointer',
    },
    participantsListContainer: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '95%',
        display: 'inline-block',
        textOverflow: 'ellipsis',
    },
    fullWidth: {
        maxWidth: '100%',
    },
}));

const DetailsDialog = ({ folderId, consent, onClose }) => {
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    const {data, loading, loaded, error} = useJsonRequest(`/api/media/folders/${folderId}/consents/${consent.id}/participants`);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="label.participants" />
            </DialogTitle>
            <DialogContent>
                {loading &&
                    <CircularProgress />
                }
                {loaded &&
                    <ParticipantsList
                        participants={data}
                        readOnly={true}
                        enablePdf={true}
                        folderId={folderId}
                        consent={consent}
                    />
                }
                {!loading && error &&
                    <FormattedMessage id="error.loading_failed" />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    <FormattedMessage id="action.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ConsentParticipantsView = ({ folderId, consent, disabled, children }) => {
    const classes = useStyles();
    const [dialog, setDialog] = useState(null);
    const handleCloseDialog = () => setDialog(null);

    const showDetails = () => {
        setDialog(
            <DetailsDialog folderId={folderId} consent={consent} onClose={handleCloseDialog} />
        );
    };

    return (
        <div className={classes.participantsTableContainer}>
            {dialog}
            <table cellPadding="0" className={classes.participantsTable}>
                <tbody>
                    <tr>
                        <td className={classes.participantsCell}>
                            <div
                                onClick={disabled ? null : showDetails}
                                className={clsx(
                                    classes.participantsContainer,
                                    {
                                        [classes.clickable]: !disabled,
                                    })
                                }
                            >
                                <div
                                    className={clsx(
                                        classes.participantsListContainer,
                                        {
                                            [classes.fullWidth]: disabled,
                                        }
                                    )}
                                >
                                    {children}
                                </div>
                                {!disabled && <IconDetails fontSize="small" />}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

ConsentParticipantsView.propTypes = {
    folderId: PropTypes.string.isRequired,
    consent: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

ConsentParticipantsView.defaultProps = {
    disabled: true,
};

export default ConsentParticipantsView;
