import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import { CONSENT_STATE } from './Consents';
import { CONSENT_TYPES, CONSENT_CONCERNING } from './ConsentFormFields';
import ConsentFormFields from './ConsentFormFields';

const ConsentCreateFromTemplate = ({ folderId, onClose, templateConsent, sensitive, onPersist }) => {
    const [open, setOpen] = useState(true);
    const [saving, setSaving] = useState(false);
    const [selected, setSelected] = useState(null);
    const [consentName, setConsentName] = useState('');
    const [participants, setParticipants] = useState([]);
    const { data, loading, loaded, error } = useJsonRequest(`/api/media/folders/${folderId}/consents/templates`);
    const { data: dataUseRights, loaded: loadedUseRight, load: loadUseRight } = useJsonRequest(null);
    const handleClose = () => setOpen(false);
    const consentType = CONSENT_TYPES.FOLDER;
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (selected && selected.id && selected.publishingUsage) {
            loadUseRight({url: `/api/media/folders/${folderId}/consents/${selected.id}/use_rights`});
        }
    }, [folderId, loadUseRight, selected]);

    useEffect(() => {
        if (loaded && data !== null) {
            setTemplates(data.filter(temp => temp.state !== CONSENT_STATE.DRAFT));
        }
    }, [data, loaded]);

    const handleSubmit = async event => {
        event.preventDefault();
        setSaving(true);

        try {
            const response = await axios.post(`/api/media/folders/${folderId}/consents`, {
                type: consentType,
                name: consentName,
                participants: participants,
                useRights: selected.publishingUsage ?
                    dataUseRights.map(u => ({
                        purpose: u.purpose,
                        expiresAt: u.expiresAt,
                        publishingPlatforms: u.publishingPlatforms,
                        audioUseAllowed: u.audioUseAllowed,
                        imageUseAllowed: u.imageUseAllowed,
                        videoUseAllowed: u.videoUseAllowed,
                    })) : [],
                participationRequired: true,
                forServiceUser: false,
                recordingUsage: selected.recordingUsage,
                publishingUsage: selected.publishingUsage,
                templateId: selected.id,
            });
            setSaving(false);
            onPersist(response.data.data);
            handleClose();
        } catch {
            setSaving(false);
        }
    };

    function findSelected(id) {
        return data.find(temp => temp.id === id);
    }

    const handleSelect = (event) => {
        if (selected === null || event.target.value !== selected.id) {
            setSelected(findSelected(event.target.value));
        }
    };

    if (templateConsent !== null && selected === null && loaded) {
        setSelected(findSelected(templateConsent.id));
    }

    const handleConsentNameChange = event => setConsentName(event.target.value);
    const handleAddParticipant = participant => setParticipants([...participants, participant]);
    const handleRemoveParticipant = (participant, index) => setParticipants([
        ...participants.slice(0, index),
        ...participants.slice(index + 1),
    ]);

    if (loaded && templates.length === 1 && selected === null) {
        setSelected(templates[0]);
    }

    const canSubmit =
        selected !== null &&
        consentName.length > 0 &&
        (sensitive || (!selected.publishingUsage || dataUseRights.length > 0)) &&
        (participants.length > 0) &&
        (sensitive || selected.recordingUsage || selected.publishingUsage);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="action.upload_consent" />
            </DialogTitle>
            <DialogContent>
                {data !== null && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl
                                component="fieldset"
                                required
                            >
                                <FormLabel id="template-label" component="legend">
                                    <FormattedMessage id="label.template_consent" />
                                </FormLabel>

                                <Select
                                    value={selected?.id ?? ''}
                                    disabled={!loaded}
                                    multiple={false}
                                    onChange={handleSelect}
                                    id="template-select"
                                    inputProps={{
                                        'aria-labelledby': 'template-label',
                                    }}
                                >
                                    {loaded && templates.map(temp =>
                                        <MenuItem key={temp.id} value={temp.id}>
                                            {temp.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {selected !== null &&
                                <ConsentFormFields
                                    sensitive={sensitive}
                                    consentName={consentName}
                                    setConsentName={handleConsentNameChange}
                                    participants={participants}
                                    setParticipants={setParticipants}
                                    onParticipantAdd={handleAddParticipant}
                                    onParticipantRemove={handleRemoveParticipant}
                                    useRights={selected.publishingUsage && loadedUseRight ? dataUseRights : []}
                                    readOnly={false}
                                    consentType={consentType}
                                    isLicense={false}
                                    forServiceUser={CONSENT_CONCERNING.OTHER}
                                    forRecordingUsage={selected.recordingUsage}
                                    forPublishingUsage={selected.publishingUsage}
                                />
                            }
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    <FormattedMessage id="action.cancel" />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={loading || error || saving || !canSubmit}
                    onClick={handleSubmit}
                >
                    <FormattedMessage id="action.create" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConsentCreateFromTemplate.propTypes = {
    folderId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    templateConsent: PropTypes.object,
};
ConsentCreateFromTemplate.defaultProps = {
    onClose: () => {},
    templateConsent: null,
};

export default ConsentCreateFromTemplate;
