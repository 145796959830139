import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth, AUTH_STRATEGY } from '../auth/AuthProvider';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'space-between',
    },
    logoContainer: {
        marginRight: theme.spacing(2),
    },
    itemBar: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    active: {
        boxShadow: `0 2px 0 0 ${theme.palette.primary.main}`,
        borderRadius: 0,
        color: theme.palette.primary.main,
        '@media(forced-colors: active)': {
            color: 'HighlightText',
            backgroundColor: 'Highlight',
        },
    },
    inactive: {
        color: 'inherit',
        '@media(forced-colors: active)': {
            color: 'ButtonText',
            backgroundColor: 'ButtonFace',
        },
    },
    border: {
        '@media(forced-colors: active)': {
            border: '1px solid ButtonText',
            borderRadius: theme.shape.borderRadius,
        },
    },
    sensitive: {
        color: theme.palette.statped.textSensitive,
    },
    nonSensitive: {
        color: theme.palette.statped.textOpen,
    },
}));

const Link = ({ isActive = false, borderEnabled = true, children, ...props }) => {
    const classes = useStyles();

    return (
        <Button
            className={
                clsx(
                    classes.item,
                    {
                        [classes.active]: isActive,
                        [classes.inactive]: !isActive,
                        [classes.border]: borderEnabled,
                    }
                )
            }
            component={RouterLink}
            {...props}
        >
            {children}
        </Button>
    );
};

const NavBar = ({ breadcrumbs = false }) => {
    const classes = useStyles();
    const { isAuthenticated, strategy } = useAuth();

    return (
        <AppBar position="static" color="transparent">
            <Container>
                <Toolbar className={classes.root} disableGutters>
                    <Logo />
                    {isAuthenticated && strategy === AUTH_STRATEGY.AUTH0 && <Navigation breadcrumbs={breadcrumbs} />}
                    <UserBar />
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const Logo = () => {
    const classes = useStyles();
    const { isAuthenticated } = useAuth();

    return (
        <div className={classes.logoContainer}>
            <Link
                color="inherit"
                component={RouterLink}
                to={isAuthenticated ? '/dashboard' : '/'}
                borderEnabled={false}
            >
                <img
                    src="/statped-logo.png"
                    alt="Statped"
                    width="122"
                    height="29"
                />
            </Link>
        </div>
    );
}

const Navigation = ({ breadcrumbs = false}) => {
    const classes = useStyles();
    const { user } = useAuth();

    let root = '';

    if (typeof breadcrumbs === 'object') {
        if (breadcrumbs.to) {
            root = breadcrumbs.to;
        } else if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0 && breadcrumbs[0].to) {
            root = breadcrumbs[0].to;
        }
    }

    return (
        <div>
            <div className={classes.itemBar}>
                <Link
                    to="/folders/sensitive"
                    endIcon={<FolderIcon className={classes.sensitive} />}
                    isActive={root === "/folders/sensitive"}
                >
                    <FormattedMessage id="places.my_users" />
                </Link>

                <Link
                    to="/folders"
                    endIcon={<FolderIcon className={classes.nonSensitive} />}
                    isActive={root === "/folders"}
                >
                    <FormattedMessage id="places.my_open_folders" />
                </Link>

                <Link
                    to="/search"
                    endIcon={<SearchIcon color="primary" />}
                    isActive={useRouteMatch('/search') !== null}
                >
                    <FormattedMessage id="action.search" />
                </Link>
            </div>
            {user.admin &&
                <div className={classes.itemBar}>
                    <Link
                        to="/admin/folders/sensitive"
                        endIcon={<FolderIcon className={classes.sensitive} />}
                        isActive={root === "/admin/folders/sensitive"}
                    >
                        <FormattedMessage id="places.all_users" />
                    </Link>

                    <Link
                        to="/admin/folders"
                        endIcon={<FolderIcon className={classes.nonSensitive} />}
                        isActive={root === "/admin/folders"}
                    >
                        <FormattedMessage id="places.all_open_folders" />
                    </Link>
                </div>
            }
        </div>
    );
};

const UserBar = () => {
    const { isAuthenticated, logout, user } = useAuth();
    const classes = useStyles();

    if (!isAuthenticated) {
        return null;
    }

    return (
        <div className={classes.itemBar}>
            {user && (
                <Typography className={classes.item} display="inline" variant="body2">
                    {user.name || 'Unknown name'}
                    {user.admin && (
                        <>
                            {' ('}
                                <FormattedMessage id="info.administrator" />
                            {')'}
                        </>
                    )}
                </Typography>
            )}
            <Button
                className={classes.item}
                color="inherit"
                onClick={logout}
                variant="outlined"
            >
                <FormattedMessage id="action.log_out" />
            </Button>
        </div>
    );
};

export default NavBar;
