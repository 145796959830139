import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import BirthNumberField from './BirthNumberField';
import { ROLE_TYPE } from './FolderAccess';
import { axiosWithAuth as axios } from '../utils/axios';
import KrrCheck from './KrrCheck';
import { MessageTransportStatus } from './MessageTransportStatus';

const useStyles = makeStyles(theme => ({
    feedbackWrapper: {
        marginRight: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flex: '1 1 100%',
    },
    feedbackLine: {
        flex: '1 1 100%',
    },
    buttonWrapper: {
        display: 'flex',
        flex: '0 0 auto',
    },
    leftPadding: {
        paddingLeft: theme.spacing(1),
    },
}));

export const FileAccessExternalReadDialog = ({ onClose, onSuccess }) => {
    const { folderId, id: fileContainerId } = useParams();

    const handleSubmit = async ({ name, birthNumber }) => {
        return axios.post(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/roles`, {
            name: name,
            birthNumber: birthNumber,
            role: ROLE_TYPE.READ,
            isExternalUser: true,
        });
    };

    return (
        <ExternalAccessDialog
            onClose={onClose}
            onSuccess={onSuccess}
            onSubmit={handleSubmit}
            title={<FormattedMessage id="action.give_external_access_to_file" />}
            submitButtonText={<FormattedMessage id="action.give_read_access" />}
        />
    );
};

export const FolderAccessExternalUploadDialog = ({ onClose, onSuccess }) => {
    const { id: folderId } = useParams();

    const handleSubmit = async ({ name, birthNumber }) => {
        return axios.post(`/api/media/folders/${folderId}/roles`, {
            name: name,
            birthNumber: birthNumber,
            role: ROLE_TYPE.UPLOAD,
            isExternalUser: true,
        });
    };

    return (
        <ExternalAccessDialog
            onClose={onClose}
            onSuccess={onSuccess}
            onSubmit={handleSubmit}
            title={<FormattedMessage id="action.let_an_external_upload_a_file" />}
            submitButtonText={<FormattedMessage id="action.give_upload_access" />}
        />
    );
};

const ExternalAccessDialog = ({ onClose, onSuccess, onSubmit, title, submitButtonText }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [user, setUser] = useState('');
    const [birthNumberError, setBirthNumberError] = useState(false);
    const [birthNumber, setBirthNumber] = useState('');
    const [saving, setSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [messageTransport, setMessageTransport] = useState();
    const [error, setError] = useState(false);
    const [errorCode, setErrorCode] = useState(0);
    const [pollUrl, setPollUrl] = useState(null);

    const handleClose = () => setOpen(false);
    const handleUserChange = event => setUser(event.target.value);
    const handleBirthNumberError = error => setBirthNumberError(error);
    const handleBirthNumberChange = value => setBirthNumber(value);
    const handleSubmit = async () => {
        if (canSave) {
            setSaving(true);
            setError(false);
            setErrorCode(0);
            try {
                const result = await onSubmit({
                    name: user,
                    birthNumber: birthNumber,
                    role: ROLE_TYPE.READ,
                    isExternalUser: true,
                });
                setSaving(false);
                setIsSaved(true);
                setMessageTransport(result.data.messageState);
                onSuccess();
                if (result.data.pollUrl) {
                    setPollUrl(result.data.pollUrl);
                }
            } catch (e) {
                setErrorCode(e.response.status);
                setError(true);
                setSaving(false);
            }
        }
    }

    const showStatus = () => {
        if (error) {
            let msgId;

            switch (errorCode) {
                case 409:
                    msgId = 'error.user_access_exists';
                    break;
                default:
                    msgId = 'error.save_failed';
            }
            return (
                <div className={classes.feedbackWrapper}>
                    <FormattedMessage id={msgId} />
                </div>
            );
        } else if (typeof messageTransport !== 'undefined') {
            return (
                <div className={classes.feedbackWrapper}>
                    <FormattedMessage id="info.access_created" />
                    <div className={classes.feedbackLine}>
                        <MessageTransportStatus
                            messageTransport={messageTransport}
                            pollUrl={pollUrl}
                        />
                    </div>
                </div>
            );
        } else if (isSaved) {
            return (
                <div className={classes.feedbackWrapper}>
                    <FormattedMessage id="info.access_created" />
                </div>
            );
        } else if (!saving) {
            return (
                <KrrCheck
                    birthNumber={birthNumber}
                    birthNumberError={birthNumberError}
                    className={classes.feedbackWrapper}
                />
            );
        }

        return null;
    };

    const canSave = (
        !isSaved &&
        !saving &&
        typeof messageTransport === 'undefined' &&
        user.length > 0 &&
        birthNumber !== '' &&
        !birthNumberError
    );

    const canEdit = (
        !saving &&
        !isSaved &&
        typeof messageTransport === 'undefined'
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onExited={onClose}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>
                {title}
            </DialogTitle>

            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={<FormattedMessage id="label.name" />}
                            variant="outlined"
                            value={user}
                            onChange={handleUserChange}
                            size="small"
                            disabled={!canEdit}
                            id="external_name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BirthNumberField
                            fullWidth
                            label={<FormattedMessage id="label.birth_number" />}
                            onChange={handleBirthNumberChange}
                            onErrorChange={handleBirthNumberError}
                            variant="outlined"
                            value={birthNumber}
                            size="small"
                            disabled={!canEdit}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                {showStatus()}
                <div className={classes.buttonWrapper}>
                    <Button variant="outlined" onClick={onClose} disabled={saving}>
                        {isSaved ?
                            <FormattedMessage id="action.close" /> :
                            <FormattedMessage id="action.cancel" />
                        }
                    </Button>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!canSave || saving}
                    >
                        {submitButtonText}
                        {saving && <CircularProgress size={20} />}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

FileAccessExternalReadDialog.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

FileAccessExternalReadDialog.defaultProps = {
    onClose: () => {},
    onSuccess: () => {},
};

FolderAccessExternalUploadDialog.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

FolderAccessExternalUploadDialog.defaultProps = {
    onClose: () => {},
    onSuccess: () => {},
};
