import React, { Fragment, useState, useImperativeHandle, forwardRef, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useJsonRequest } from '../utils/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import UploadFileIcon from '@material-ui/icons/Publish';
import DataTable, { COLUMN_STYLES, COLUMN_TYPES } from './DataTable';
import DeleteMediaFileDialog from '../components/DeleteMediaFileDialog';
import { FileUploadDialogInternal } from './FileUploadDialog';
import { getLabelIdForContentType } from '../utils/file';
import MoveFileDialog from './MoveFileDialog';
import { NOTIFICATION_CATEGORY } from './DashboardNotifications';
import { useFolder } from '../routes/Folder';

const useStyles = makeStyles(theme => ({
    rowActions: {
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const FolderFiles = forwardRef(({ folderId, sensitive }, ref) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { data, loading, error, reload } = useJsonRequest(`/api/media/folders/${folderId}/file_containers`);
    const [dialog, setDialog] = useState(null);
    const location = useLocation();
    const timeoutHandle = useRef(null);
    const history = useHistory();
    const { isAdmin, ownerAccess, writeAccess } = useFolder();

    useImperativeHandle(ref, () => ({
        upload: handleAddFileOpen,
    }));

    const clearHighlight = useCallback(() => {
        if (timeoutHandle.current !== null) {
            clearTimeout(timeoutHandle.current);
            timeoutHandle.current = null;
            history.replace({
                state: {...location.state, highlight: undefined},
            });
        }
    }, [history, location.state]);

    useEffect(() => {
        const section = location?.state?.highlight?.section;
        if (section === NOTIFICATION_CATEGORY.FILE) {
            timeoutHandle.current = setTimeout(clearHighlight, 10000);
            return () => {clearHighlight()};
        } else if (timeoutHandle.current !== null) {
            clearTimeout(timeoutHandle.current);
            timeoutHandle.current = null;
        }
    }, [clearHighlight, location.state]);

    const handleMoveDialogOpen = (fileContainer) => {
        setDialog(
            <MoveFileDialog
                fileContainerId={fileContainer.id}
                folderId={fileContainer.folderId}
                onClose={handleDialogClose}
                onSubmit={handleMoveSubmit}
            />
        )
    };

    const handleDialogClose = () => setDialog(null);

    const handleMoveSubmit = () => {
        handleDialogClose();
        reload();
    };

    const handleDeleteDialogOpen = (fileContainer) => {
        setDialog(
            <DeleteMediaFileDialog
                fileContainerId={fileContainer.id}
                folderId={fileContainer.folderId}
                name={fileContainer.name}
                onClose={handleDialogClose}
                onSubmit={handleDeleteSubmit}
            />
        )
    };

    const handleDeleteSubmit = () => reload();

    const handleAddFileOpen = () => setDialog(
        <FileUploadDialogInternal
            folderId={folderId}
            onClose={handleDialogClose}
            onSuccess={reload}
            waitForFileReady={true}
        />
    );

    const getFileType = fileType => {
        const id = getLabelIdForContentType(fileType);
        return formatMessage({ id });
    }

    return (
        <Fragment>
            {dialog}
            <DataTable
                loading={loading}
                error={error}
                columns={{
                    link: <FormattedMessage id="label.file_name" />,
                    fileType: <FormattedMessage id="label.file_type" />,
                    updatedAt: <FormattedMessage id="label.edited_date" />,
                    originator: <FormattedMessage id="label.originator" />,
                    externalUploaded: <FormattedMessage id="label.external_uploader" />,
                    actions: <FormattedMessage id="label.actions" />,
                }}
                columnTypes={{
                    updatedAt: COLUMN_TYPES.DATETIME,
                    externalUploaded: COLUMN_TYPES.TEXT,
                    actions: COLUMN_TYPES.RAW,
                }}
                columnStyles={{
                    fileType: COLUMN_STYLES.FIT_CONTENT,
                    updatedAt: COLUMN_STYLES.FIT_CONTENT,
                    externalUploaded: COLUMN_STYLES.FIT_CONTENT,
                    actions: COLUMN_STYLES.ALIGN_RIGHT,
                }}
                rows={(data || []).map(file => ({
                    ...file,
                    link: (
                        <Link
                            component={RouterLink}
                            to={(isAdmin ? '/admin' : '') + `/folders/${file.folderId}/media/${file.id}`}
                        >
                            {file.name}
                        </Link>
                    ),
                    fileType: getFileType(file?.primaryFile?.data?.contentType),
                    originator: file?.primaryFile?.data?.createdBy?.data?.name,
                    actions: (
                        <span className={classes.rowActions}>
                            {!sensitive &&
                                <Button
                                    onClick={isAdmin || writeAccess ? () => handleMoveDialogOpen(file) : null}
                                    size="small"
                                    variant="outlined"
                                    disabled={isAdmin ? false : !writeAccess}
                                >
                                    <FormattedMessage id="action.move_to_folder" />
                                </Button>
                            }
                            <IconButton
                                size="small"
                                onClick={isAdmin || ownerAccess ? () => handleDeleteDialogOpen(file) : null}
                                aria-label={formatMessage({ id: 'action.remove' })}
                                disabled={isAdmin ? false : !ownerAccess}
                                title={formatMessage({ id: 'action.remove' })}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    ),
                    externalUploaded: file?.primaryFile?.data?.externalUploaded ?
                        formatMessage({ id: 'text.yes' }) :
                        formatMessage({ id: 'text.no' }),
                }))}
                highlight={location?.state?.highlight?.id}
                sortable={['link', 'fileType', 'updatedAt', 'originator', 'externalUploaded']}
                defaultSortOrder="asc"
                defaultSortColumn="link"
                sortColumnMap={{ 'link': 'name' }}
            />
        </Fragment>
    );
});

export const UploadButton = ({onClick, disabled}) => (
    <Button
        onClick={onClick}
        startIcon={<UploadFileIcon />}
        variant="outlined"
        disabled={disabled}
    >
        <FormattedMessage id="action.upload_media" />
    </Button>
);

FolderFiles.propTypes = {
    folderId: PropTypes.string.isRequired,
};

export default FolderFiles;
