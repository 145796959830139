import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconVideo from '@material-ui/icons/PlayCircleOutline';
import IconImage from '@material-ui/icons/Image';
import IconAudio from '@material-ui/icons/Audiotrack';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useJsonRequest } from '../utils/axios';
import { CONSENT_TYPES } from './ConsentFormFields';
import { fileIsOfType, FILE_TYPE } from '../utils/file';
import BaseVideoPlayer from '../components/VideoPlayer';
import BaseImageViewer from '../components/ImageViewer';
import Portal from './Portal';
import { useStream } from '../utils/stream';

const useStyles = makeStyles(theme => ({
    fileItem: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fileTitle: {
        marginLeft: theme.spacing(1),
    },
    info: {
        fontStyle: 'italic',
    },
    borderContainer: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
    },
}));

const getStreamUrl = (consentId, fileContainerId, fileId) =>
    `/api/media/consents/mine/${consentId}/file_containers/${fileContainerId}/files/${fileId}/streaming_request`;

const VideoPlayer = ({ consentId, fileContainerId, fileId }) => (
    <BaseVideoPlayer
        stream={useStream(getStreamUrl(consentId, fileContainerId, fileId))}
        autoPlay={true}
    />
);

const ImageViewer = ({ consentId, fileContainerId, fileId }) => (
    <BaseImageViewer
        stream={useStream(getStreamUrl(consentId, fileContainerId, fileId))}
    />
);

const ConsentFile = ({ fileContainer, onSelect }) => {
    const classes = useStyles();
    const primaryFile = fileContainer?.primaryFile?.data;
    const isAudio = primaryFile ? fileIsOfType(primaryFile, FILE_TYPE.AUDIO) : false;
    const isImage = primaryFile ? fileIsOfType(primaryFile, FILE_TYPE.IMAGE) : false;
    const isVideo = primaryFile ? fileIsOfType(primaryFile, FILE_TYPE.VIDEO) : false;

    return (
        <Link
            component="button"
            variant="body2"
            onClick={() => onSelect(fileContainer, primaryFile)}
            className={classes.fileItem}
        >
            {isAudio && <IconAudio />}
            {isImage && <IconImage />}
            {isVideo && <IconVideo />}
            <span className={classes.fileTitle}>
                {fileContainer.name}
            </span>
        </Link>
    );
};

export const ConsentFileList = ({ consent, previewElementId }) => {
    const classes = useStyles();
    const { data, setData, load, error } = useJsonRequest(null);
    const [ selectedFile, setSelectedFile ] = useState(null);
    const [ selectedContainer, setSelectedContainer ] = useState(null);
    const isAudio = selectedFile ? fileIsOfType(selectedFile, FILE_TYPE.AUDIO) : false;
    const isImage = selectedFile ? fileIsOfType(selectedFile, FILE_TYPE.IMAGE) : false;
    const isVideo = selectedFile ? fileIsOfType(selectedFile, FILE_TYPE.VIDEO) : false;

    useEffect(() => {
        if (consent.type === CONSENT_TYPES.FILE) {
            load({url: `/api/media/consents/mine/${consent.id}/file_containers`});
        } else {
            setData([]);
        }
    }, [consent]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnSelect = (fileContainer, file) => {
        setSelectedContainer(fileContainer);
        setSelectedFile(file);
    };

    function showPreview() {
        if (selectedFile) {
            const title = (
                <Typography variant="h5" component="div">
                    {selectedFile.name}
                </Typography>
            );

            if (isVideo || isAudio) {
                return (
                    <>
                        {title}
                        <VideoPlayer
                            consentId={consent.id}
                            fileContainerId={selectedContainer.id}
                            fileId={selectedFile.id}
                        />
                    </>
                );
            } else if (isImage) {
                return (
                    <>
                        {title}
                        <ImageViewer
                            consentId={consent.id}
                            fileContainerId={selectedContainer.id}
                            fileId={selectedFile.id}
                        />
                    </>
                );
            }
        }

        return null;
    }

    return (
        <div>
            {error &&
                <div className={classes.info}>
                    <FormattedMessage id="error.loading_failed" />
                </div>
            }
            <Portal elementId={previewElementId}>
                {showPreview()}
            </Portal>

            {data !== null && data.map(item => {
                return (
                    <ConsentFile
                        key={item.fileContainerId}
                        onSelect={handleOnSelect}
                        fileContainer={item.fileContainer?.data}
                    />
                );
            })}
            {data !== null && data.length === 0 &&
                <div className={classes.info}>
                    <FormattedMessage id="notice.no_consent_files" />
                </div>
            }
        </div>
    );
};

ConsentFileList.propTypes = {
    consent: PropTypes.object.isRequired,
    previewElementId: PropTypes.string.isRequired,
};

ConsentFileList.defaultProps = {};

export const ConsentListConnectedFiles = ({fileContainers, loading}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.borderContainer}>
                <Typography variant="h6">
                    <FormattedMessage id="label.attached_files" />
                </Typography>
                {loading && <CircularProgress />}
                {!loading &&
                    <List aria-label={formatMessage({ id: 'label.files' })}>
                        {fileContainers && fileContainers.map(f =>
                            <ListItem key={f.fileContainerId}>
                                <ListItemAvatar>
                                    <FileIcon />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={f.fileContainer.data.name}
                                />
                            </ListItem>
                        )}
                        {fileContainers && fileContainers.length === 0 &&
                            <Typography>
                                <FormattedMessage id="notice.empty_data" />
                            </Typography>
                        }
                    </List>
                }
            </Grid>
        </Grid>
    );
};

ConsentListConnectedFiles.propTypes = {
    fileContainers: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

ConsentListConnectedFiles.defaultProps = {
    loading: false,
};
