import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useJsonRequest } from '../utils/axios';
import DataTable, { COLUMN_TYPES } from './DataTable';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import FolderIcon from '@material-ui/icons/Folder';

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(2),
    },
    sensitive: {
        backgroundColor: theme.palette.statped.backgroundSensitive,
    },
    open: {
        backgroundColor: theme.palette.statped.backgroundOpen,
    },
    iconSpace: {
        marginRight: '.5em',
    },
}));

const DashboardFolders = ({ sensitive }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { data, loading, error, reload } = useJsonRequest(sensitive
        ? '/api/media/folders/my_folders/sensitive'
        : '/api/media/folders/my_folders/non_sensitive'
    );

    const loadData = () => {
        reload();
    };

    return (
        <Grid item xs={12} md={6}>
            {(loading || error !== false || data) && (
                <Paper elevation={1} variant="outlined">
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className={sensitive ? classes.sensitive : classes.open}
                    >
                        <Grid item xs>
                            <Typography className={classes.heading} component="h2" variant="h5">
                                <FolderIcon className={classes.iconSpace} />
                                {sensitive && <FormattedMessage id="places.my_users_count" values={{count: (data || []).length}} />}
                                {!sensitive && <FormattedMessage id="places.my_non_sensitive_folders_count" values={{count: (data || []).length}} />}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.heading}>
                                <IconButton
                                    size="small"
                                    disabled={loading}
                                    onClick={loadData}
                                    title={formatMessage({id: 'action.refresh'})}
                                    aria-label={formatMessage({id: 'action.refresh'})}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                    </Grid>

                    <DataTable
                        columns={{
                            link: <FormattedMessage id="label.name" />,
                            updatedAt: <FormattedMessage id="label.updated" />,
                        }}
                        loading={loading}
                        rows={(data || []).map(row => ({
                            id: row.id,
                            name: row.name,
                            link: (
                                <Link component={RouterLink} to={`/folders/${row.id}`}>
                                    {row.name}
                                </Link>
                            ),
                            updatedAt: row.updatedAt,
                        }))}
                        columnTypes={{
                            link: COLUMN_TYPES.TEXT,
                            updatedAt: COLUMN_TYPES.DATETIME,
                        }}
                        error={error}
                        sortable={true}
                        defaultSortColumn="updatedAt"
                        defaultSortOrder="desc"
                        sortColumnMap={{ 'link': 'name' }}
                    />
                </Paper>
            )}
        </Grid>
    );
};

DashboardFolders.propTypes = {
    sensitive: PropTypes.bool,
};

DashboardFolders.defaultProps = {
    sensitive: false,
};

export default DashboardFolders;
