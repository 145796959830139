import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { axiosWithAuth as axios } from '../utils/axios';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConsentFormFields, { CONSENT_TYPES, CONSENT_CONCERNING } from './ConsentFormFields';

const ConsentCreateDialog = ({ folderId, onClose, onPersist, sensitive, isLicense, serviceUserName }) => {
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [useRights, setUseRights] = useState([]);
    const [forServiceUser, setForServiceUser] = useState(null);
    const [consentName, setConsentName] = useState('');
    const [consentType, setConsentType] = useState(null);
    const [forRecordingUsage, setForRecordingUsage] = useState(false);
    const [forPublishingUsage, setForPublishingUsage] = useState(false);
    const isTemplate = consentType === CONSENT_TYPES.TEMPLATE;

    const handleSetForServiceUser = consentType => {
        if (sensitive) {
            if (consentType === CONSENT_TYPES.FOLDER) {
                setConsentName(serviceUserName || '');
                setForServiceUser(CONSENT_CONCERNING.FABRIS)
            } else {
                setConsentName('');
                setForServiceUser(CONSENT_CONCERNING.OTHER)
            }
        }
    };
    const handleConsentNameChange = event => setConsentName(event.target.value);
    const handleConsentTypeChange = event => {
        setConsentType(event.target.value);
        handleSetForServiceUser(event.target.value);
    };
    const handleConsentRecordingUsageChange = event => setForRecordingUsage(event.target.checked);
    const handleConsentPublishingUsageChange = event => {
        if (!event.target.checked) {
            setUseRights([]);
        }
        setForPublishingUsage(event.target.checked);
    };

    const handleClose = () => setOpen(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        const isServiceUser = isLicense ? false : (forServiceUser === CONSENT_CONCERNING.FABRIS);
        try {
            const response = await axios.post(`/api/media/folders/${folderId}/consents`, {
                type: consentType,
                name: isServiceUser ? serviceUserName : consentName,
                participants: (isLicense || isTemplate) ? [] : participants,
                useRights: useRights.map(({ purposeTitle, ...rest }) => rest),
                participationRequired: !(isLicense || isTemplate),
                forServiceUser: isServiceUser,
                recordingUsage: forRecordingUsage,
                publishingUsage: forPublishingUsage,
            });
            setLoading(false);
            onPersist(response.data.data);
            handleClose();
        } catch {
            setLoading(false);
        }
    };

    const handleAddParticipant = participant => setParticipants([...participants, participant]);

    const handleRemoveParticipant = (participant, index) => setParticipants([
        ...participants.slice(0, index),
        ...participants.slice(index + 1),
    ]);

    const handleAddUseRight = useRight => setUseRights([...useRights, useRight]);

    const handleRemoveUseRight = (useRight, index) => setUseRights([
        ...useRights.slice(0, index),
        ...useRights.slice(index + 1),
    ]);

    const canSubmit = typeof consentName === 'string' && consentName.length > 0 &&
        consentType !== null &&
        (sensitive || (!forPublishingUsage || useRights.length > 0)) &&
        (isLicense || isTemplate || participants.length > 0) &&
        (isLicense || sensitive || forRecordingUsage || forPublishingUsage);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                {isLicense ?
                    <FormattedMessage id="action.new_license" /> :
                    ( sensitive ?
                        <FormattedMessage id="label.new_consent_sensitive" /> :
                        <FormattedMessage id="label.new_consent_open" />
                    )
                }
            </DialogTitle>
            {loading && (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            )}
            {!loading && (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <ConsentFormFields
                            sensitive={sensitive}
                            consentName={consentName}
                            setConsentName={handleConsentNameChange}
                            participants={participants}
                            setParticipants={setParticipants}
                            onParticipantAdd={handleAddParticipant}
                            onParticipantRemove={handleRemoveParticipant}
                            useRights={useRights}
                            onUseRightAdd={handleAddUseRight}
                            onUseRightRemove={handleRemoveUseRight}
                            readOnly={false}
                            consentType={consentType}
                            setConsentType={handleConsentTypeChange}
                            isLicense={isLicense}
                            forServiceUser={forServiceUser}
                            forRecordingUsage={forRecordingUsage}
                            setForRecordingUsage={handleConsentRecordingUsageChange}
                            forPublishingUsage={forPublishingUsage}
                            setForPublishingUsage={handleConsentPublishingUsageChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} variant="outlined">
                            <FormattedMessage id="action.cancel" />
                        </Button>
                        <Button color="primary" type="submit" variant="contained" disabled={!canSubmit}>
                            <FormattedMessage id="action.create" />
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

ConsentCreateDialog.propTypes = {
    folderId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onPersist: PropTypes.func,
    sensitive: PropTypes.bool.isRequired,
    isLicense: PropTypes.bool,
    serviceUserName: PropTypes.string,
};

ConsentCreateDialog.defaultProps = {
    onClose: (() => {}),
    onPersist: (() => {}),
    isLicense: false,
    serviceUserName: null,
};

export default ConsentCreateDialog;
