import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import DataTable, {COLUMN_TYPES, COLUMN_STYLES} from './DataTable';

const useStyles = makeStyles(theme => ({
    heading: {
        padding: theme.spacing(2),
    },
    weekLabel: {
        position: 'relative',
    },
    buttonContainer: {
        display: 'flex',
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export const NOTIFICATION_CATEGORY = {
    CONSENT: 'consent',
    LICENSE: 'license',
    FILE: 'file',
};

const DashboardNotifications = () => {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [ showRead, setShowRead ] = useState(false);
    const [numWeeks, setNumWeeks] = useState(4);
    const { loading, setData, reload, error, data, load, pagination } = useJsonRequest('api/media/notifications/unread?w=' + numWeeks);

    useEffect(() => {
        load({ url: `api/media/notifications/${showRead ? 'read' : 'unread'}?w=${numWeeks}`});
    }, [showRead, numWeeks]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = async () => {
        reload();
    };

    const toggleRead = async () => {
        setNumWeeks(4);
        setShowRead(!showRead);
    };

    const handleToggleReadState = async (id, setAsRead) => {
        try {
            const url = setAsRead ? `/api/media/notifications/${id}/read` : `/api/media/notifications/${id}/unread`;
            const response = await axios.patch(url);
            setData(data.map(item => item.id === id ? response.data.data : item));
        } catch (e) {
            console.log(e);
        }
    };

    const handleGotoFolder = async (item) => {
        if (item.readAt === null) {
            await handleToggleReadState(item.id, true);
        }
        history.push({
            pathname: `/folders/${item.eventData.folderId}`,
            state: {
                highlight: {
                    section: item.category,
                    id: item.itemId,
                },
            },
        });
    };

    const handleNumWeeksChange = (e) => {
        setNumWeeks(e.target.value);
    };

    function getCategoryLabel(item) {
        const labelId = `label.${item.category}`;
        const label = formatMessage({ id: labelId });

        return (label !== labelId) ? label : `(${item.category})`;
    }

    function getEventLabel(item) {
        let labelId = `label.${item.category}.${item.event}`;
        let label = '';

        if (labelId !== '') {
            label = formatMessage({ id: labelId });
        }

        return (label !== labelId && label !== '') ? label : `(${item.event})`;
    }

    function getActionButtons(item) {
        if (item) {
            return (
                <span className={classes.buttonContainer}>
                    {item.readAt === null &&
                        <Button
                            key="markRead"
                            size="small"
                            onClick={() => handleToggleReadState(item.id, true)}
                            variant="outlined"
                        >
                            <FormattedMessage id="action.mark_read" />
                        </Button>
                    }
                    {item.readAt !== null &&
                        <Button
                            key="markUnread"
                            size="small"
                            onClick={() => handleToggleReadState(item.id, false)}
                            variant="outlined"
                        >
                            <FormattedMessage id="action.mark_unread" />
                        </Button>
                    }
                    <Button
                        key="goto"
                        size="small"
                        onClick={() => handleGotoFolder(item)}
                        variant="outlined"
                    >
                        <FormattedMessage id="action.show_in_folder" />
                    </Button>
                </span>
            );
        }

        return null;
    }

    const columns = {
        eventAt: <FormattedMessage id="label.date" />,
        category: <FormattedMessage id="label.category" />,
        event: <FormattedMessage id="label.event" />,
        title: <FormattedMessage id="label.title" />,
        readAt: <FormattedMessage id="label.read" />,
        actions: <FormattedMessage id="label.actions" />,
    };

    if (!showRead) {
        delete columns.readAt;
    }

    function getWeekFilter() {
        return (
            <FormControl>
                <InputLabel id="week-filter-label" className={classes.weekLabel}>
                    <FormattedMessage id="info.weeks_display" />
                </InputLabel>
                <Select
                    labelId="week-filter-label"
                    id="week-filter"
                    value={numWeeks}
                    onChange={handleNumWeeksChange}
                    inputProps={{
                        'aria-labelledby': 'week-filter-label',
                    }}
                >
                    <MenuItem value={2}><FormattedMessage id="label.n_weeks" values={{ weeks: 2 }} /></MenuItem>
                    <MenuItem value={4}><FormattedMessage id="label.n_weeks" values={{ weeks: 4 }} /></MenuItem>
                    <MenuItem value={12}><FormattedMessage id="label.n_weeks" values={{ weeks: 12 }} /></MenuItem>
                    <MenuItem value={26}><FormattedMessage id="label.n_weeks" values={{ weeks: 26 }} /></MenuItem>
                    <MenuItem value={52}><FormattedMessage id="label.n_weeks" values={{ weeks: 52 }} /></MenuItem>
                    <MenuItem value={0}><em><FormattedMessage id="label.all" /></em></MenuItem>
                </Select>
            </FormControl>
        );
    }

    function showCount(pagination) {
        if (pagination) {
            if (pagination.total > pagination.count) {
                return (
                    <>
                        {pagination.count}
                        <Typography variant="body1" component="span">
                            <FormattedMessage id="label.count_of_total" values={pagination}/>
                        </Typography>
                    </>
                );
            } else {
                return pagination.count;
            }
        }

        return '';
    }

    return (
        <Grid item xs={12}>
            <Paper elevation={1} variant="outlined">
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid container item xs alignItems="center">
                        <Grid item>
                            <Typography className={classes.heading} component="h2" variant="h5">
                                <FormattedMessage
                                    id={showRead ? "places.read_notifications" : "places.unread_notifications"}
                                    values={{
                                        count: showCount(pagination),
                                    }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.heading} component="div">
                            {getWeekFilter()}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.heading}>
                            <Button
                                size="small"
                                disabled={loading}
                                onClick={toggleRead}
                                variant="outlined"
                            >
                                {showRead ?
                                    <FormattedMessage id="action.view_unread" /> :
                                    <FormattedMessage id="action.view_read" />
                                }
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.heading}>
                            <IconButton
                                size="small"
                                disabled={loading}
                                onClick={loadData}
                                title={formatMessage({id: 'action.refresh'})}
                                aria-label={formatMessage({id: 'action.refresh'})}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>

                <DataTable
                    loading={loading}
                    error={error}
                    columns={columns}
                    columnTypes={{
                        eventAt: COLUMN_TYPES.DATE,
                        readAt: COLUMN_TYPES.DATE,
                        actions: COLUMN_TYPES.RAW,
                    }}
                    columnStyles={{
                        actions: COLUMN_STYLES.FIT_CONTENT,
                    }}
                    rows={(data || []).map(item => ({
                        id: item.id,
                        eventAt: item.eventAt,
                        title: item.title,
                        category: getCategoryLabel(item),
                        event: getEventLabel(item),
                        readAt: item.readAt,
                        actions: getActionButtons(item),
                    }))}
                    defaultSortColumn="eventAt"
                    defaultSortOrder="desc"
                />
            </Paper>
        </Grid>
    );
};

export default DashboardNotifications;
