import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { axiosWithAuth as axios } from '../utils/axios';
import ConsentAnswerForm from './ConsentAnswerForm';
import { CONSENT_STATE } from './Consents';
import FileSize from './FileSize';
import { LinearProgressWithPercentage } from './FileUploadDialog';

const acceptedFileTypes = [
    '.gif',
    '.jpg',
    '.jpeg',
    '.jfif',
    '.pjpeg',
    '.pjp',
    '.png',
    '.webp',
];
const acceptedMimeTypes = [
    'application/pdf',
];

const ConsentAnswerUpload = ({ folderId, consent, participant, onClose, onSuccess }) => {
    const [open, setOpen] = useState(true);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(null);
    const [uploadError, setUploadError] = useState(false);
    const [answer, setAnswer] = useState(null);
    const [uploadDone, setUploadDone] = useState(false);
    const [saving, setSaving] = useState(null);
    const fileIsOptional = [CONSENT_STATE.APPROVED, CONSENT_STATE.WITHDRAWN].indexOf(participant.answer?.data?.answer) !== -1;

    const handleClose = () => setOpen(false);
    const handleChangeAnswer = value => setAnswer(value);

    const handleUploadProgress = (e) => {
        setUploadProgress(Math.round(100 * e.loaded / e.total));
    };

    const handleFileChange = async event => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        setUploadProgress(null);
    };

    function uploadStatus() {
        if (file && typeof uploadProgress === 'number' && !uploadError) {
            if (uploadProgress < 100) {
                return (
                    <Grid item xs={12}>
                        <FormattedMessage id="info.uploading" />
                        <LinearProgressWithPercentage value={uploadProgress} />
                    </Grid>
                )
            }

            if (uploadDone) {
                return (
                    <Grid item xs={12}>
                        <FormattedMessage id="info.upload_done" />
                    </Grid>
                );
            } else {
                return (
                    <Grid item xs={12}>
                        <FormattedMessage id="info.uploaded_preparing" />
                        <LinearProgress variant="indeterminate" />
                    </Grid>
                );
            }
        }

        return null;
    }

    const handleSubmit = async () => {
        try {
            setUploadError(false);
            setSaving(true);
            let fileContainerId = null;
            let fileId = null;

            if (file !== null) {
                setUploadProgress(0);

                const fileContainerResponse = await axios.post(`/api/media/folders/${folderId}/file_containers`, {
                    name: fileName,
                    description: '',
                });

                fileContainerId = fileContainerResponse.data.data.id;

                const fileResponse = await axios.post(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/files`, {
                    name: fileName,
                    primary: true,
                    type: 'consent_answer',
                });

                fileId = fileResponse.data.data.id;

                await axios.put(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/files/${fileId}/upload`,
                    file,
                    {
                        headers: { 'Content-Type': file.type },
                        onUploadProgress: handleUploadProgress,
                    }
                );
                setUploadDone(true);
            }

            const data = {
                answer: answer,
            };
            if (fileContainerId !== null) {
                data.fileContainerId = fileContainerId;
                data.fileId = fileId;
            }
            const answerResponse = await axios.post(
                `/api/media/folders/${folderId}/consents/${consent.id}/participants/${participant.personId}/answer`,
                data
            );

            if (typeof onSuccess === 'function') {
                onSuccess(participant.id, answerResponse.data.data);
            }
            onClose();
        } catch (e) {
            setUploadProgress(null);
            setUploadError(true);
        }

        setSaving(false);
    }

    const canAnswer = folderId !== null &&
        consent !== null && consent?.id &&
        typeof participant === 'object' && participant?.personId &&
        (fileIsOptional || file !== null) &&
        answer !== null && answer !== participant.answer?.data?.answer;

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            onExited={onClose}
            open={open}
            disableBackdropClick={!!saving}
        >
            <DialogTitle>
                <FormattedMessage id="label.answer_consent_for" values={{ name: participant?.person?.data?.name}}/>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" direction="row" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            color="secondary"
                            component="label"
                            fullWidth
                            variant="contained"
                        >
                            <FormattedMessage id="action.choose_file" />
                            <input
                                onChange={handleFileChange}
                                type="file"
                                style={{ display: 'none' }}
                                disabled={!!saving}
                                accept={acceptedFileTypes.concat(acceptedMimeTypes).join(',')}
                            />
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        {file && <Typography>{file.name} (<FileSize value={file.size} />)</Typography>}
                        {!file && (
                            <Typography color="textSecondary">
                                <FormattedMessage id="notice.no_file_chosen" />
                            </Typography>
                        )}
                    </Grid>

                    {uploadStatus()}

                    <Grid item xs={12}>
                        <ConsentAnswerForm
                            onSelect={handleChangeAnswer}
                            disabled={!!saving}
                            answer={participant.answer.data.answer ?? null}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" disabled={!!saving}>
                    <FormattedMessage id="action.close" />
                </Button>
                <Button
                    color="primary"
                    disabled={uploadError || saving !== null || !canAnswer}
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    <FormattedMessage id="action.save" />
                    {saving && <CircularProgress size={20} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConsentAnswerUpload.propTypes = {
    folderId: PropTypes.string.isRequired,
    consent: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};
ConsentAnswerUpload.defaultProps = {
    onClose: () => {},
};

export default ConsentAnswerUpload;
