import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AddPersonIcon from '@material-ui/icons/PersonAdd';
import AnswerIcon from '@material-ui/icons/Upload';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ConsentPdfIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { AnswerDetailsDialog } from './AnswerDetails';
import BirthNumberField from './BirthNumberField';
import ConsentAnswerUpload from './ConsentAnswerUpload';
import { CONSENT_STATE } from './Consents';
import { useFolder } from '../routes/Folder';
import KrrCheck, { KrrCheckButton } from './KrrCheck';
import SmsStatusPoll, { transportType } from './SmsStatusPoll';
import { MessageTransportStatus } from './MessageTransportStatus';

const useStyles = makeStyles(theme => ({
    linkItem: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    linkTitle: {
        marginLeft: theme.spacing(1),
        cursor: 'pointer'
    },
    grid: {
        marginTop: 0,
        marginBottom: 0,
    },
}));

const ParticipantsAddButton = ({ onClick, disabled }) => {
    return (
        <Button
            color="secondary"
            startIcon={<AddPersonIcon />}
            onClick={onClick}
            variant="contained"
            disabled={disabled || false}
        >
            <FormattedMessage id="action.add" />
        </Button>
    );
};

const ParticipantsForm = ({ participants, onAdd, onClose }) => {
    const classes = useStyles();
    const [birthNumberError, setBirthNumberError] = useState(false);
    const [birthNumber, setBirthNumber] = useState('');
    const [name, setName] = useState('');
    const nameInputRef = useRef();
    const [open, setOpen] = useState(true);
    const [ krrCheck, setKrrCheck ] = useState(null);

    const canAdd = !birthNumberError && birthNumber.length > 0 && name.length > 0;
    const alreadyAdded = !!participants.find(item => item.birthNumber === birthNumber);

    const handleAddParticipant = () => {
        if (canAdd && !alreadyAdded) {
            onAdd({ name, birthNumber });
            setOpen(false);
        }
    };

    const handleParticipantKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();

            handleAddParticipant();
        }
    };

    const handleCheckKrr = () => {
        if (!birthNumberError) {
            setKrrCheck(birthNumber);
        }
    };

    const handleNameChange = event => setName(event.target.value);
    const handleBirthNumberChange = value => {
        setBirthNumber(value);
        setKrrCheck(null);
    }
    const handleBirthNumberError = error => setBirthNumberError(error);
    const handleClose = () => setOpen(false);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="label.add_participant" />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={<FormattedMessage id="label.name" />}
                            inputRef={nameInputRef}
                            onChange={handleNameChange}
                            onKeyDown={handleParticipantKeyDown}
                            size="small"
                            variant="outlined"
                            value={name}
                            autoFocus={true}
                            id="participant-name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BirthNumberField
                            fullWidth
                            label={<FormattedMessage id="label.birth_number" />}
                            onChange={handleBirthNumberChange}
                            onErrorChange={handleBirthNumberError}
                            onKeyDown={handleParticipantKeyDown}
                            size="small"
                            variant="outlined"
                            value={birthNumber}
                            setError={alreadyAdded ? <FormattedMessage id="error.national-id.participant_exists" /> : ''}
                        />
                    </Grid>
                    <Grid container item spacing={2} alignItems="center">
                        <Grid item xs={false}>
                            <KrrCheckButton
                                onClick={handleCheckKrr}
                                disabled={birthNumberError || birthNumber.length < 11 }
                            />
                        </Grid>
                        <Grid item xs={true}>
                            {krrCheck !== null &&
                                <KrrCheck
                                    birthNumberError={birthNumberError}
                                    birthNumber={krrCheck}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    <FormattedMessage id="action.cancel" />
                </Button>
                <ParticipantsAddButton onClick={handleAddParticipant} disabled={!canAdd || alreadyAdded} />
            </DialogActions>
        </Dialog>
    );
};

const ParticipantsList = ({ participants, onRemove, readOnly, enablePdf, folderId, consent, onAnswerUpdated}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [dialog, setDialog] = useState(null);
    const handleCloseDialog = () => setDialog(null);
    const { isAdmin, writeAccess } = useFolder();

    const uploadAnswer = (item) => setDialog(
        <ConsentAnswerUpload
            folderId={folderId}
            participant={item}
            onClose={handleCloseDialog}
            consent={consent}
            onSuccess={onAnswerUpdated}
        />
    );

    const showAnswerDetails = (participant) => {
        setDialog(
            <AnswerDetailsDialog
                folderId={folderId}
                consentId={consent.id}
                personId={participant.personId}
                onClose={handleCloseDialog}
            />
        );
    };

    function showSecondaryText(participant) {
        if (participant.answer) {
            if (participant.answer.data.answer) {
                return (
                    <>
                        <FormattedMessage id={`label.consent.${participant.answer.data.answer}`} />
                        <IconButton
                            size="small"
                            onClick={() => {showAnswerDetails(participant)}}
                            aria-label={formatMessage({ id: 'label.registration_info' })}
                        >
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </>
                );
            }
            return <FormattedMessage id={'consent.awaiting_answer'} />;
        } else if (typeof participant.messageTransport !== 'undefined') {
            return (
                <MessageTransportStatus
                    messageTransport={participant.messageTransport}
                    pollUrl={participant.pollUrl}
                />
            );
        }

        return null;
    }

    function canRegisterAnswer(participant) {
        if (participant.answer) {
            return ![CONSENT_STATE.DECLINED, CONSENT_STATE.WITHDRAWN].includes(participant.answer?.data?.answer);
        }

        return false;
    }

    const sortByName = (a, b) => {
        const aName = a.name || (a.person ? a.person.data.name : '?');
        const bName = b.name || (b.person ? b.person.data.name : '?');
        return (aName < bName) ? -1 :  (bName < aName ? 1 : 0);
    };

    return (
        <>
            {dialog}
            <List aria-label={formatMessage({ id: 'label.participants' })}>
                {participants.sort(sortByName).map((item, i) => (
                    <ListItem key={item.birthNumber || item.personId}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.name || (item.person ? item.person.data.name : '?')}
                            secondary={showSecondaryText(item)}
                            secondaryTypographyProps={{
                                component: 'div',
                            }}
                        />
                        {!readOnly &&
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label={formatMessage({ id: 'action.remove' })}
                                    onClick={() => onRemove(item, i)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        }
                        <ListItemSecondaryAction>
                            {enablePdf && item?.consentPdf?.data?.url &&
                                <Link
                                    href={item.consentPdf.data.url}
                                    target="_blank"
                                    variant="body2"
                                    className={classes.linkItem}
                                >
                                    <ConsentPdfIcon fontSize="default" />
                                    <span className={classes.linkTitle}>
                                        <FormattedMessage id="label.consent" />
                                    </span>
                                </Link>
                            }
                            {!enablePdf && canRegisterAnswer(item) && (isAdmin || writeAccess) &&
                                <Link
                                    variant="body2"
                                    className={classes.linkItem}
                                    onClick={() => uploadAnswer(item)}
                                >
                                    <AnswerIcon fontSize="default" />
                                    <span className={classes.linkTitle}>
                                        <FormattedMessage id="label.register_answer" />
                                    </span>
                                </Link>
                            }
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                {participants.length === 0 && (
                    <ListItem>
                        <ListItemText>
                            <FormattedMessage id="error.no_participants"/>
                        </ListItemText>
                    </ListItem>
                )}
            </List>
        </>
    );
};

ParticipantsForm.propTypes = {
    participants: PropTypes.array,
    onAdd: PropTypes.func,
};

ParticipantsForm.defaultProps = {
    participants: [],
    onAdd: () => {},
};

ParticipantsList.propTypes = {
    participants: PropTypes.array,
    onRemove: PropTypes.func,
    readOnly: PropTypes.bool,
};

ParticipantsList.defaultProps = {
    participants: [],
    onRemove: () => {},
    readOnly: true,
};

export {
    ParticipantsForm,
    ParticipantsList,
    ParticipantsAddButton,
};
