import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ExternalUploadIcon from '@material-ui/icons/Publish';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DataTable, { COLUMN_STYLES, COLUMN_TYPES } from './DataTable';
import FolderAccessEmployeeDialog from './FolderAccessEmployeeDialog';
import { FolderAccessExternalUploadDialog } from './ExternalAccessDialogs';
import { useJsonRequest } from '../utils/axios';
import RemoveAccessDialog from './RemoveAccessDialog';
import { useFolder } from '../routes/Folder';
import { FolderChangeOwnerButton } from './FolderChangeOwnerDialog';

const useStyles = makeStyles(theme => ({
    ownerRow: {
        display: 'flex',
        flexDirection: 'column',
        '& > :not(:first-child)': {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            flexDirection: 'row',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(2),
                marginTop: 0,
            },
        },
    },
    subSection: {
        marginTop: theme.spacing(2),
    },
    accessButton: {
        textAlign: 'right',
    },
}));

export const ROLE_TYPE = {
    READ: 'READ',
    WRITE: 'WRITE',
    UPLOAD: 'UPLOAD',
};

export const ROLE_TRANSLATIONS = {
    [ROLE_TYPE.READ]: 'role.read',
    [ROLE_TYPE.WRITE]: 'role.write',
    [ROLE_TYPE.UPLOAD]: 'role.upload',
};

const ConfirmRemoveAllEmployeeRead = ({ onClose, onConfirm }) => {
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);

    const handleRemove = async () => {
        onConfirm();
        handleClose();
    }

    return (
        <Dialog open={open} onExited={onClose} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <FormattedMessage id="label.remove_access" />
            </DialogTitle>

            <DialogContent>
                <Typography>
                    <FormattedMessage id="prompt.remove_all_employee_read_access" />
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                >
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    color="primary"
                    onClick={handleRemove}
                    variant="contained"
                >
                    <FormattedMessage id="label.remove_access" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AccessLists = forwardRef(({ folderId, sensitive, onEmployeeReadChange, employeeRead }, ref) => {
    const classes = useStyles();
    const [dialog, setDialog] = useState(null);
    const { isAdmin, ownerAccess, writeAccess, ownerId } = useFolder();
    const [readAllowed, setReadAllowed] = useState(employeeRead);
    const {
        data: folderRoles,
        loading: folderRolesLoading,
        error: folderRolesError,
        reload: folderRolesReload,
    } = useJsonRequest(`/api/media/folders/${folderId}/roles`);
    const {
        data: fileRoles,
        loading: fileRolesLoading,
        error: fileRolesError,
        reload: fileRolesReload,
    } = useJsonRequest(`/api/media/folders/${folderId}/file_containers/roles`);

    useImperativeHandle(ref, () => ({
        externalUploader: handleFileUploadExternalClick,
        refreshFolderRoleLists: folderRolesReload,
    }));

    const handleDialogClose = () => setDialog(null);

    const handleDelete = role => {
        if (role.role === 'ALL_EMPLOYEE_READ') {
            setDialog(
                <ConfirmRemoveAllEmployeeRead
                    onClose={handleDialogClose}
                    onConfirm={() => handleEmployeeReadChange(false)}
                />
            );
        } else {
            const isFileContainerRole = typeof role.fileContainer !== 'undefined';

            setDialog(
                <RemoveAccessDialog
                    folderId={folderId}
                    role={role}
                    isFile={isFileContainerRole}
                    onClose={handleDialogClose}
                    onAfterRemove={() => {
                        isFileContainerRole ? fileRolesReload() : folderRolesReload();
                    }}
                />
            );
        }
    };

    const handleFileUploadExternalClick = () => setDialog(
        <FolderAccessExternalUploadDialog
            onClose={handleDialogClose}
            onSuccess={folderRolesReload}
        />
    );

    const handleAddAccessEmployee = () => setDialog(
        <FolderAccessEmployeeDialog
            onClose={handleDialogClose}
            onSuccess={folderRolesReload}
            unSelectable={(folderRoles || []).filter(r => !r.isExternalUser)}
            sensitive={sensitive}
            ownerId={ownerId}
        />
    );

    const handleEmployeeReadChange = async (allow) => {
        setReadAllowed(allow);
        const success = await onEmployeeReadChange(allow);
        if (!success) {
            setReadAllowed(employeeRead);
        }
    };

    function getReadAccessData() {
        let roles = [];
        if (Array.isArray(folderRoles)) {
            roles = folderRoles.filter(i => !i.isExternalUser && i.role === ROLE_TYPE.READ);
        }

        if (readAllowed) {
            roles.unshift({
                role: 'ALL_EMPLOYEE_READ',
                user: {
                    data: {
                        name: <i><FormattedMessage id="label.all_employees" /></i>,
                }}
            });
        }

        return roles;
    }

    return (
        <>
            {dialog}
            <Grid item xs={12} className={classes.subSection}>
                <Typography variant="h6" component="div">
                    <FormattedMessage id="label.access_internal" />
                </Typography>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" component="div">
                        <FormattedMessage id="label.access_write" />:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccessTable
                        data={(folderRoles || []).filter(i => !i.isExternalUser && i.role === ROLE_TYPE.WRITE)}
                        loading={folderRolesLoading}
                        error={folderRolesError}
                        onDelete={isAdmin || ownerAccess ? handleDelete : null}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" component="div">
                        <FormattedMessage id="label.access_read" />:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccessTable
                        data={getReadAccessData()}
                        loading={folderRolesLoading}
                        error={folderRolesError}
                        onDelete={isAdmin || ownerAccess ? handleDelete : null}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-end" spacing={2}>
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={isAdmin || ownerAccess ? handleAddAccessEmployee : null}
                        startIcon={<AddIcon />}
                        disabled={isAdmin ? false : !ownerAccess}
                    >
                        <FormattedMessage id="action.give_access" />
                    </Button>
                </Grid>
                {!sensitive &&
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={isAdmin || ownerAccess ? () => handleEmployeeReadChange(true) : null}
                            startIcon={<AddIcon />}
                            disabled={isAdmin ? false : (!ownerAccess || readAllowed)}
                        >
                            <FormattedMessage id="label.grant_employee_read" />
                        </Button>
                    </Grid>
                }
            </Grid>

            <Grid item xs={12} className={classes.subSection}>
                <Typography variant="h6" component="div">
                    <FormattedMessage id="label.access_external" />
                </Typography>
            </Grid>
            <Grid container item xs={6} alignContent="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" component="div">
                        <FormattedMessage id="label.access_folder_upload" />:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccessTable
                        data={(folderRoles || []).filter(i => i.isExternalUser && i.role === ROLE_TYPE.UPLOAD)}
                        loading={folderRolesLoading}
                        error={folderRolesError}
                        onDelete={isAdmin || writeAccess ? handleDelete : null}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} alignContent="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" component="div">
                        <FormattedMessage id="label.access_files_read" />:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccessTable
                        showFile={true}
                        folderId={folderId}
                        data={fileRoles || []}
                        loading={fileRolesLoading}
                        error={fileRolesError}
                        onDelete={isAdmin || writeAccess ? handleDelete : null}
                    />
                </Grid>
            </Grid>
        </>
    );
});

AccessLists.propTypes = {
    folderId: PropTypes.string.isRequired,
    sensitive: PropTypes.bool.isRequired,
    onEmployeeReadChange: PropTypes.func,
    employeeRead: PropTypes.bool,
};
AccessLists.defaultProps = {
    onEmployeeReadChange: () => {},
    employeeRead: false,
};

export const AccessTable = ({ showFile, folderId, data, loading, error, onDelete}) => {
    const columns = {
        name: <FormattedMessage id="label.name" />,
    };
    const canDelete = typeof onDelete === 'function';

    if (showFile) {
        columns.file = <FormattedMessage id="label.file" />;
    }
    columns.actions = <FormattedMessage id="label.actions" />;

    return (
        <DataTable
            columns={columns}
            columnTypes={{
                name: COLUMN_TYPES.TEXT,
                actions: COLUMN_TYPES.RAW,
            }}
            columnStyles={{
                actions: COLUMN_STYLES.FIT_CONTENT,
            }}
            rows={() => data.map(row => ({
                id: row.id,
                name: row.user.data.name ?? row.id,
                file: (showFile ?
                    <Link component={RouterLink} to={`/folders/${folderId}/media/${row.fileContainer.data.id}`}>
                        {row.fileContainer.data.name}
                    </Link>
                    : ''
                ),
                actions: (
                    <Button
                        onClick={canDelete ? () => onDelete(row) : null}
                        variant="outlined"
                        size="small"
                        disabled={!canDelete}
                    >
                        <FormattedMessage id="label.remove_access" />
                    </Button>
                ),
            }))}
            loading={loading}
            error={error}
            sortable={true}
            defaultSortColumn="name"
            defaultSortOrder="asc"
        />
    );
};

AccessTable.propTypes = {
    folderId: PropTypes.string,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.array,
    onDelete: PropTypes.func,
    showFile: PropTypes.bool,
};

AccessTable.defaultProps = {
    folderId: null,
    error: false,
    loading: true,
    data: [],
    onDelete: null,
    showFile: false,
};

export const ExternalUploaderButton = ({onClick, disabled}) => (
    <Button
        onClick={onClick}
        startIcon={<ExternalUploadIcon />}
        variant="outlined"
        disabled={disabled}
    >
        <FormattedMessage id="action.let_an_external_upload_a_file" />
    </Button>
);

const FolderAccess = forwardRef(({ folderId, sensitive, ownerId, ownerName, employeeRead, onEmployeeReadChange, onOwnerChangeSuccess }, ref) => {
    const classes = useStyles();
    const { isAdmin, ownerAccess } = useFolder();
    const accessListRef = useRef();
    const changeOwnerRef = useRef();

    useImperativeHandle(ref, () => ({
        externalUploader: accessListRef.current ? accessListRef.current.externalUploader : null,
        refreshFolderRoleLists: accessListRef.current ? accessListRef.current.refreshFolderRoleLists : null,
        changeOwner: changeOwnerRef.current ? changeOwnerRef.current.changeOwner : null,
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <div className={classes.ownerRow}>
                    <Typography component="span" variant="subtitle2">
                        <FormattedMessage id="label.owner" />{': '}
                    </Typography>
                    <Typography variant="inherit">
                        {ownerName}
                    </Typography>
                    <FolderChangeOwnerButton
                        ownerId={ownerId}
                        ownerName={ownerName}
                        folderId={folderId}
                        sensitive={sensitive}
                        onSuccess={onOwnerChangeSuccess}
                        ref={changeOwnerRef}
                        disabled={isAdmin ? sensitive : !ownerAccess}
                    />
                </div>
            </Grid>
            <Grid item container xs={12} spacing={2} alignItems="flex-start">
                <AccessLists
                    folderId={folderId}
                    sensitive={sensitive}
                    ref={accessListRef}
                    onEmployeeReadChange={onEmployeeReadChange}
                    employeeRead={employeeRead}
                />
            </Grid>
        </Grid>
    );
});

FolderAccess.propTypes = {
    folderId: PropTypes.string.isRequired,
    sensitive: PropTypes.bool.isRequired,
    ownerId: PropTypes.string,
    ownerName: PropTypes.node,
    employeeRead: PropTypes.bool,
    onEmployeeReadChange: PropTypes.func,
    onOwnerChangeSuccess: PropTypes.func,
};

FolderAccess.defaultProps = {
    ownerName: null,
};

export default FolderAccess;
