import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { useJsonRequest } from '../utils/axios';
import Typography from '@material-ui/core/Typography';

const ConsentArchiveDialog = ({ folderId, consentId, onClose, onSuccess }) => {
    const [open, setOpen] = useState(true);
    const { error, load, loaded, loading } = useJsonRequest(null);

    const handleClose = () => setOpen(false);

    const handleArchive = () => load({
        url: `/api/media/folders/${folderId}/consents/${consentId}/archive`,
        method: 'POST',
    });

    useEffect(() => {
        if (loaded) {
            onSuccess();
            handleClose();
        }
    }, [loaded, onSuccess]);

    return (
        <Dialog open={open} onClose={handleClose} onExited={onClose}>
            <DialogTitle>
                <FormattedMessage id="action.archive_consent" />
            </DialogTitle>

            <DialogContent>
                <Typography>
                    <FormattedMessage id="prompt.archive_consent_in_fabris" />
                </Typography>

                {error && (
                    <Typography color="error">
                        <FormattedMessage id="error.consent_archived_failed" />
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                >
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    color="primary"
                    disabled={loading}
                    onClick={handleArchive}
                    variant="contained"
                >
                    <FormattedMessage id="action.archive" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConsentArchiveDialog.defaultProps = {
    onClose: () => {},
};

export default ConsentArchiveDialog;
