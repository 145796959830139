import { useRef, useState } from 'react';
import debounce from 'lodash.debounce';

export const useDebouncedState = (defaultValue, timeout = 600) => {
    const [state, setState] = useState(defaultValue);
    const debouncer = useRef(debounce(setState, timeout));

    return [
        state,
        v => debouncer.current(v),
        () => debouncer.current.cancel(),
        () => debouncer.current.flush(),
    ];
};
