import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Redirect } from 'react-router-dom';
import BasePage from '../components/BasePage';
import { useAuth, AUTH_STRATEGY } from '../auth/AuthProvider';

const Front = () => {
    const { formatMessage } = useIntl();
    const { isAuthenticated, loginWithStrategy, loading } = useAuth();

    if (isAuthenticated) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <BasePage title={formatMessage({ id: 'action.log_in' })}>
            <Grid container>
                {
                    (() => {
                        let queryString = window.location.search;
                        if (queryString.length > 0 && queryString.substring(0, 1) === '?') {
                            queryString = queryString.substring(1);
                        }
                        const qSplit = queryString.split('&');
                        let qMap = {};
                        for (let i = 0; i < qSplit.length; i++) {
                            let j = 0;
                            while (j < qSplit[i].length && qSplit[i][j] !== '=') {
                                j++;
                            }
                            let name = qSplit[i].substr(0, j);
                            if (j < qSplit[i].length) {
                                j++;
                            }
                            let value = qSplit[i].substr(j);
                            qMap[decodeURIComponent(name)] = decodeURIComponent(value);
                        }
                        if (qMap.error !== undefined || qMap.error_description !== undefined) {
                            return (
                                <Grid className={'failureIsIndicated'} item md={12} xs={12}>
                                    <h1>
                                        <FormattedMessage
                                            id={'error.azure_error_title'}
                                        />
                                    </h1>
                                    <p>
                                        <FormattedMessage
                                            id={'error.azure_error_code'}
                                            values={{
                                                error: (qMap.error !== undefined ? qMap.error : 'N/A')
                                            }}
                                        />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id={'error.azure_error_description'}
                                            values={{
                                                error: (qMap.error_description !== undefined ? qMap.error_description : 'No description available')
                                            }}
                                        />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id={'error.azure_error_pointer'}
                                        />
                                    </p>
                                </Grid>
                            );
                        } else {
                            return null;
                        }
                    })()
                }
                <Grid item md={6} xs={12}>
                    {loading && <CircularProgress aria-busy={true} aria-label={formatMessage({ id: "info.loading" })} />}
                    {!loading && (
                        <List role="presentation">
                            <ListItem disableGutters>
                                <Button
                                    color="primary"
                                    fullWidth
                                    onClick={loginWithStrategy(AUTH_STRATEGY.IDPORTEN)}
                                    variant="contained"
                                >
                                    <FormattedMessage id="login.external" />
                                </Button>
                            </ListItem>
                            <ListItem aria-hidden dense disableGutters>
                                <Divider absolute />
                            </ListItem>
                            <ListItem disableGutters>
                                <Button
                                    fullWidth
                                    onClick={loginWithStrategy(AUTH_STRATEGY.AUTH0)}
                                    variant="contained"
                                >
                                    <FormattedMessage id="login.employee" />
                                </Button>
                            </ListItem>
                        </List>
                    )}
                </Grid>
            </Grid>
        </BasePage>
    );
};

export default Front;
