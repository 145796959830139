import React, { useState } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime, useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import DataTable, { COLUMN_TYPES, COLUMN_STYLES } from './DataTable';
import { useJsonRequest } from '../utils/axios';
import { useStream } from '../utils/stream';

const sortByDate = (a,b) => (a.createdAt < b.createdAt) ? 1 : (b.createdAt < a.createdAt ? -1 : 0);

const AttachmentLink = ({ folderId, fileContainerId, fileId }) => {
    const { streamUrl, loaded, loading, error } = useStream(
        `/api/media/folders/${folderId}/file_containers/${fileContainerId}/files/${fileId}/streaming_requests`
    );

    if (error) {
        return <FormattedMessage id="error.loading_failed" />;
    }

    if (loading) {
        return <CircularProgress size={15} />;
    }

    if (loaded && streamUrl) {
        return (
            <Link
                href={streamUrl}
                target="_blank"
                variant="body2"
            >
                <FormattedMessage id="label.registration_attachment" />
            </Link>
        );
    }

    return null;
};

export const AnswerDetailsDialog = ({folderId, consentId, personId, onClose}) => {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(true);
    const {data, loaded, loading, error} = useJsonRequest(
        `/api/media/folders/${folderId}/consents/${consentId}/participants/${personId}/answers`
    );
    const handleClose = () => setOpen(false);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="label.registration_info" />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {loading &&
                        <CircularProgress />
                    }
                    {!loading && error &&
                        <FormattedMessage id="error.loading_failed" />
                    }
                    {loaded && data !== null &&
                        <DataTable
                            columns={{
                                createdAt: <FormattedMessage id="label.date" />,
                                answer: <FormattedMessage id="label.status" />,
                                name: <FormattedMessage id="label.registered_by" />,
                                file: <FormattedMessage id="label.registration_attachment" />,
                            }}
                            rows={data.map(r => ({
                                createdAt: r.createdAt,
                                answer: formatMessage({ id: 'label.consent.' + r.answer }),
                                name: r.registeredBy.data.name || '',
                                file: (
                                    r.attachment.data?.id ?
                                        <AttachmentLink
                                            folderId={folderId}
                                            fileContainerId={r.attachment.data.fileContainerId}
                                            fileId={r.attachment.data.id}
                                        /> :
                                        ''
                                ),
                            }))}
                            columnTypes={{
                                createdAt: COLUMN_TYPES.DATETIME,
                            }}
                            columnStyles={{
                                createdAt: COLUMN_STYLES.FIT_CONTENT,
                            }}
                            sortable={['createdAt', 'answer', 'name']}
                            defaultSortColumn="createdAt"
                            defaultSortOrder="desc"
                        />
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    <FormattedMessage id="action.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const MyAnswers = ({ consentId }) => {
    const {data, loaded, loading, error} = useJsonRequest(
        `/api/media/consents/mine/${consentId}/answers`
    );

    return (
        <Grid container spacing={0}>
            {loading &&
                <CircularProgress />
            }
            {!loading && error &&
                <FormattedMessage id="error.loading_failed" />
            }
            {loaded && data !== null && data.length > 0 &&
                <Grid container spacing={0}>
                    {data.sort(sortByDate).map((r, i) => (
                        <Grid key={i} item xs={12}>
                            <FormattedDate value={r.createdAt} format="short" />
                            {' '}
                            <FormattedTime value={r.createdAt} format="short" />
                            {' - '}
                            {r.registeredBy.data.name ?
                                <FormattedMessage
                                    id={"label.user.consent.history_employee." + r.answer}
                                    values={{ name: r.registeredBy.data.name }}
                                />:
                                <FormattedMessage id={'label.user.consent.' + r.answer} />
                            }
                        </Grid>
                    ))}
                </Grid>
            }
        </Grid>
    );
};
