import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const FolderFabris = ({ primaryOfficerName, responsibleName, serviceCode, userId, userName }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    <FormattedMessage id="label.fabris_username" />
                    {` ${userName}`}
                </Typography>
            </Grid>
            <Grid container item spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="span">
                        <FormattedMessage id="label.fabris_userid" />
                    </Typography>
                    {` ${userId}`}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="span">
                        <FormattedMessage id="label.fabris_primary_officer" />
                    </Typography>
                    {` ${primaryOfficerName}`}
                </Grid>
            </Grid>
            <Grid container item spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="span">
                        <FormattedMessage id="label.fabris_casenumber" />
                    </Typography>
                    {` ${serviceCode}`}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="span">
                        <FormattedMessage id="label.fabris_service_resonsible" />
                    </Typography>
                    {` ${responsibleName}`}
                </Grid>
            </Grid>
        </Grid>
    );
};

FolderFabris.propTypes = {
    primaryOfficerName: PropTypes.string,
    responsibleName: PropTypes.string,
    serviceCode: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string,
};

FolderFabris.defaultProps = {
    primaryOfficerName: '',
    responsibleName: '',
    serviceCode: '',
    userId: '',
    userName: '',
};

export default FolderFabris;
