import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    grid: {
        marginTop: 0,
        marginBottom: 0,
    },
}));

const FileTypeHelpDialog = ({ onClose, isExternalUser }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            onExited={onClose}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="label.file_type_help" />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} className={classes.grid} direction="row">
                    <Grid item xs={12}>
                        {isExternalUser &&
                            <FormattedMessage id="help.file_type_info_external" />
                        }
                        {!isExternalUser &&
                            <FormattedMessage id="help.file_type_info" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="button" component="div">
                            <FormattedMessage id="label.file_type_audio" />
                        </Typography>
                        <FormattedMessage id="help.file_type_audio"
                            values={{
                                ul: (...chunks) => <ul>{chunks}</ul>,
                                li: (...chunks) => <li>{chunks}</li>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="button" component="div">
                            <FormattedMessage id="label.file_type_image" />
                        </Typography>
                        <FormattedMessage
                            id="help.file_type_image"
                            values={{
                                ul: (...chunks) => <ul>{chunks}</ul>,
                                li: (...chunks) => <li>{chunks}</li>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="button" component="div">
                            <FormattedMessage id="label.file_type_video" />
                        </Typography>
                        {isExternalUser &&
                            <FormattedMessage id="help.file_type_info_video_external" />
                        }
                        {!isExternalUser &&
                            <FormattedMessage id="help.file_type_info_video" />
                        }
                        <FormattedMessage id="help.file_type_video"
                            values={{
                                ul: (...chunks) => <ul>{chunks}</ul>,
                                li: (...chunks) => <li>{chunks}</li>,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    <FormattedMessage id="action.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

FileTypeHelpDialog.propTypes = {
    onClose: PropTypes.func,
};

FileTypeHelpDialog.defaultProps = {
    onClose: (() => {}),
};

export default FileTypeHelpDialog;
