import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BasePage from '../components/BasePage';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import DataTable, { COLUMN_TYPES, COLUMN_STYLES } from '../components/DataTable';
import { useJsonRequest } from '../utils/axios';
import { makeStyles } from '@material-ui/core/styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import Fab from '@material-ui/core/Fab';
import FolderCreateDialog from '../components/FolderCreateDialog';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../auth/AuthProvider';

const useStyles = makeStyles((theme) => ({
    fabIcon: {
        marginRight: theme.spacing(1),
    },
    borderSensitive: {
        borderBottomColor: theme.palette.statped.backgroundSensitive,
        borderBottomStyle: 'solid',
        borderBottomWidth: 5,
    },
    borderOpen: {
        borderBottomColor: theme.palette.statped.backgroundOpen,
        borderBottomStyle: 'solid',
        borderBottomWidth: 5,
    },
}));

export const MySensitiveFolders = () => {
    const { formatMessage } = useIntl();

    const url = '/api/media/folders/my_folders/sensitive?count=1';
    const title = formatMessage({ id: 'places.my_users' });
    const breadCrumb = {
        titleId: 'places.my_users',
        to: '/folders/sensitive',
        display: false,
    };

    return (
        <Folders
            asAdmin={false}
            sensitive={true}
            url={url}
            title={title}
            breadCrumb={breadCrumb}
        />
    );
};

export const MyNonSensitiveFolders = () => {
    const { formatMessage } = useIntl();

    const url = '/api/media/folders/my_folders/non_sensitive?count=1';
    const title = formatMessage({ id: 'places.my_open_folders' });
    const breadCrumb = {
        titleId: 'places.my_open_folders',
        to: '/folders',
        display: false,
    };

    return (
        <Folders
            asAdmin={false}
            sensitive={false}
            url={url}
            title={title}
            breadCrumb={breadCrumb}
        />
    );
};

export const AdminSensitiveFolders = () => {
    const { formatMessage } = useIntl();

    const url = '/api/media/admin/folders/sensitive?count=1';
    const title = formatMessage({ id: 'places.all_users' });
    const breadCrumb = {
        titleId: 'places.all_users',
        to: '/admin/folders/sensitive',
        display: false,
    };

    return (
        <Folders
            asAdmin={true}
            sensitive={true}
            url={url}
            title={title}
            breadCrumb={breadCrumb}
        />
    );
};

export const AdminNonSensitiveFolders = () => {
    const { formatMessage } = useIntl();

    const url = '/api/media/admin/folders/non_sensitive?count=1';
    const title = formatMessage({ id: 'places.all_open_folders' });
    const breadCrumb = {
        titleId: 'places.all_open_folders',
        to: '/admin/folders',
        display: false,
    };

    return (
        <Folders
            asAdmin={true}
            sensitive={false}
            url={url}
            title={title}
            breadCrumb={breadCrumb}
        />
    );
};

const Folders = ({ sensitive, asAdmin, url, title, breadCrumb }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [folderDialog, setFolderDialog] = useState(null);
    const [filterText, setFilterText] = useState('');
    const { user } = useAuth();
    const isAdmin = asAdmin && user.admin;

    const { data, loading, error } = useJsonRequest(url);

    const handleOpenFolderDialog = async () => {
        setFolderDialog(
            <FolderCreateDialog
                onClose={handleCloseFolderDialog}
                sensitive={sensitive}
            />
        );
    }
    const handleCloseFolderDialog = () => setFolderDialog(null);

    let columns = {
        link: <FormattedMessage id="label.folder_name" />,
        owner: <FormattedMessage id="label.owner" />,
        mediaFileCount: <FormattedMessage id="label.media_files" />,
        consentCount: <FormattedMessage id="label.consents" />,
        updatedAt: <FormattedMessage id="label.updated" />,
    };

    return (
        <BasePage
            breadcrumbs={breadCrumb}
            title={title}
            unwrapChildren
            hideTitle
        >
            {folderDialog}
            <Grid container item xs={12} className={sensitive ? classes.borderSensitive : classes.borderOpen}>
                <Grid item xs={true}>
                    <Typography variant="h3">
                        {title}
                    </Typography>
                </Grid>
                {isAdmin && sensitive ?
                    null :
                    <Grid item xs={false}>
                        <Fab
                            variant="extended"
                            color="primary"
                            aria-label={formatMessage({ id: "action.new_folder" })}
                            label={formatMessage({ id: "action.new_folder" })}
                            onClick={handleOpenFolderDialog}
                        >
                            <CreateNewFolderIcon color="inherit" className={classes.fabIcon}/>
                            <FormattedMessage id="action.new_folder" />
                        </Fab>
                    </Grid>
                }
            </Grid>
            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <TextField
                            fullWidth
                            label={<FormattedMessage id="label.filter" />}
                            disabled={loading}
                            onChange={(e) => setFilterText(e.currentTarget.value.trimLeft().trimRight().toLocaleLowerCase())}
                            helperText={<FormattedMessage id="help.folder.filter" />}
                            inputProps={{
                                'aria-label': formatMessage({ id: 'label.filter' }),
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={12}>
                                <DataTable
                                    columns={columns}
                                    rows={(data || [])
                                        .filter(item =>
                                            filterText.length > 1 ?
                                                item.name.toLocaleLowerCase().includes(filterText) :
                                                true
                                        )
                                        .map(row => ({
                                            ...row,
                                            link: (
                                                <Link
                                                    component={RouterLink}
                                                    to={(isAdmin ? '/admin' : '') + `/folders/${row.id}`}
                                                >
                                                    {row.name}
                                                </Link>
                                            ),
                                            owner: row.owner.data.name,
                                            mediaFileCount: row?.fileContainerCount?.data?.count ?? '-',
                                            consentCount: row?.consentCount?.data?.count ?? '-',
                                        }))
                                    }
                                    columnTypes={{
                                        updatedAt: COLUMN_TYPES.DATETIME,
                                    }}
                                    columnStyles={{
                                        mediaFileCount: COLUMN_STYLES.ALIGN_RIGHT,
                                        consentCount: COLUMN_STYLES.ALIGN_RIGHT,
                                    }}
                                    loading={loading}
                                    error={error}
                                    sortable={['link', 'owner', 'mediaFileCount', 'consentCount', 'updatedAt']}
                                    sortColumnMap={{ 'link': 'name' }}
                                    defaultSortColumn="updatedAt"
                                    defaultSortOrder="desc"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </BasePage>
    );
};

Folders.propTypes = {
    sensitive: PropTypes.bool.isRequired,
    asAdmin: PropTypes.bool,
}
Folders.defaultProps = {
    asAdmin: false,
};
