import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';
import { ROLE_TRANSLATIONS } from './FolderAccess';

const TranslatedFolderRole = ({ role }) =>
    ROLE_TRANSLATIONS[role]
        ? <FormattedMessage id={ROLE_TRANSLATIONS[role]} />
        : role
;

const RemoveAccessDialog = ({ role, folderId, fileContainerId, isFile, onClose, onAfterRemove }) => {
    const cancelToken = useRef(CancelToken.source());
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setOpen(false);

    useEffect(() => () => cancelToken.current.cancel(), []);

    const handleRemove = async () => {
        setLoading(true);

        let url = `/api/media/folders/${folderId}/roles/${role.id}`;

        if (isFile) {
            const fcId = fileContainerId ?? role.fileContainer.data.id;
            url =`/api/media/folders/${folderId}/file_containers/${fcId}/roles/${role.id}`;
        }

        try {
            await axios.delete(url, { cancelToken: cancelToken.current.token });
            setLoading(false);
            onAfterRemove(role);
            handleClose();
        } catch (e) {
            if (!isCancel(e)) {
                setLoading(false);
            }
        }
    };

    return (
        <Dialog open={open} onExited={onClose} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <FormattedMessage id="label.remove_access" />
            </DialogTitle>

            <DialogContent>
                {loading && <CircularProgress />}
                {!loading && (
                    <Fragment>
                        <Typography>
                            <FormattedMessage
                                id={isFile ? "prompt.delete_access_file" : "prompt.delete_access_folder"}
                                values={{
                                    user: role.user.data.name,
                                    role: <TranslatedFolderRole role={role.role} />,
                                    filename: isFile && role.fileContainer ? '\'' + role.fileContainer.data.name + '\'' : '',
                                }}
                            />
                        </Typography>
                    </Fragment>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                >
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    color="primary"
                    onClick={handleRemove}
                    variant="contained"
                >
                    <FormattedMessage id="label.remove_access" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RemoveAccessDialog.propTypes = {
    role: PropTypes.object.isRequired,
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string,
    onClose: PropTypes.func,
    onAfterRemove: PropTypes.func,
};

RemoveAccessDialog.defaultProps = {
    fileContainerId: null,
    onClose: () => {},
    onAfterRemove: () => {},
};

export default RemoveAccessDialog;
