import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import { useFolder } from '../routes/Folder';

const useStyles = makeStyles((theme) => ({
    gridSize: {
        // Fix unwanted scrollbar
        width: 'auto',
        margin: 0,
    },
    connectButtonSpacing: {
        marginLeft: theme.spacing(1),
    },
    info: {
        fontStyle: 'italic',
    },
    errorMsg: {
        marginRight: theme.spacing(1),
    },
    spinner: {
        marginLeft: theme.spacing(1),
    },
}));

const FabrisCaseLookupForm = ({
    caseId,
    onSuccess,
    onError,
    onChange,
    required,
    sensitive,
    showError,
    showSuccess,
    disabled,
    label,
    shouldAutoFocus,
}) => {
    const classes = useStyles();
    const [fabrisCaseId, setFabrisCaseId] = useState(caseId);
    const { formatMessage } = useIntl();
    const {
        data: caseData,
        loaded: caseLoaded,
        load: loadCase,
        error: caseError,
        statusCode: caseStatusCode,
    } = useJsonRequest(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        if (caseLoaded) {
            setSearching(false);
            setErrorMessage(null);
            onSuccess(caseData);
            setSuccessMessage(showSuccess ?
                <Typography variant="subtitle2" component="span">
                    {formatMessage({ id: 'info.case_found' })}
                    {': '}
                    <span className={classes.info}>
                        {caseData.title}
                    </span>
                </Typography>
                : null);
        }
    }, [caseData, caseLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (caseError) {
            let msg;

            switch (caseStatusCode) {
                case 403:
                    msg = formatMessage({ id: 'error.access_denied' });
                    break;
                case 404:
                    msg = formatMessage({ id: 'error.case_not_found' });
                    break;
                case 409:
                    if (sensitive) {
                        msg = formatMessage({ id: 'error.case_type_sensitive_required'});
                    } else {
                        msg = formatMessage({ id: 'error.case_type_non_sensitive_required'});
                    }
                    break;
                default:
                    msg = formatMessage({ id: 'error.loading_failed' });
            }
            if (showError) {
                setErrorMessage(
                    <Typography variant="subtitle2" component="span">
                        {msg}
                    </Typography>
                );
            }
            onError(caseStatusCode, msg);
            setSearching(false);
        }
    }, [caseError, caseStatusCode]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = event => {
        setFabrisCaseId(event.target.value);
        setErrorMessage(null);
        setSuccessMessage(event.target.value ?
            <Typography variant="subtitle2" component="span">
                {formatMessage({ id: 'info.not_checked' })}
            </Typography>
            : null
        )
        onChange(event.target.value);
    };

    const handleInputKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();

            handleLookup();
        }
    };

    const handleLookup = () => {
        setSearching(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        loadCase({ url: `/api/media/fabris/case/${fabrisCaseId}?sensitive=${sensitive}` });
    };

    return (
        <>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    autoFocus={shouldAutoFocus}
                    label={label}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    error={errorMessage !== null}
                    required={required}
                    helperText={errorMessage || successMessage}
                    value={caseId || fabrisCaseId || ''}
                    disabled={searching || disabled}
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={handleLookup}
                    variant="outlined"
                    fullWidth={true}
                    disabled={fabrisCaseId === '' || searching || disabled}
                >
                    {<FormattedMessage id="action.retrieve_case" />}
                    {searching && <CircularProgress size={20} className={classes.spinner} />}
                </Button>
            </Grid>
        </>
    );
};

const FolderConnectFabrisDialog = ({ folderId, onClose, onSuccess }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [data, setData] = useState(null);
    const handleClose = () => setOpen(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(formatMessage());
    const { isAdmin, writeAccess } = useFolder();

    const handleSetCase = (data) => {
        setData(data);
        setMessage(
            <>
                {formatMessage(<><FormattedMessage id="info.case_found" />{': '}</>)}
                <span className={classes.info}>
                    {data.title}
                </span>
            </>
        );
    };

    const handleClearCase = () => {
        setData(null);
        setMessage(formatMessage());
        setError(null);
    };

    const handleErrorCase = (code, msg) => {
        setData(null);
        setMessage(formatMessage(msg));
    };

    function formatMessage(msg = null) {
        return (
            <Typography variant="subtitle2" component="span">
                {msg || <>&nbsp;</>}
            </Typography>
        );
    }

    const handleConnect = async () => {
        try {
            setSaving(true);
            await axios.patch(`/api/media/folders/${folderId}`, {
                fabrisCaseId: data.serviceCode,
            });
            onSuccess(data);
            setSaving(false);
            onClose();
        } catch (e) {
            setSaving(false);
            setError(e.response.status);
        }
    };

    function showError() {
        if (error === 409) {
            return (
                <div className={classes.errorMsg}>
                    <FormattedMessage id="error.fabis_already_connected" />
                </div>
            );
        }
        return (
            <div className={classes.errorMsg}>
                <FormattedMessage id="error.save_failed" />
            </div>
        );
    }

    return (
        <Dialog fullWidth onClose={handleClose} onExited={onClose} open={open}>
            <DialogTitle>
                {<FormattedMessage id="action.connect_to_fabris_case" />}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3} alignItems="flex-end" className={classes.gridSize}>
                    <FabrisCaseLookupForm
                        onSuccess={isAdmin || !writeAccess ? null : handleSetCase}
                        onChange={isAdmin || !writeAccess ? null : handleClearCase}
                        onError={handleErrorCase}
                        required={true}
                        sensitive={false}
                        disabled={isAdmin ? true : !writeAccess || saving}
                        label={<FormattedMessage id="label.fabris_case_number" />}
                    />
                    <Grid item xs={12}>
                        {message}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {error !== null && showError()}

                <Button onClick={onClose} variant="outlined">
                    <FormattedMessage id="action.cancel" />
                </Button>

                <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={data === null || saving}
                    onClick={handleConnect}
                >
                    <FormattedMessage id="action.connect" />
                    {saving && <CircularProgress size={20} className={classes.spinner} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ConnectToFabrisButton = forwardRef(({ folderId, onSuccess, disabled }, ref) => {
    const classes = useStyles();
    const [dialog, setDialog] = useState(null);
    const handleCloseDialog = () => setDialog(null);

    useImperativeHandle(ref, () => ({
        openDialog: handleOpenConnectDialog,
    }));

    const handleOpenConnectDialog = () => {
        setDialog(
            <FolderConnectFabrisDialog
                folderId={folderId}
                onClose={handleCloseDialog}
                onSuccess={onSuccess}
            />
        );
    };

    return (
        <span>
            {dialog}
            <Button
                onClick={handleOpenConnectDialog}
                variant="outlined"
                className={classes.connectButtonSpacing}
                disabled={disabled}
            >
                <FormattedMessage id="action.connect_to_fabris_case" />
            </Button>
        </span>
    );
});

FabrisCaseLookupForm.propTypes = {
    caseId: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    sensitive: PropTypes.bool,
    showError: PropTypes.bool,
    showSuccess: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    shouldAutoFocus: PropTypes.bool,
};

FabrisCaseLookupForm.defaultProps = {
    caseId: '',
    onSuccess: () => {},
    onError: () => {},
    onChange: () => {},
    required: false,
    sensitive: false,
    showError: false,
    showSuccess: false,
    disabled: false,
    label: '',
    shouldAutoFocus: true,
};

FolderConnectFabrisDialog.propTypes = {
    folderId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};
FolderConnectFabrisDialog.defaultProps = {
    onClose: () => {},
    onSuccess: () => {},
};

ConnectToFabrisButton.propTypes = {
    folderId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    disabled: PropTypes.bool,
};
ConnectToFabrisButton.defaultProps = {
    onSuccess: () => {},
    disabled: false,
};

export {
    FolderConnectFabrisDialog,
    ConnectToFabrisButton,
    FabrisCaseLookupForm,
};
