import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { axiosWithAuth as axios, useJsonRequest } from '../utils/axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditableText from '../components/EditableText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getUserLocale } from '../language';
import { ConnectToFabrisButton } from './FolderConnectFabrisDialog';
import Tags from '../components/Tags';
import { useFolder } from '../routes/Folder';

// TODO: needs retry logic
const FolderMetadataSelector = ({ choiceUrl, value, disabledValues = [], disabled, ...props }) => {
    const { data, loaded } = useJsonRequest(choiceUrl);

    return (
        <Select
            disabled={!loaded ? false : disabled}
            value={!loaded ? '' : (value || '')}
            inputProps={{
                'aria-labelledby': props.labelId || 'tags',
            }}
            {...props}
        >
            {loaded && Object.entries(data).map(([key, { label }]) => (
                <MenuItem key={key} value={key} disabled={disabledValues.includes(key)}>
                    {label[getUserLocale()] ?? label.nb}
                </MenuItem>
            ))}
        </Select>
    );
};

const FolderAbout = forwardRef(({
    folderId,
    sensitive,
    description,
    discipline,
    tags,
    onAfterSave,
    serviceCode,
    createdAt,
}, ref) => {
    const { formatMessage } = useIntl();
    const fabrisConnectRef = useRef();
    const { isAdmin, writeAccess } = useFolder();

    useImperativeHandle(ref, () => ({
        connectToFabris: fabrisConnectRef.current ? fabrisConnectRef.current.openDialog : null,
    }));

    const handleSave = async (key, value) => {
        await axios.patch(`/api/media/folders/${folderId}`, { [key]: value });
        onAfterSave({ [key]: value });
    };

    const handleAddTag = async (value) => {
        const response = await axios.put(`/api/media/folders/${folderId}/tags`, {tag: value});
        const values = tags.slice();
        values.push(response.data.data);
        onAfterSave({ tags: { data: values}});
    };

    const handleDeleteTag = async (id) => {
        await axios.delete(`/api/media/folders/${folderId}/tags/${id}`);
        onAfterSave({tags: {data: tags.filter(tag => tag.id !== id)}});
    };

    const handleConnectSuccess = (data) => {
        onAfterSave({ serviceCode: data.serviceCode });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputLabel id="folder-description-label">
                    <FormattedMessage id="label.description" />
                </InputLabel>
                <Typography component="div" variant="body1">
                    <EditableText
                        label={formatMessage({ id: 'label.description' })}
                        multiline
                        onSave={isAdmin || writeAccess ? value => handleSave('description', value) : null}
                        placeholder={formatMessage({ id: 'action.describe_folder' })}
                        text={description}
                        outlined
                        readOnly={isAdmin ? false : !writeAccess}
                        inputProps={{
                            id: "folder-description",
                            inputProps: {
                                'aria-label': formatMessage({ id: 'label.description' }),
                            },
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography component="span" variant="subtitle2">
                    <FormattedMessage id="label.created" />
                    {': '}
                </Typography>
                <Typography variant="inherit">
                    <FormattedDate value={createdAt} format="short" />
                    {' '}
                    <FormattedTime value={createdAt} format="short" />
                </Typography>
            </Grid>
            {!sensitive && (
                <>
                    <Grid item xs={12}>
                        <Typography component="span" variant="subtitle2">
                            <FormattedMessage id="label.fabris_case_number" />{': '}
                        </Typography>
                        {serviceCode ?? <FormattedMessage id="label.not_connected" />}
                        <ConnectToFabrisButton
                            folderId={folderId}
                            onSuccess={writeAccess ? handleConnectSuccess : null}
                            ref={fabrisConnectRef}
                            disabled={isAdmin ? true : serviceCode !== null || !writeAccess}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size="small" variant="outlined">
                            <InputLabel id="subject_areas">
                                <FormattedMessage id="label.subject_area" />
                            </InputLabel>

                            <FolderMetadataSelector
                                choiceUrl="/api/media/disciplines.json"
                                defaultValue=""
                                value={discipline}
                                label={<FormattedMessage id="label.subject_area" />}
                                labelId="subject_areas"
                                onChange={isAdmin || writeAccess ? event => handleSave('discipline', event.target.value) : null}
                                disabled={isAdmin ? false : !writeAccess}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Tags
                            onChange={isAdmin || writeAccess ? handleAddTag : null}
                            onDelete={isAdmin || writeAccess ? handleDeleteTag : null}
                            selected={tags}
                            disabled={isAdmin ? false : !writeAccess}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
});

FolderAbout.propTypes = {
    folderId: PropTypes.string.isRequired,
    sensitive: PropTypes.bool,
    description: PropTypes.string,
    discipline: PropTypes.string,
    tags: PropTypes.array,
    onAfterSave: PropTypes.func,
    serviceCode: PropTypes.string,
    createdAt: PropTypes.string,
};

FolderAbout.defaultProps = {
    sensitive: false,
    description: '',
    discipline: '',
    tags: [],
    onAfterSave: () => {},
    serviceCode: null,
    createdAt: null,
};

export default FolderAbout;
