import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useJsonRequest } from '../utils/axios';
import { NeedsAuthenticationError, fetchAndStoreJwt, JWT_TIMEOUT_MS } from './jwt';
import { useIntl } from 'react-intl';

const SESSION_KEY = 'stpe-auth-strategy';

export const AuthContext = createContext({
    forciblyLoggedOut: false,
    isAuthenticated: false,
    loading: false,
    loginWithStrategy: () => {},
    logout: () => {},
    strategy: '',
    user: {},
});
export const useAuth = () => useContext(AuthContext);

const reducer = (state, action) => ({ ...state, ...action });

export const AUTH_STRATEGY = {
    AUTH0: 'auth0',
    IDPORTEN: 'idporten',
};

const AuthProvider = ({ strategies, children }) => {
    const { locale } = useIntl();
    const [state, dispatch] = useReducer(reducer, {
        forciblyLoggedOut: false,
        loading: true,
        user: null,
    });
    const {
        error: loadUserError,
        load: loadUserData,
        loaded: userDataLoaded,
        data: userData,
    } = useJsonRequest();

    // Bring current authentication method from storage into state
    useEffect(() => {
        const lastStrategyName = localStorage.getItem(SESSION_KEY);
        const strategy = strategies.find(strategy => (
            strategy.didAuthenticate() ||
            strategy.getName() === lastStrategyName
        ));

        dispatch({ loading: Boolean(strategy), strategy });
    }, [strategies]);

    // Run post-authentication if login was attempted, fetch user data
    useEffect(() => {
        if (!state.strategy) {
            return;
        }

        (async () => {
            try {
                if (state.strategy.didAuthenticate()) {
                    await state.strategy.postAuthentication();
                }
            } catch (e) {
                console.info('Authentication error', e);
                localStorage.clear();

                throw e;
            }

            loadUserData({ url: '/api/identity/me' });
        })();
    }, [loadUserData, state.strategy]);

    const logout = useCallback(() => {
        localStorage.clear();
        state.strategy && state.strategy.logout();
        dispatch({ strategy: null });
    }, [state.strategy]);

    // Log out if fetching user data failed
    useEffect(() => {
        if (loadUserError) {
            logout();
        }
    }, [loadUserError, logout]);

    // Store user data in state, save authentication method in storage
    useEffect(() => {
        if (!userDataLoaded) {
            return;
        }

        dispatch({ loading: false, user: userData });

        const strategyName = state.strategy.getName();

        if (localStorage.getItem(SESSION_KEY) !== strategyName) {
            console.log('setting session key', strategyName);
            localStorage.setItem(SESSION_KEY, strategyName);
        }
    }, [state.strategy, userData, userDataLoaded]);

    // Refresh the JWT silently
    useEffect(() => {
        if (state.loading || !state.user) {
            return;
        }

        const interval = setInterval(async () => {
            try {
                await fetchAndStoreJwt();
            } catch (e) {
                if (e instanceof NeedsAuthenticationError) {
                    dispatch({ forciblyLoggedOut: true });
                    clearInterval(interval);
                } else {
                    console.log('An error occurred while refreshing the JWT', e);
                }
            }
        }, JWT_TIMEOUT_MS);

        return () => clearTimeout(interval);
    }, [state.loading, state.user]);

    const loginWithStrategy = useCallback((name) => () => {
        let strategy = strategies.find(strategy => strategy.getName() === name);

        if (!strategy) {
            throw new Error('invalid strategy');
        }

        strategy.setLocale(locale);
        strategy.loginWithRedirect();
    }, [locale, strategies]);

    return (
        <AuthContext.Provider value={{
            forciblyLoggedOut: state.forciblyLoggedOut,
            isAuthenticated: !state.loading && state.strategy && state.user,
            loading: state.loading,
            loginWithStrategy,
            logout,
            strategy: state.strategy?.getName(),
            user: state.user,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
    strategies: PropTypes.array.isRequired,
};

export default AuthProvider;
