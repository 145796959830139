import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { axiosWithAuth as axios, CancelToken, isCancel } from '../utils/axios';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    errorMsg: {
        color: red[700],
    },
}));

export const KrrCheckButton = ({ onClick, disabled }) => (
    <Button
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
    >
        <FormattedMessage id="action.check_krr" />
    </Button>
);

const KrrCheck = ({
    birthNumber,
    birthNumberError,
    onChange,
    showSuccessMessage,
    showFailMessage,
    showErrorMessage,
    className,
}) => {
    const classes = useStyles();
    const cancelToken = useRef(CancelToken.source());
    const [ checking, setChecking ] = useState(false);
    const [ canNotify, setCanNotify ] = useState(null);

    const handleChange = useCallback(value => {
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }, [onChange]);

    useEffect(() => () => cancelToken.current.cancel(), []);

    useEffect(() => {
        if (!birthNumberError && birthNumber !== '' && birthNumber !== null) {
            (async () => {
                setChecking(true);
                handleChange(false);

                try {
                    const response = await axios.post(`/api/media/krr/check`, {
                        birthNumber: birthNumber,
                    }, {
                        cancelToken: cancelToken.current.token,
                    });
                    handleChange(response.data.canNotify);
                    setCanNotify(response.data.canNotify);
                    setChecking(false);
                } catch (e) {
                    if (!isCancel(e)) {
                        handleChange(false);
                        setCanNotify(null);
                        setChecking(false);
                    }
                }
            })();
        }
    }, [birthNumber, birthNumberError, handleChange]);

    if (checking) {
        return (
            <div className={className}>
                <FormattedMessage id="info.checking_krr" />...&nbsp;<CircularProgress size={20} />
            </div>
        );
    } else if (!birthNumberError && birthNumber !== '') {
        if (canNotify === true) {
            return showSuccessMessage && (
                <div className={className}>
                    <FormattedMessage id="info.can_notify" />
                </div>
            );
        } else if (canNotify === false) {
            return showFailMessage && (
                <div className={clsx(className, classes.errorMsg)}>
                    <FormattedMessage id="info.cannot_notify" />
                </div>
            );
        } else if (canNotify === null) {
            return showErrorMessage && (
                <div className={className}>
                    <FormattedMessage id="error.loading_failed" />
                </div>
            );
        }
    }

    return null;
};

KrrCheck.propTypes = {
    birthNumber: PropTypes.string.isRequired,
    birthNumberError: PropTypes.bool.isRequired,
    onCheck: PropTypes.func,
    showSuccessMessage: PropTypes.bool,
    showFailMessage: PropTypes.bool,
    showErrorMessage: PropTypes.bool,
    className: PropTypes.string,
};

KrrCheck.defaultProps = {
    onCheck: () => {},
    showSuccessMessage: true,
    showFailMessage: true,
    showErrorMessage: true,
    className: null,
};

export default KrrCheck;
