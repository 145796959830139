import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axiosWithAuth as axios } from '../utils/axios';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import ConsentAnswerForm from '../components/ConsentAnswerForm';

const ConsentAnswer = ({consentId, consentAnswer, disabled, onAfterAnswer, onCancel}) => {
    const [answer, setAnswer] = useState(consentAnswer);
    const [error, setError] = useState(null);

    const handleChangeApproves = value => setAnswer(value);

    const handleSubmit = async () => {
        try {
            await axios.post(`/api/media/consents/mine/${consentId}/answer`, { answer });

            if (typeof onAfterAnswer === 'function') {
                onAfterAnswer(answer);
            }
        } catch (e) {
            setError(e?.response?.status || 0);
        }
    };

    const handleCancel = async () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ConsentAnswerForm
                    answer={consentAnswer}
                    disabled={disabled}
                    onSelect={handleChangeApproves}
                />
            </Grid>
            {error !== null &&
                <Grid item xs={12}>
                    <div>
                        <FormattedMessage id="error.save_failed" />
                        {' (' + error + ')'}
                    </div>
                </Grid>
            }

            <Grid item xs={false}>
                <Button
                    color="default"
                    onClick={handleCancel}
                    variant="outlined"
                    size="medium"
                >
                    <FormattedMessage id="action.cancel" />
                </Button>
            </Grid>
            <Grid item xs={true}>
                <Button
                    color="primary"
                    disabled={disabled || answer === consentAnswer}
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                >
                    <FormattedMessage id="action.send" />
                </Button>
            </Grid>
            <Grid item xs={12}>
                <FormattedMessage id="info.consent_user_age" />
            </Grid>
        </Grid>
    );
};

ConsentAnswer.propTypes = {
    consentId:PropTypes.string.isRequired,
    consentAnswer: PropTypes.string,
    disabled: PropTypes.bool,
    onAfterAnswer: PropTypes.func,
    onCancel: PropTypes.func,
};

ConsentAnswer.defaultProps = {
    disabled: true,
    consentAnswer: null,
    onAfterAnswer: () => {},
    onCancel: () => {},
};

export default ConsentAnswer;
