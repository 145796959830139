import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    block: {
        display: 'block',
        marginTop: 20,
    },
    image: {
        width: '100%',
    },
}));

const ImageViewer = ({ stream }) => {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    if (stream.error) {
        return (
            <div className={classes.block}>
                <FormattedMessage id="error.loading_failed" />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.block}>
                <FormattedMessage id="error.image_display" />
            </div>
        );
    }

    if (!stream.loaded) {
        return <CircularProgress className={classes.block} />;
    }

    return (
        <>
            {loading &&
                <CircularProgress className={classes.block} />
            }
            <img
                src={stream.streamUrl}
                alt=""
                className={clsx(classes.image, classes.block)}
                onContextMenu={(e) => e.preventDefault()}
                onError={(e) => {
                    setLoading(false);
                    setError(true);
                }}
                onLoad={() => setLoading(false)}
            />
        </>
    );
};

ImageViewer.propTypes = {
    stream: PropTypes.object.isRequired,
};

export default ImageViewer;
