import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useJsonRequest, axiosWithAuth as axios } from '../utils/axios';
import { useFolder } from '../routes/Folder';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useId } from '../utils/id';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import OwnerIcon from '@material-ui/icons/Person';
import UserIcon from '@material-ui/icons/PersonOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    subSection: {
        marginTop: theme.spacing(2),
    },
    ownerChangeButtonSpacing: {
        marginLeft: theme.spacing(1),
    },
    errorMsg: {
        marginRight: theme.spacing(1),
    },
}));

export const FolderChangeOwnerDialog = ({ folderId, ownerId, ownerName, sensitive, onSuccess, onClose }) => {
    const classes = useStyles();
    const ownerSelectId = useId();
    const { isAdmin, ownerAccess } = useFolder();
    const sortByName = (a, b) => (a.name < b.name) ? -1 :  (b.name < a.name ? 1 : 0);
    const [open, setOpen] = useState(true);
    const [selectedOwner, setSelectedOwner] = useState({
        id: ownerId,
        name: ownerName,
    });
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const getUrl = () => {
        if (isAdmin) {
            return sensitive ? null : '/api/media/employees';
        }
        if (ownerAccess) {
            return sensitive ? `/api/media/folders/${folderId}/employees` : '/api/media/employees';
        }

        return null;
    }

    const { data: employees, loaded, loading } = useJsonRequest(getUrl());

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = async () => {
        try {
            setSaving(true);
            const response = await axios.patch(`/api/media/folders/${folderId}`, {
                ownerId: selectedOwner.id,
                asAdmin: isAdmin,
            });
            onSuccess(
                selectedOwner.id,
                selectedOwner.name,
                response?.data?.data?.role?.data?.role
            );
            setSaving(false);
            onClose();
        } catch {
            setSaving(false);
            setError(true);
        }
    }

    const handleSelect = event => {
        const id = event.target.value;
        setSelectedOwner({
            id: id,
            name: employees.find(employee => employee.personId === id)?.name,
        });
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            onExit={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={saving}
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage id="action.change_owner" />
            </DialogTitle>
            <DialogContent>
                {!isAdmin &&
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id="info.transfer_ownership_info" />
                    </DialogContentText>
                }
                <FormControl fullWidth>
                    <Select
                        defaultValue=""
                        fullWidth
                        id={ownerSelectId}
                        label={<FormattedMessage id="label.owner" />}
                        labelId={ownerSelectId}
                        onChange={handleSelect}
                        value={selectedOwner.id}
                        disabled={isAdmin ? saving : (!ownerAccess || saving)}
                        inputProps={{
                            'aria-labelledby': ownerSelectId,
                        }}
                    >
                        {!loaded &&
                            <MenuItem value={selectedOwner.id}>
                                <ListItemIcon>
                                    {loading && <CircularProgress size={20} />}
                                    {!loading && <OwnerIcon fontSize="small" />}
                                </ListItemIcon>
                                <Typography variant="inherit">
                                    {selectedOwner.name}
                                </Typography>
                            </MenuItem>
                        }
                        {loaded && employees.sort(sortByName).map(({ name, personId }, idx) => (
                            <MenuItem
                                key={personId || idx}
                                value={personId}
                                disabled={personId === null || personId === ownerId}
                            >
                                <ListItemIcon>
                                    {personId === ownerId && <OwnerIcon fontSize="small" />}
                                    {personId !== ownerId && <UserIcon fontSize="small" />}
                                </ListItemIcon>
                                {name}
                                {personId === ownerId && (
                                    <>
                                        {' ('}
                                        <FormattedMessage id="label.owner" />
                                        {')'}
                                    </>
                                )}
                                {personId === null && (
                                    <>
                                        {' ('}
                                        <FormattedMessage id="info.portaluser_not_found" />
                                        {')'}
                                    </>
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedOwner.id !== ownerId ?
                    <DialogContentText
                        id="alert-dialog-description"
                        className={classes.subSection}
                    >
                        <FormattedMessage id="info.transfer_ownership_title" values={{name: selectedOwner.name}} />
                    </DialogContentText>
                    : ''
                }
            </DialogContent>
            <DialogActions>
                {error &&
                    <div className={classes.errorMsg}>
                        <FormattedMessage id="error.save_failed" />
                    </div>
                }
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    autoFocus
                    disabled={saving}
                >
                    <FormattedMessage id="action.cancel" />
                </Button>
                <Button
                    onClick={handleChange}
                    color="primary"
                    variant="contained"
                    disabled={selectedOwner.id === ownerId || saving}
                >
                    <FormattedMessage id="action.change_owner" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

FolderChangeOwnerDialog.propTypes = {
    folderId: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    ownerName: PropTypes.node.isRequired,
    sensitive: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

FolderChangeOwnerDialog.defaultProps = {
    onSuccess: () => {},
};

export const FolderChangeOwnerButton = forwardRef(({ folderId, ownerId, ownerName, sensitive, onSuccess, disabled }, ref) => {
    const classes = useStyles();
    const [dialog, setDialog] = useState(null);
    const handleCloseDialog = () => setDialog(null);

    useImperativeHandle(ref, () => ({
        changeOwner: handleOpenChangeOwnerDialog,
    }));

    const handleOpenChangeOwnerDialog = async () => {
        setDialog(
            <FolderChangeOwnerDialog
                folderId={folderId}
                ownerId={ownerId}
                ownerName={ownerName}
                sensitive={sensitive}
                onClose={handleCloseDialog}
                onSuccess={onSuccess}
            />
        );
    };

    return (
        <span>
            {dialog}
            <Button
                onClick={handleOpenChangeOwnerDialog}
                variant="outlined"
                className={classes.ownerChangeButtonSpacing}
                disabled={disabled}
            >
                <FormattedMessage id="action.change_owner" />
            </Button>
        </span>
    );
});

FolderChangeOwnerButton.propTypes = {
    folderId: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    ownerName: PropTypes.node.isRequired,
    sensitive: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
    disabled: PropTypes.bool,
};

FolderChangeOwnerButton.defaultProps = {
    onSuccess: () => {},
    disabled: true,
};
