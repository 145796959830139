import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useJsonRequest } from '../utils/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConsentPdfIcon from '@material-ui/icons/Assignment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import BasePage from '../components/BasePage';
import ConsentAnswer from './ConsentAnswer';
import {CONSENT_STATE } from '../components/Consents';
import { MyAnswers } from '../components/AnswerDetails';
import UseRightList from '../components/UseRightList';

const useStyles = makeStyles(theme => ({
    linkItem: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    linkTitle: {
        marginLeft: theme.spacing(1),
    },
}));

const MyConsent = () => {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { consent } = useParams();
    const { data, loading, reload } = useJsonRequest(`/api/media/consents/mine/${consent}`);
    const [documentRead, setDocumentRead] = useState(false);
    const [ dialog, setDialog] = useState(null);

    if (loading) {
        return (
            <BasePage>
                <CircularProgress />
            </BasePage>
        );
    }

    const handleReadChange = event => setDocumentRead(event.target.checked);
    const answered = !!data?.answer?.data?.answer;

    const onHandleCancel = () => {
        history.push('/dashboard');
    };

    const onHandleClose = () => {
        setDialog(null);
    };

    const onHandleAfterAnswer = (answer) => {
        if (answer === CONSENT_STATE.APPROVED) {
            reload();
            setDialog(
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    onClose={onHandleClose}
                    open={true}
                >
                    <DialogTitle>
                        <FormattedMessage id="label.consent_send" />
                    </DialogTitle>
                    <DialogContent>
                        <FormattedMessage id="notice.consent_answered" />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onHandleCancel}
                            variant="outlined"
                        >
                            <FormattedMessage id="action.my_page" />
                        </Button>
                        <Button
                            color="primary"
                            onClick={onHandleClose}
                            variant="contained"
                        >
                            <FormattedMessage id="action.close" />
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        } else {
            onHandleCancel();
        }
    };

    return (
        <BasePage breadcrumbs unwrapChildren title={formatMessage({ id: 'label.consent_title' })}>
            {dialog}
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div">
                            <FormattedMessage id="label.consent_person" />
                        </Typography>
                        {data.name}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div">
                            <FormattedMessage id="label.consent_creator" />
                        </Typography>
                        {data?.createdBy?.data?.name}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div">
                            <FormattedMessage id="label.consent_usage" />
                        </Typography>
                        {data?.recordingUsage &&
                            <div>
                                {'- '}<FormattedMessage id="label.recording" />
                            </div>
                        }
                        {data?.publishingUsage &&
                            <div>
                                {'- '}<FormattedMessage id="label.publishing" />{':'}
                                <UseRightList
                                    useRights={data?.useRights?.data}
                                    readOnly={true}
                                    showListAvatar={false}
                                />
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div">
                            <FormattedMessage id="label.documents" />
                        </Typography>
                        <Link
                            href={data.consentPdf.data.url}
                            target="_blank"
                            variant="body2"
                            className={classes.linkItem}
                        >
                            <ConsentPdfIcon fontSize="default" />
                            <span className={classes.linkTitle}>
                                <FormattedMessage id="label.consent" />
                            </span>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {answered &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" component="div">
                                <FormattedMessage id="label.history" />
                            </Typography>
                            <MyAnswers
                                consentId={consent}
                            />
                        </Grid>
                    }
                    {answered &&
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div">
                                <FormattedMessage id="notice.already_answered_consent" />
                            </Typography>
                        </Grid>
                    }
                    {!answered &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={1}
                                        size="small"
                                        onChange={handleReadChange}
                                        checked={documentRead}
                                    />
                                }
                                label={<FormattedMessage id="label.consent_read" />}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <ConsentAnswer
                            consentId={data.id}
                            consentAnswer={data.answer.data.answer ?? null}
                            disabled={!answered && !documentRead}
                            onAfterAnswer={onHandleAfterAnswer}
                            onCancel={onHandleCancel}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </BasePage>
    );
};

export default MyConsent;
