import { useEffect, useState } from 'react';

let lastId = 0;

const makeId = () => 'id-' + String(++lastId);

const useId = () => {
    const [id, setId] = useState('');
    useEffect(() => setId(makeId()), [setId]);

    return id;
}

export { makeId, useId };
