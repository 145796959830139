import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useJsonRequest } from '../utils/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { FileUploadDialogInternal } from './FileUploadDialog';
import DeleteMediaFileDialog from '../components/DeleteMediaFileDialog';
import CloudDownload from '@material-ui/icons/CloudDownload';
import downloader from 'js-file-download';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import ClosedCaption from '@material-ui/icons/ClosedCaption';
import Section from './Section';
import { isCaptionFile } from '../utils/caption';

const useStyles = makeStyles(() => ({
    downloadError: {
        color: red[700],
    },
}));

const AttachedFileListItem = ({ fileContainerId, folderId, file, onDelete }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const {
        data: downloadResponse,
        load: download,
        loading: downloading,
        error: downloadError,
        loaded: downloaded,
    } = useJsonRequest(null, {
        method: 'get',
        requestOptions: { responseType: 'blob' },
    });

    useEffect(() => {
        if (downloaded) {
            downloader(downloadResponse, file.name, file.contentType);
        }
    }, [downloaded, file.name, file.contentType]);

    return (
        <ListItem>
            <ListItemAvatar>
                {isCaptionFile(file) ? <ClosedCaption /> : <InsertDriveFileIcon />}
            </ListItemAvatar>

            <ListItemText primary={file.name} />

            <ListItemSecondaryAction>
                {downloading && (
                    <IconButton disabled={true}>
                        <CircularProgress size={25} />
                    </IconButton>
                )}

                {downloadError && (
                    <IconButton disabled={true}>
                        <ErrorIcon size={25} className={classes.downloadError} />
                    </IconButton>
                )}

                <IconButton
                    title={formatMessage({ id: 'action.download' })}
                    onClick={() => download({
                        url: `/api/media/folders/${folderId}/file_containers/${fileContainerId}/files/${file.id}/download`,
                    })}
                >
                    <CloudDownload />
                </IconButton>

                <IconButton
                    title={formatMessage({ id: 'action.delete' })}
                    onClick={onDelete}
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const AttachedFiles = ({ files, onAfterUpload, onAfterDelete }) => {
    const { folderId, id } = useParams();
    const [dialog, setDialog] = useState(null);

    const handleAddFileOpen = () => setDialog(
        <FileUploadDialogInternal
            fileContainerId={id}
            folderId={folderId}
            onClose={handleDialogClose}
            onSuccess={onAfterUpload}
        />
    );

    const handleDialogClose = () => setDialog(null);

    const handleAuxDeleteOpen = (fileId, name) => setDialog(
        <DeleteMediaFileDialog
            fileOnly
            fileContainerId={id}
            folderId={folderId}
            fileId={fileId}
            name={name}
            onClose={handleDialogClose}
            onSubmit={(folderId, fileContainerId, fileId) => onAfterDelete(fileId)}
        />
    );

    return (
        <Section title={<FormattedMessage id="label.attached_files" />}>
            {dialog}

            <List>
                {files.map((file, i) => (
                    <AttachedFileListItem
                        fileContainerId={id}
                        folderId={folderId}
                        file={file}
                        key={i}
                        onDelete={() => handleAuxDeleteOpen(file.id, file.name)}
                    />
                ))}
            </List>

            <Button
                onClick={handleAddFileOpen}
                startIcon={<NoteAddIcon />}
                variant="outlined"
            >
                <FormattedMessage id="action.add_file" />
            </Button>
        </Section>
    );
};

AttachedFiles.propTypes = {
    files: PropTypes.array,
    onAfterUpload: PropTypes.func,
    onAfterDelete: PropTypes.func,
};

AttachedFiles.defaultValues = {
    files: [],
    onAfterUpload: () => {},
    onAfterDelete: () => {},
};

export default AttachedFiles;
