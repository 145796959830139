import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { escape as htmlEscape, unescape as htmlUnescape } from 'html-escaper';
import AddIcon from '@material-ui/icons/AddLocation';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CurrentIcon from '@material-ui/icons/LocationOn';
import CurrentTimeIcon from '@material-ui/icons/MyLocation';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import EditingIcon from '@material-ui/icons/EditLocation';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Check';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import makeStyles from '@material-ui/core/styles/makeStyles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { axiosWithAuth as axios, useJsonRequest, CancelToken, isCancel } from '../utils/axios';
import DeleteBookmarkDialog from './DeleteBookmarkDialog';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxHeight: 400,
        marginTop: theme.spacing(2),
    },
    heading: {
        padding: '12px 16px',
    },
    activeCue: {
        backgroundColor: yellow[50],
    },
    saveError: {
        backgroundColor: red[50],
    },
    onHover: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    save: {
        color: green[700],
    },
    cancel: {
        color: red[700],
    },
    textField: {
        marginLeft: 0,
        marginRight: 0,
    },
    stateColumnSmall: {
        width: 20,
    },
    stateColumnLarge: {
        width: 50,
    },
    actionColumn: {
        width: 100,
    },
    timeColumnSmall: {
        width: 120,
    },
    timeColumnLarge: {
        width: 155,
    },
}));

const VideoBookmarks = ({ folderId, fileContainerId, playerRef, editMode, captionsDisplaying }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { data: bookmarks, loading, error, loaded } = useJsonRequest(`/api/media/folders/${folderId}/file_containers/${fileContainerId}/chapters`);
    const [chapter, setChapter] = useState();
    const [activeCues, setActiveCues] = useState([]);
    const [backupCue, setBackupCue] = useState(null);
    const [editedCue, setEditedCue] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(null);
    const currentRowRef = useRef();
    const cancelToken = CancelToken.source();
    const textTrackId = 'chapters';

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (chapter) {
            (bookmarks || []).forEach(data => {
                insertCue({ ...data, text: data.caption });
            });

            handleCueChange();
            chapter.on('cuechange', handleCueChange);

            return () => {
                chapter.off('cuechange', handleCueChange);
            };
        } else if (playerRef.current) {
            createChaptersTrack();
        }
    }, [chapter, bookmarks]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        playerRef.current.trigger('texttrackchange');
    }, [editedCue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (editMode === false && editedCue !== null) {
            cancelEdit();
        }
    }, [editMode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (editedCue === null && currentRowRef.current) {
            currentRowRef.current.scrollIntoView({block: 'center'});
        }
    }, [currentRowRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * When captions are showing the chapters are hidden, and vice versa.
     *
     * 'showing' => Displayed on the video
     * 'hidden' => Not displayed on video, but events still trigger
     */
    useEffect(() => {
        if (chapter) {
            if (chapter.mode === 'hidden' && !captionsDisplaying) {
                chapter.mode = 'showing';
            } else {
                chapter.mode = 'hidden';
            }
        }
    }, [captionsDisplaying, chapter]);

    const handleCueChange = () => {
        setActiveCues(chapter.activeCues.cues_);
    };

    function getCue(id) {
        if (chapter) {
            return chapter.cues.getCueById(id);
        }
    }

    const removeCue = (cueId) => {
        if (chapter) {
            const cue = chapter.cues.getCueById(cueId);

            if (cue) {
                chapter.removeCue(cue);
                playerRef.current.trigger('texttrackchange');
                chapter.trigger('cuechange');
                if (chapter.cues.length === 0) {
                    playerRef.current.removeRemoteTextTrack(chapter);
                    setChapter(null);
                }
            }
        }
    };

    const deleteCue = (cueId) => {
        if (chapter) {
            const cue = chapter.cues.getCueById(cueId);
            if (cue) {
                setDeleteDialog(
                    <DeleteBookmarkDialog
                        folderId={folderId}
                        fileContainerId={fileContainerId}
                        cueId={cueId}
                        onClose={() => {setDeleteDialog(null)}}
                        onSuccess={removeCue}
                        name={htmlUnescape(cue.text)}
                    />
                );
            }
        }
    };

    const createCue = () => {
        const cue = {
            id: 'new_' + Math.random().toString(),
            startTime: playerRef.current.currentTime(),
            endTime: playerRef.current.currentTime(),
            text: '',
            isNew: true,
            canSave: false,
            saving: false,
        };
        insertCue(cue);
        setEditedCue(cue);
    };

    const editCue = (id) => {
        const cue = getCue(id);
        const newCue = {
            id: cue.id,
            startTime: cue.startTime,
            endTime: cue.endTime,
            text: cue.text,
            isNew: false,
            canSave: false,
            saving: false,
        };
        setBackupCue(newCue);
        setEditedCue(newCue);
    };

    function createChaptersTrack() {
        if (!chapter) {
            let textTrack = playerRef.current.remoteTextTracks().getTrackById(textTrackId);

            if (!textTrack) {
                playerRef.current.addRemoteTextTrack({
                    kind: 'chapters',
                    label: formatMessage({ id: 'label.bookmarks' }),
                    default: true,
                    id: textTrackId,
                }, false);

                textTrack = playerRef.current.remoteTextTracks().getTrackById(textTrackId);
            }

            if (textTrack !== null) {
                setChapter(textTrack);
            }
        }
    }

    function insertCue(cue) {
        if (chapter) {
            const newCue = new VTTCue(cue.startTime, cue.endTime, cue.text);
            newCue.id = cue.id;
            chapter.addCue(newCue);
        }
    }

    const saveCue = async () => {
        if (chapter) {
            try {
                setEditedCue({...editedCue, saving: true});

                const response = await axios.request({
                    url: (editedCue.isNew
                            ? `/api/media/folders/${folderId}/file_containers/${fileContainerId}/chapters`
                            : `/api/media/folders/${folderId}/file_containers/${fileContainerId}/chapters/${editedCue.id}`
                    ),
                    method: (editedCue.isNew ? 'post' : 'put'),
                    data: {
                        startTime: editedCue.startTime,
                        endTime: editedCue.endTime,
                        caption: editedCue.text,
                    },
                    cancelToken: cancelToken.token,
                });

                if (editedCue.isNew) {
                    const cue = getCue(editedCue.id);
                    cue.id = response.data.data.id;
                }

                setBackupCue(null);
                setEditedCue(null);
            } catch (e) {
                setEditedCue({
                    ...editedCue,
                    saving: false,
                    error: !isCancel(e),
                });
            }
        }
    };

    const cancelEdit = () => {
        if (editedCue.isNew) {
            removeCue(editedCue.id);
        } else {
            const cue = getCue(editedCue.id);
            cue.startTime = backupCue.startTime;
            cue.endTime = backupCue.endTime;
            cue.text = backupCue.text;
        }
        setEditedCue(null);
        setBackupCue(null);
    }

    function verifyCue(cue) {
        return (
            cue.startTime < cue.endTime &&
            cue.text.length > 0
        );
    }

    function updateEditCueProperty(property, value) {
        if (editedCue !== null) {
            const cue = getCue(editedCue.id);
            if (cue && cue.hasOwnProperty(property)) {
                cue[property] = value;
                setEditedCue({
                    ...editedCue,
                    [property]: value,
                    canSave: verifyCue({ ...editedCue, [property]: value }),
                });
            }
        }
    }

    const setCueStartTime = (e) => {
        e.preventDefault();
        updateEditCueProperty('startTime', playerRef.current.currentTime());
    };

    const setCueEndTime = (e) => {
        e.preventDefault();
        updateEditCueProperty('endTime', playerRef.current.currentTime());
    };

    const setCueText = (e) => {
        updateEditCueProperty('text', htmlEscape(e.target.value));
    };

    const cueSort = (a, b) => {
        if (a.startTime < b.startTime) {
            return -1;
        }
        if (a.startTime > b.startTime) {
            return 1;
        }

        return 0;
    }

    function renderCues() {
        if (loading) {
            return <InfoRow message={<CircularProgress />} />;
        }

        if (error) {
            return <InfoRow message={<FormattedMessage id="error.loading_failed" />} />;
        }

        if (chapter) {
            if (chapter.cues_.length === 0) {
                return <InfoRow message={<FormattedMessage id="notice.empty_data" />} />;
            }

            return chapter.cues_.sort(cueSort).map(cue => {
                const isCurrent = (typeof activeCues.find(a => a.id === cue.id) !== 'undefined');
                const isEditing = (editedCue !== null);
                const isEditCue = (isEditing && editedCue.id === cue.id);
                const isNew = (isEditCue && editedCue.isNew);
                const canSave = (isEditCue && editedCue.canSave);

                return (
                    <TableRow
                        key={cue.id}
                        className={clsx(
                            {
                                [classes.activeCue]: isCurrent,
                                [classes.onHover]: !editMode,
                                [classes.saveError]: isEditCue && editedCue.error,
                            }
                        )}
                        onClick={editMode ? null : () => {playerRef.current.currentTime(cue.startTime)}}
                        ref={isCurrent ? currentRowRef : null}
                    >
                        <TableCell align="center" padding="none">
                            <StatusIcon isNew={isNew} editMode={editMode} isCurrent={isCurrent} isEditCue={isEditCue} />
                        </TableCell>
                        <TableCell>
                            {editMode ?
                                <TextField
                                    required={true}
                                    value={htmlUnescape(isEditCue ? editedCue.text : cue.text)}
                                    onChange={setCueText}
                                    className={classes.textField}
                                    margin="dense"
                                    multiline={true}
                                    disabled={!isEditCue}
                                    fullWidth={true}
                                />
                                : htmlUnescape(cue.text)
                            }
                        </TableCell>
                        <TableCell
                            align="right"
                            className={editMode ? classes.onHover : ''}
                            onClick={editMode ? () => {playerRef.current.currentTime(cue.startTime)} : null}
                        >
                            {isEditCue &&
                            <IconButton onClick={setCueStartTime} size="small">
                                <CurrentTimeIcon />
                            </IconButton>
                            }
                            <Duration seconds={cue.startTime} />
                        </TableCell>
                        <TableCell
                            align="right"
                            className={editMode ? classes.onHover : ''}
                            onClick={editMode ? () => {playerRef.current.currentTime(cue.endTime)} : null}
                        >
                            {isEditCue &&
                            <IconButton onClick={setCueEndTime} size="small">
                                <CurrentTimeIcon />
                            </IconButton>
                            }
                            <Duration seconds={cue.endTime} />
                        </TableCell>
                        <TableCell align="right">
                            <Duration end={cue.endTime} start={cue.startTime} />
                        </TableCell>
                        {editMode &&
                            <TableCell>
                                {!isEditCue &&
                                    <React.Fragment>
                                        <IconButton
                                            disabled={editedCue !== null}
                                            onClick={() => {editCue(cue.id)}}
                                            size="small"
                                            title={formatMessage({ id: 'action.edit' })}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={editedCue !== null}
                                            onClick={() => {deleteCue(cue.id)}}
                                            size="small"
                                            title={formatMessage({ id: 'action.delete' })}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </React.Fragment>
                                }
                                {isEditCue && editedCue.saving &&
                                    <CircularProgress size={35} />
                                }
                                {isEditCue && !editedCue.saving &&
                                    <React.Fragment>
                                        <IconButton
                                            disabled={!canSave}
                                            onClick={saveCue}
                                            className={classes.save}
                                            size="small"
                                            title={formatMessage({ id: 'action.save' })}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={cancelEdit}
                                            className={classes.cancel}
                                            size="small"
                                            title={formatMessage({ id: 'action.cancel' })}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            </TableCell>
                        }
                    </TableRow>
                );
            });
        }
    }

    return (
        <React.Fragment>
            {editMode && loaded &&
                <Button
                    disabled={editedCue !== null}
                    onClick={createCue}
                    variant="outlined"
                    endIcon={<AddIcon />}
                >
                    <FormattedMessage id="action.add_bookmark" />
                </Button>
            }
            {deleteDialog}
            <TableContainer className={classes.tableContainer} component={Paper}>
                {!editMode && (
                    <Typography component="h2" variant="h5" className={classes.heading}>
                        <FormattedMessage id="label.bookmarks" />
                    </Typography>
                )}
                <Table stickyHeader={true} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="none" className={editMode ? classes.stateColumnLarge : classes.stateColumnSmall} />
                            <TableCell>
                                <FormattedMessage id="label.text" />
                            </TableCell>
                            <TableCell align="right" className={editMode ? classes.timeColumnLarge : classes.timeColumnSmall}>
                                <FormattedMessage id="label.start_time_bookmark" />
                            </TableCell>
                            <TableCell align="right" className={editMode ? classes.timeColumnLarge : classes.timeColumnSmall}>
                                <FormattedMessage id="label.end_time_bookmark" />
                            </TableCell>
                            <TableCell align="right" className={editMode ? classes.timeColumnLarge : classes.timeColumnSmall}>
                                <FormattedMessage id="label.duration" />
                            </TableCell>
                            {editMode &&
                                <TableCell className={classes.actionColumn}>
                                    <FormattedMessage id="label.actions" />
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderCues()}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

VideoBookmarks.propTypes = {
    folderId: PropTypes.string.isRequired,
    fileContainerId: PropTypes.string.isRequired,
    playerRef: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    captionsDisplaying: PropTypes.bool,
};

VideoBookmarks.defaultProps = {
    editMode: false,
    captionsDisplaying: false,
};

export default VideoBookmarks;

const StatusIcon = React.memo(({ isCurrent, isEditCue, isNew, editMode }) => {
    if (isEditCue) {
        if (isNew) {
            return <AddIcon fontSize="large" />;
        }
        return <EditingIcon fontSize="large" />;
    } else if (isCurrent) {
        return <CurrentIcon fontSize={editMode ? 'large' : 'small'} />;
    }

    return null;
});

const InfoRow = ({ message }) => {
    return (
        <TableRow>
            <TableCell colSpan={4} align="center">
                {message}
            </TableCell>
        </TableRow>
    );
};

const Duration = React.memo(({ seconds, start, end }) => {
    let duration = 0;

    if (typeof seconds === 'number') {
        duration = seconds;
    }

    if (typeof start === 'number' && typeof end === 'number') {
        duration = (end - start);
    }

    const sign = (duration < 0 ? '-' : '');
    let unsignedSecs = (duration < 0 ? -duration : duration);
    const hour = Math.trunc(unsignedSecs / 3600);
    unsignedSecs -= (hour * 3600);
    const min = Math.trunc(unsignedSecs / 60);
    unsignedSecs -= (min * 60);
    const sec = Math.trunc(unsignedSecs);
    unsignedSecs -= sec;
    const ms = unsignedSecs.toFixed(3).toString().substr(2, 3);

    return (
        <React.Fragment>
            {
                sign +
                hour.toString().padStart(2, '0') + ':' +
                min.toString().padStart(2, '0') + ':' +
                sec.toString().padStart(2, '0') + '.' +
                ms
            }
        </React.Fragment>
    );
});
