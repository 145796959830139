import { getUserLocale } from './index';
import DateFnsUtils from '@date-io/date-fns';

const userLocale = getUserLocale();

export class DateFnsUtilsLocalized extends DateFnsUtils {
    dateFormat = (() => {
        switch (userLocale) {
            case 'nb':
                return "dd.MM.yyyy";
            case 'en':
                return "MM/dd/yyyy";
            default:
                return "yyyy-MM-dd";
        }
    })();
}

export const intlProviderFormats = {
    date: {
        short: {year: 'numeric', month: '2-digit', day: '2-digit'},
    },
    time: {
        short: {hour: 'numeric', minute: 'numeric'},
    }
};
